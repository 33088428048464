import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
import { AllMaterials } from './materials.module';
import { TranslateModule } from '@ngx-translate/core';

import { LogoComponent } from '../components/logo/logo.component';
import { HeaderComponent } from '../components/header/header.component';
import { ChartComponent } from '../components/chart/chart.component';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { PageLoadingComponent } from '../components/page-loading/page-loading.component';
import { InvitationCodeComponent } from '../components/invitation-code/invitation-code.component';

import { ModalEditingMeasurementComponent } from '../components/modal-editing-measurement/modal-editing-measurement.component';
import { ModalEditUserPermissionsComponent } from '../components/modal-edit-user-permissions/modal-edit-user-permissions.component';
import { ModalEditingHealthComponent } from '../components/modal-editing-health/modal-editing-health.component';
import { ModalHelpButtonComponent } from '../components/modal-help-button/modal-help-button.component';
import { ModalAddPatientComponent } from '../components/modal-add-patient/modal-add-patient.component';

import { MoodFormComponent } from '../components/mood_form/mood-form.component';
import { ModalUserByCodeComponent } from '../components/modal-userbycode/modal-userbycode.component';
import { AddPatientComponent } from '../components/add-patient-form/add-patient-form.component';
import { UserByCodeFormComponent } from '../components/userbycode-form/userbycode-form.component';
import { UserWithoutEmailFormComponent } from '../components/user-without-email-form/user-without-email-form.component';
import { ChooseUserPermissionsComponent } from '../components/choose-user-permissions/choose-user-permissions.component';

import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { NotificationsComponent } from '../components/notifications/notifications.component';
import { CommentsComponent } from '../components/comments/comments.component';
import { TimelineComponent } from '../components/timeline/timeline.component';
import { CategoryHeaderComponent } from '../components/category-header/category-header.component';

import { MyMeasurementsComponent } from '../components/my-measurements/my-measurements.component';
import { MyHealthComponent } from '../components/my-health/my-health.component';
import { MyPatientsComponent } from '../components/my-patients/my-patients.component';
import { MySupervisorsComponent } from '../components/my-supervisors/my-supervisors.component';

import { ViewLogWithCommentsComponent } from '../components/history-logs/view-log-with-comments/view-log-with-comments.component';
import { LogsListComponent } from '../components/history-logs/logs-list/logs-list.component';

// -- [ MY MEASUREMENTS MODULES ]  -- \\
// FORMS
import { TensionFormComponent } from '../components/my-measurements/forms/tension/tension-form.component';
import { HeightFormComponent } from '../components/my-measurements/forms/height/height-form.component';
import { WeightFormComponent  } from '../components/my-measurements/forms/weight/weight-form.component';
import { CholesterolFormComponent } from '../components/my-measurements/forms/cholesterol/cholesterol-form.component';
import { BloodGlucoseFormComponent } from '../components/my-measurements/forms/blood-glucose/blood-glucose-form.component';
import { SymptomsFormComponent } from '../components/my-measurements/forms/symptoms/symptoms-form.component';

// PAGES
import { TensionComponent } from '../components/my-measurements/pages/tension/tension.component';
import { HeightComponent } from '../components/my-measurements/pages/height/height.component';
import { WeightComponent } from '../components/my-measurements/pages/weight/weight.component';
import { CholesterolComponent } from '../components/my-measurements/pages/cholesterol/cholesterol.component';
import { BloodGlucoseComponent } from '../components/my-measurements/pages/blood_glucose/blood_glucose.component';
import { SymptomComponent } from '../components/my-measurements/pages/symptoms/symptoms.component';


// -- [ MY HEALTH MODULES ]  -- \\
// FORMS
import { MyHealthFormAllergiesComponent } from '../components/my-health/forms/allergies/allergies.component';
import { MyHealthFormDiseaseComponent } from '../components/my-health/forms/disease/disease.component';
import { MyHealthFormEmotionalStateComponent } from '../components/my-health/forms/emotional_state/emotional_state.component';
import { MyHealthFormEventsComponent } from '../components/my-health/forms/events/events.component';
import { MyHealthFormFamilyHistoryComponent } from '../components/my-health/forms/family_history/family_history.component';
import { MyHealthFormAppointmentsComponent } from '../components/my-health/forms/future_appointments/future_appointments.component';
import { MyHealthFormImmunizationsComponent } from '../components/my-health/forms/immunizations/immunizations.component';
import { MyHealthFormInsuranceComponent } from '../components/my-health/forms/insurance/insurance.component';
import { MyHealthFormLaboratoryResultsComponent } from '../components/my-health/forms/laboratory_results/laboratory_results.component';
import { MyHealthFormMedicalDevicesComponent } from '../components/my-health/forms/medical_devices/medical_devices.component';
import { MyHealthFormMedicalProceduresComponent } from '../components/my-health/forms/medical_procedures/medical_procedures.component';
import { MyHealthFormMedicationComponent } from '../components/my-health/forms/medication/medication.component';
import { MyHealthFormUsedMedicationComponent } from '../components/my-health/forms/used_medication/used_medication.component';
import { MyHealthFormEcographyComponent } from '../components/my-health/forms/ecography/ecography.component';

// PAGES
import { MyHealthPageAllergiesComponent } from '../components/my-health/pages/allergies/allergies.component';
import { MyHealthPageDiseaseComponent } from '../components/my-health/pages/disease/disease.component';
import { MyHealthPageEmotionalStateComponent } from '../components/my-health/pages/emotional_state/emotional_state.component';
import { MyHealthPageEventsComponent } from '../components/my-health/pages/events/events.component';
import { MyHealthPageFamilyHistoryComponent } from '../components/my-health/pages/family_history/family_history.component';
import { MyHealthPageAppointmentsComponent } from '../components/my-health/pages/future_appointments/future_appointments.component';
import { MyHealthPageImmunizationsComponent } from '../components/my-health/pages/immunizations/immunizations.component';
import { MyHealthPageInsuranceComponent } from '../components/my-health/pages/insurance/insurance.component';
import { MyHealthPageLaboratoryResultsComponent } from '../components/my-health/pages/laboratory_results/laboratory_results.component';
import { MyHealthPageMedicalDevicesComponent } from '../components/my-health/pages/medical_devices/medical_devices.component';
import { MyHealthPageMedicalProceduresComponent } from '../components/my-health/pages/medical_procedures/medical_procedures.component';
import { MyHealthPageMedicationComponent } from '../components/my-health/pages/medication/medication.component';
import { MyHealthPageUsedMedicationComponent } from '../components/my-health/pages/used_medication/used_medication.component';
import { MyHealthPageEcographyComponent } from '../components/my-health/pages/ecography/ecography.component';

@NgModule({
  declarations: [
    LogoComponent,
    HeaderComponent,
    ChartComponent,
    SpinnerComponent,
    PageLoadingComponent,
    ModalEditingMeasurementComponent,
    ModalEditUserPermissionsComponent,
    ModalEditingHealthComponent,
    ModalHelpButtonComponent,
    ModalAddPatientComponent,
    MoodFormComponent,
    ModalUserByCodeComponent,
    AddPatientComponent,
    UserByCodeFormComponent,
    UserWithoutEmailFormComponent,
    ChooseUserPermissionsComponent,
    DashboardComponent,
    NotificationsComponent,
    MyMeasurementsComponent,
    MyHealthComponent,
    MyPatientsComponent,
    MySupervisorsComponent,
    CommentsComponent,
    TimelineComponent,
    CategoryHeaderComponent,
    InvitationCodeComponent,
    ViewLogWithCommentsComponent,
    LogsListComponent,
    
    // -- [ MY MEASUREMENTS MODULES ]  -- \\
    // FORMS
    TensionFormComponent,
    HeightFormComponent,
    WeightFormComponent,
    CholesterolFormComponent,
    BloodGlucoseFormComponent,
    SymptomsFormComponent,
    
    // PAGES
    TensionComponent,
    HeightComponent,
    WeightComponent,
    CholesterolComponent,
    BloodGlucoseComponent,
    SymptomComponent,


    // -- [ MY HEALTH MODULES ]  -- \\
    // FORMS
    MyHealthFormAllergiesComponent,
    MyHealthFormDiseaseComponent,
    MyHealthFormEmotionalStateComponent,
    MyHealthFormEventsComponent,
    MyHealthFormFamilyHistoryComponent,
    MyHealthFormAppointmentsComponent,
    MyHealthFormImmunizationsComponent,
    MyHealthFormInsuranceComponent,
    MyHealthFormLaboratoryResultsComponent,
    MyHealthFormMedicalDevicesComponent,
    MyHealthFormMedicalProceduresComponent,
    MyHealthFormMedicationComponent,
    MyHealthFormUsedMedicationComponent,
    MyHealthFormEcographyComponent,

    // PAGES
    MyHealthPageAllergiesComponent,
    MyHealthPageDiseaseComponent,
    MyHealthPageEmotionalStateComponent,
    MyHealthPageEventsComponent,
    MyHealthPageFamilyHistoryComponent,
    MyHealthPageAppointmentsComponent,
    MyHealthPageImmunizationsComponent,
    MyHealthPageInsuranceComponent,
    MyHealthPageLaboratoryResultsComponent,
    MyHealthPageMedicalDevicesComponent,
    MyHealthPageMedicalProceduresComponent,
    MyHealthPageMedicationComponent,
    MyHealthPageUsedMedicationComponent,
    MyHealthPageEcographyComponent
  ],
  imports: [
    CommonModule,
    AllMaterials,
    ChartsModule,
    TranslateModule
  ],
  exports: [
    AllMaterials,
    LogoComponent,
    HeaderComponent,
    ChartComponent,
    SpinnerComponent,
    PageLoadingComponent,
    ModalEditingMeasurementComponent,
    ModalEditUserPermissionsComponent,
    ModalHelpButtonComponent,
    ModalEditingHealthComponent,
    ModalAddPatientComponent,
    MoodFormComponent,
    ModalUserByCodeComponent,
    AddPatientComponent,
    UserByCodeFormComponent,
    UserWithoutEmailFormComponent,
    ChooseUserPermissionsComponent,
    DashboardComponent,
    NotificationsComponent,
    MyMeasurementsComponent,
    MyHealthComponent,
    MyPatientsComponent,
    MySupervisorsComponent,
    CommentsComponent,
    TimelineComponent,
    CategoryHeaderComponent,
    InvitationCodeComponent,
    ViewLogWithCommentsComponent,
    LogsListComponent,

    // -- [ MY MEASUREMENTS MODULES ]  -- \\
    // FORMS
    TensionFormComponent,
    HeightFormComponent,
    WeightFormComponent,
    CholesterolFormComponent,
    BloodGlucoseFormComponent,
    SymptomsFormComponent,

    // PAGES
    TensionComponent,
    HeightComponent,
    WeightComponent,
    CholesterolComponent,
    BloodGlucoseComponent,
    SymptomComponent,

    // -- [ MY HEALTH MODULES ]  -- \\
    // FORMS
    MyHealthFormAllergiesComponent,
    MyHealthFormDiseaseComponent,
    MyHealthFormEmotionalStateComponent,
    MyHealthFormEventsComponent,
    MyHealthFormFamilyHistoryComponent,
    MyHealthFormAppointmentsComponent,
    MyHealthFormImmunizationsComponent,
    MyHealthFormInsuranceComponent,
    MyHealthFormLaboratoryResultsComponent,
    MyHealthFormMedicalDevicesComponent,
    MyHealthFormMedicalProceduresComponent,
    MyHealthFormMedicationComponent,
    MyHealthFormUsedMedicationComponent,
    MyHealthFormEcographyComponent,

    // PAGES
    MyHealthPageAllergiesComponent,
    MyHealthPageDiseaseComponent,
    MyHealthPageEmotionalStateComponent,
    MyHealthPageEventsComponent,
    MyHealthPageFamilyHistoryComponent,
    MyHealthPageAppointmentsComponent,
    MyHealthPageImmunizationsComponent,
    MyHealthPageInsuranceComponent,
    MyHealthPageLaboratoryResultsComponent,
    MyHealthPageMedicalDevicesComponent,
    MyHealthPageMedicalProceduresComponent,
    MyHealthPageMedicationComponent,
    MyHealthPageUsedMedicationComponent,
    MyHealthPageEcographyComponent
  ]
})
export class SharedModule {}