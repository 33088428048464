import { Injectable } from '@angular/core';

import { MatSnackBar } from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AlertService 
{
  constructor(public snackBar: MatSnackBar) {}

  show(message: string, action: string) 
  {
    this.snackBar.open(message, action, 
    {
      duration: 2000,
    });
  }

  showLongAlert(message: string, action: string) 
  {
    this.snackBar.open(message, action, 
    {
      duration: 6000,
    });
  }
}
