import { Component, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../shared/services/auth.service';

import { User } from '../../shared/models/user.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit
{
  private loading = false;
  private alert_code;

  constructor( private AuthService : AuthService, private Route : ActivatedRoute, private Router : Router ) { }
 
  ngOnInit() 
  {
    if( this.Route.snapshot.queryParams && this.Route.snapshot.queryParams.demo )
    {
      switch( this.Route.snapshot.queryParams.demo )
      {
        case 'user':
        {
          this.AuthService.login("utilizator@medistoric.ro", "parolasecreta").then( () => {
              this.Router.navigate(['user']);
          });
          break;
        }

        case 'carer':
        {
          this.AuthService.login("asistenta@medistoric.ro", "parolasecreta").then( () => {
              this.Router.navigate(['carer']);
          });
          break;
        }

        case 'medic':
        {
          this.AuthService.login("medic@medistoric.ro", "parolasecreta").then( () => {
              this.Router.navigate(['medic']);
          });
          break;
        }
      }
    }
  }

  submitLogin( loginForm : NgForm )
  {
    this.loading = true;

    if( loginForm.valid )
    {
      this.AuthService.login(loginForm.value.email, loginForm.value.password)
      .then((user: User) => {
        if (user && user.role)
        {
          this.loading = false;
          this.Router.navigate([user.role]);
        }
      })
      .catch(err => {
        this.loading = false;
        this.alert_code = err.code;
      });
    }
    else this.loading = false;
  }
}
