import { Injectable } from '@angular/core';

import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate 
{
  constructor(private router: Router, private auth: AuthService) 
  { }

  private unAuthRoutes = ['login', 'register', 'forgot-password'];
  private roles = ['user', 'carer', 'medic'];

  canActivate(route: ActivatedRouteSnapshot) 
  {    
    console.log("[AUTH-GUARD]: Checking local storage..");
    if ( this.auth.getLoggedInUser() ) 
    {
      console.log("[AUTH-GUARD]: Exist local storage");
      let data = this.auth.getLoggedInUser();

      if ( this.roles.indexOf(data.role) == -1 ) 
      {
        // intra in bucla
        console.log("[AUTH-GUARD]: Clause #1");
        this.auth.logout().then( () => {
          this.router.navigate(['/login']);
        });

        return true;
      }

      if (this.unAuthRoutes.indexOf(route.url.toString()) != -1) 
      {
        console.log("[AUTH-GUARD]: Clause #2");

        if( data.email == "utilizator@medistoric.ro" || data.email == "asistenta@medistoric.ro" || data.email == "medic@medistoric.ro")
          this.auth.logout().then( () => {
            
          });
        else
          this.router.navigate([`/${data.role}/home`]);
        return true;
      } 
      else 
      {
        if (data.role == route.url.toString()) 
        {
          console.log("[AUTH-GUARD]: Clause #3");
          return true;
        }
        else
        {
          console.log("[AUTH-GUARD]: Clause #4");
          this.router.navigate([`/${data.role}/home`]);
          return true;
        }
      }
    } 
    else 
    {
      console.log("[AUTH-GUARD]: Does not exist local storage");

      if( route.url.toString().indexOf("invitation/") )
      {
        return true;
      }

      if (this.unAuthRoutes.indexOf(route.url.toString()) != -1) 
      {
        console.log("[AUTH-GUARD]: Clause #5");
        return true;
      }
      else
      {
        console.log("[AUTH-GUARD]: Clause #6");
        this.router.navigate(['/login']);
        return true;
      }
    }
  }
}
