export var symptoms = [
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "crampy",
            "title": "Crampy"
          },
          {
            "index": "dull",
            "title": "Dull"
          },
          {
            "index": "extreme",
            "title": "Extreme"
          },
          {
            "index": "intermittent_episodic",
            "title": "Intermittent or episodic"
          },
          {
            "index": "located_lower_abdomen",
            "title": "Located in lower abdomen"
          },
          {
            "index": "sharp",
            "title": "Sharp"
          }
        ],
        "index": "pain_is"
      },
      {
        "items": [
          {
            "index": "intermittent_episodic",
            "title": "Intermittent or episodic"
          },
          {
            "index": "recent_hours_days",
            "title": "Recent (hours to days)"
          },
          {
            "index": "sudden_minutes_hours",
            "title": "Sudden (minutes to hours)"
          }
        ],
        "index": "onset_is"
      },
      {
        "items": [
          {
            "index": "eating_suspect_food",
            "title": "Eating suspect food"
          },
          {
            "index": "recent_antibiotic_use",
            "title": "Recent antibiotic use"
          }
        ],
        "index": "preceded_by"
      },
      {
        "items": [
          {
            "index": "coughing_other_jarring_movements",
            "title": "Coughing or other jarring movements"
          },
          {
            "index": "eating_certain_foods",
            "title": "Eating certain foods"
          },
          {
            "index": "menstrual_cycle",
            "title": "Menstrual cycle"
          },
          {
            "index": "stress",
            "title": "Stress"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "avoiding_certain_foods",
            "title": "Avoiding certain foods"
          },
          {
            "index": "drinking_more_water",
            "title": "Drinking more water"
          },
          {
            "index": "eating_more_fiber",
            "title": "Eating more fiber"
          }
        ],
        "index": "relieved_by"
      },
      {
        "items": [
          {
            "index": "constipation",
            "title": "Constipation"
          },
          {
            "index": "delayed_growth_sexual_development",
            "title": "Delayed growth or sexual development"
          },
          {
            "index": "diarrhea",
            "title": "Diarrhea"
          },
          {
            "index": "failure_thrive_infant_toddler",
            "title": "Failure to thrive (infant or toddler)"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "lump_abdomen",
            "title": "Lump in abdomen"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "passing_gas",
            "title": "Passing gas"
          },
          {
            "index": "rash",
            "title": "Rash"
          },
          {
            "index": "stools_with_blood",
            "title": "Stools with blood"
          },
          {
            "index": "stools_with_pus",
            "title": "Stools with pus"
          },
          {
            "index": "weight_loss",
            "title": "Weight loss"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "abdominal_pain"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "ongoing_recurrent",
            "title": "Ongoing or recurrent"
          },
          {
            "index": "recent",
            "title": "Recent"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "avoiding_regular_bowel_movements",
            "title": "Avoiding regular bowel movements"
          }
        ],
        "index": "triggered_by"
      },
      {
        "items": [
          {
            "index": "abdominal_pain",
            "title": "Abdominal pain"
          },
          {
            "index": "abdominal_swelling",
            "title": "Abdominal swelling"
          },
          {
            "index": "anal_rectal_pain",
            "title": "Anal or rectal pain"
          },
          {
            "index": "bloody_stools",
            "title": "Bloody stools"
          },
          {
            "index": "diarrhea",
            "title": "Diarrhea"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "loss_appetite",
            "title": "Loss of appetite"
          },
          {
            "index": "leaking_stool",
            "title": "Leaking stool"
          },
          {
            "index": "lump_abdomen",
            "title": "Lump in abdomen"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "constipation"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "dry",
            "title": "Dry"
          },
          {
            "index": "gasping_harsh_barking",
            "title": "Gasping, harsh or barking"
          },
          {
            "index": "producing_phlegm_sputum",
            "title": "Producing phlegm or sputum"
          }
        ],
        "index": "cough_is"
      },
      {
        "items": [
          {
            "index": "new_recent",
            "title": "New or recent"
          },
          {
            "index": "ongoing_recurrent",
            "title": "Ongoing or recurrent"
          },
          {
            "index": "worsening_progressing",
            "title": "Worsening or progressing"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "allergens_irritants",
            "title": "Allergens or irritants"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "bluish_skin_face",
            "title": "Bluish skin on face"
          },
          {
            "index": "chest_pain_tightness",
            "title": "Chest pain or tightness"
          },
          {
            "index": "drooling_difficulty_swallowing",
            "title": "Drooling or difficulty swallowing"
          },
          {
            "index": "new_recent_wheezing",
            "title": "New or recent wheezing"
          },
          {
            "index": "chills_sweating",
            "title": "Chills or sweating"
          },
          {
            "index": "diarrhea",
            "title": "Diarrhea"
          },
          {
            "index": "fatigue",
            "title": "Fatigue"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "headache_facial_pain",
            "title": "Headache or facial pain"
          },
          {
            "index": "hoarse_voice",
            "title": "Hoarse voice"
          },
          {
            "index": "loss_appetite",
            "title": "Loss of appetite"
          },
          {
            "index": "muscle_aches",
            "title": "Muscle aches"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "rapid_heartbeat",
            "title": "Rapid heartbeat"
          },
          {
            "index": "rapid_difficult_breathing",
            "title": "Rapid or difficult breathing"
          },
          {
            "index": "runny_stuffy_nose",
            "title": "Runny or stuffy nose"
          },
          {
            "index": "shortness_breath",
            "title": "Shortness of breath"
          },
          {
            "index": "sneezing",
            "title": "Sneezing"
          },
          {
            "index": "sore_throat",
            "title": "Sore throat"
          },
          {
            "index": "watery_itchy_eyes",
            "title": "Watery or itchy eyes"
          },
          {
            "index": "wheezing",
            "title": "Wheezing"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "cough"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "ongoing_recurrent_weeks_years",
            "title": "Ongoing or recurrent (weeks to years)"
          },
          {
            "index": "recent_days_weeks",
            "title": "Recent (days to weeks)"
          },
          {
            "index": "sudden_hours_days",
            "title": "Sudden (hours to days)"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "eating_suspect_food",
            "title": "Eating suspect food"
          },
          {
            "index": "recent_antibiotic_use",
            "title": "Recent antibiotic use"
          }
        ],
        "index": "preceded_by"
      },
      {
        "items": [
          {
            "index": "eating_certain_foods",
            "title": "Eating certain foods"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "avoiding_certain_foods",
            "title": "Avoiding certain foods"
          }
        ],
        "index": "relieved_by"
      },
      {
        "items": [
          {
            "index": "abdominal_pain_cramping",
            "title": "Abdominal pain or cramping"
          },
          {
            "index": "bloating_abdominal_swelling",
            "title": "Bloating or abdominal swelling"
          },
          {
            "index": "bloody_stools",
            "title": "Bloody stools"
          },
          {
            "index": "decreased_energy_fatigue",
            "title": "Decreased energy or fatigue"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "foulsmelling_stools",
            "title": "Foul-smelling stools"
          },
          {
            "index": "hallucinations",
            "title": "Hallucinations"
          },
          {
            "index": "mucus_stools",
            "title": "Mucus in stools"
          },
          {
            "index": "muscle_joint_aches",
            "title": "Muscle or joint aches"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "passing_gas",
            "title": "Passing gas"
          },
          {
            "index": "stomach_growling_rumbling",
            "title": "Stomach growling or rumbling"
          },
          {
            "index": "weight_loss",
            "title": "Weight loss"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "diarrhea"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "change_air_pressure",
            "title": "Change in air pressure"
          },
          {
            "index": "cold_upper_respiratory_illness",
            "title": "Cold or upper respiratory illness"
          },
          {
            "index": "foreign_object_ear",
            "title": "Foreign object in ear"
          },
          {
            "index": "injury_trauma",
            "title": "Injury or trauma"
          },
          {
            "index": "loud_sudden_noise",
            "title": "Loud, sudden noise"
          }
        ],
        "index": "triggered_by"
      },
      {
        "items": [
          {
            "index": "middle_ear_infection",
            "title": "Middle ear infection"
          }
        ],
        "index": "preceded_by"
      },
      {
        "items": [
          {
            "index": "bleeding_from_ear",
            "title": "Bleeding from ear"
          },
          {
            "index": "clear_pusfilled_drainage",
            "title": "Clear or pus-filled drainage"
          },
          {
            "index": "dizziness_spinning_sensation",
            "title": "Dizziness or spinning sensation"
          },
          {
            "index": "ear_pain_discomfort",
            "title": "Ear pain or discomfort"
          },
          {
            "index": "feeling_fullness_ear",
            "title": "Feeling of fullness in ear"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "headache",
            "title": "Headache"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "ringing_buzzing_ear",
            "title": "Ringing or buzzing in ear"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "ear_problems"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "allergens_other_irritants",
            "title": "Allergens or other irritants"
          },
          {
            "index": "change_air_pressure",
            "title": "Change in air pressure"
          },
          {
            "index": "cold_upper_respiratory_illness",
            "title": "Cold or upper respiratory illness"
          },
          {
            "index": "foreign_object_ear",
            "title": "Foreign object in ear"
          },
          {
            "index": "injury_trauma",
            "title": "Injury or trauma"
          },
          {
            "index": "loud_sudden_noise",
            "title": "Loud, sudden noise"
          }
        ],
        "index": "triggered_by"
      },
      {
        "items": [
          {
            "index": "bleeding_from_ear",
            "title": "Bleeding from ear"
          },
          {
            "index": "clear_pusfilled_drainage",
            "title": "Clear or pus-filled drainage"
          },
          {
            "index": "cough",
            "title": "Cough"
          },
          {
            "index": "decreased_hearing",
            "title": "Decreased hearing"
          },
          {
            "index": "difficulty_sleeping_irritability_infant_toddler",
            "title": "Difficulty sleeping or irritability (infant or toddler)"
          },
          {
            "index": "dizziness_spinning_sensation",
            "title": "Dizziness or spinning sensation"
          },
          {
            "index": "feeling_fullness_ear",
            "title": "Feeling of fullness in ear"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "headache_facial_pain",
            "title": "Headache or facial pain"
          },
          {
            "index": "itchy_nose_mouth_throat",
            "title": "Itchy nose, mouth or throat"
          },
          {
            "index": "mild_body_aches",
            "title": "Mild body aches"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "redness_ear_canal",
            "title": "Redness in ear canal"
          },
          {
            "index": "redness_swelling_outer_ear",
            "title": "Redness or swelling of outer ear"
          },
          {
            "index": "ringing_buzzing_ear",
            "title": "Ringing or buzzing in ear"
          },
          {
            "index": "runny_stuffy_nose",
            "title": "Runny or stuffy nose"
          },
          {
            "index": "scaly_flaking_skin_outer_ear",
            "title": "Scaly or flaking skin on outer ear"
          },
          {
            "index": "sneezing",
            "title": "Sneezing"
          },
          {
            "index": "sore_throat",
            "title": "Sore throat"
          },
          {
            "index": "watery_itchy_eyes",
            "title": "Watery or itchy eyes"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "earache"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "achy",
            "title": "Achy"
          },
          {
            "index": "dry_itchy",
            "title": "Dry or itchy"
          },
          {
            "index": "gritty_sensation",
            "title": "Gritty sensation"
          },
          {
            "index": "redness_without_actual_discomfort",
            "title": "Redness without actual discomfort"
          },
          {
            "index": "sensitivity_light",
            "title": "Sensitivity to light"
          },
          {
            "index": "severe_pain",
            "title": "Severe pain"
          },
          {
            "index": "stinging_burning_sensation",
            "title": "Stinging or burning sensation"
          }
        ],
        "index": "eye_discomfort_best_described_as"
      },
      {
        "items": [
          {
            "index": "bleeding_the_surface",
            "title": "Bleeding on the surface of the white of the eye"
          },
          {
            "index": "crusted_eyelashes_after_sleeping",
            "title": "Crusted eyelashes after sleeping"
          },
          {
            "index": "excessive_tearing",
            "title": "Excessive tearing"
          },
          {
            "index": "red_painful_lump_the_eyelid",
            "title": "Red, painful lump on the eyelid"
          },
          {
            "index": "redness",
            "title": "Redness"
          },
          {
            "index": "stringy_mucus_around",
            "title": "Stringy mucus in or around the eye"
          },
          {
            "index": "swelling_around_the_eye",
            "title": "Swelling around the eye"
          }
        ],
        "index": "appearance_of_eye_includes"
      },
      {
        "items": [
          {
            "index": "blurred_vision",
            "title": "Blurred vision"
          },
          {
            "index": "dark_floating_spots_vision",
            "title": "Dark, floating spots in vision"
          },
          {
            "index": "vision_loss",
            "title": "Vision loss"
          }
        ],
        "index": "vision_problem_includes"
      },
      {
        "items": [
          {
            "index": "allergens_other_irritants",
            "title": "Allergens or other irritants"
          },
          {
            "index": "injury_trauma",
            "title": "Injury or trauma"
          }
        ],
        "index": "triggered_by"
      },
      {
        "items": [
          {
            "index": "eye_movement",
            "title": "Eye movement"
          }
        ],
        "index": "worsened_by"
      },
      {
        "items": [
          {
            "index": "runny_stuffy_nose",
            "title": "Runny or stuffy nose"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "eye_discomfort_and_redness"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "blurry_distant_objects",
            "title": "Blurry distant objects"
          },
          {
            "index": "blurry_nearby_objects",
            "title": "Blurry nearby objects"
          },
          {
            "index": "blurry_vision_at_all_distances",
            "title": "Blurry vision at all distances"
          },
          {
            "index": "bright_zigzag_lines",
            "title": "Bright zigzag lines"
          },
          {
            "index": "fading_colors",
            "title": "Fading colors"
          },
          {
            "index": "flashes_light",
            "title": "Flashes of light"
          },
          {
            "index": "inability_distinguish",
            "title": "Inability to distinguish certain shades of color"
          },
          {
            "index": "objects_appear_crooked",
            "title": "Objects appear crooked or distorted"
          },
          {
            "index": "sensitivity_light",
            "title": "Sensitivity to light"
          },
          {
            "index": "shimmering_spots_stars",
            "title": "Shimmering spots or stars"
          },
          {
            "index": "spots_strings_floating",
            "title": "Spots or strings floating in field of vision"
          },
          {
            "index": "vision_loss_partial_total",
            "title": "Vision loss, partial or total"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "one_eye",
            "title": "One eye"
          }
        ],
        "index": "problem_affects"
      },
      {
        "items": [
          {
            "index": "gradual_progressive",
            "title": "Gradual or progressive"
          },
          {
            "index": "recent_hours_days",
            "title": "Recent (hours to days)"
          },
          {
            "index": "sudden_seconds_minutes",
            "title": "Sudden (seconds to minutes)"
          }
        ],
        "index": "onset_is"
      },
      {
        "items": [
          {
            "index": "usually_no_longer_30_minutes",
            "title": "Usually no longer than 30 minutes"
          }
        ],
        "index": "duration_is"
      },
      {
        "items": [
          {
            "index": "favoring_one_eye_over_the_other",
            "title": "Favoring one eye over the other"
          },
          {
            "index": "having_one_eye_that_wanders",
            "title": "Having one eye that wanders"
          },
          {
            "index": "holding_objects_away_from_his_her_face",
            "title": "Holding objects away from his or her face"
          },
          {
            "index": "holding_objects_close_his_her_face",
            "title": "Holding objects close to his or her face"
          },
          {
            "index": "squinting_frequently",
            "title": "Squinting frequently"
          }
        ],
        "index": "you_observe_your_child"
      },
      {
        "items": [
          {
            "index": "eye_pain_discomfort",
            "title": "Eye pain or discomfort"
          },
          {
            "index": "eye_redness",
            "title": "Eye redness"
          },
          {
            "index": "eyestrain",
            "title": "Eyestrain"
          },
          {
            "index": "headache",
            "title": "Headache"
          },
          {
            "index": "other_sensory_disturbances",
            "title": "Other sensory disturbances"
          },
          {
            "index": "swelling_around_the_eye",
            "title": "Swelling around the eye"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "eye_problems"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "abdominal_pain_cramping",
            "title": "Abdominal pain or cramping"
          },
          {
            "index": "bloody_cloudy_urine",
            "title": "Bloody or cloudy urine"
          },
          {
            "index": "bloody_stools",
            "title": "Bloody stools"
          },
          {
            "index": "chest_pain_tightness",
            "title": "Chest pain or tightness"
          },
          {
            "index": "chills",
            "title": "Chills"
          },
          {
            "index": "cough",
            "title": "Cough"
          },
          {
            "index": "decreased_energy_fatigue",
            "title": "Decreased energy or fatigue"
          },
          {
            "index": "diarrhea",
            "title": "Diarrhea"
          },
          {
            "index": "ear_pain_discharge",
            "title": "Ear pain or discharge"
          },
          {
            "index": "frequent_painful_urination",
            "title": "Frequent or painful urination"
          },
          {
            "index": "headache",
            "title": "Headache"
          },
          {
            "index": "loss_appetite",
            "title": "Loss of appetite"
          },
          {
            "index": "muscle_aches",
            "title": "Muscle aches"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "night_sweats",
            "title": "Night sweats"
          },
          {
            "index": "rapid_heartbeat",
            "title": "Rapid heartbeat"
          },
          {
            "index": "rapid_difficult_breathing",
            "title": "Rapid or difficult breathing"
          },
          {
            "index": "rash",
            "title": "Rash"
          },
          {
            "index": "red_spots_at_the_back_the_roof_the_mouth",
            "title": "Red spots at the back of the roof of the mouth"
          },
          {
            "index": "red_swollen_tonsils",
            "title": "Red, swollen tonsils"
          },
          {
            "index": "runny_stuffy_nose",
            "title": "Runny or stuffy nose"
          },
          {
            "index": "shortness_breath",
            "title": "Shortness of breath"
          },
          {
            "index": "sneezing",
            "title": "Sneezing"
          },
          {
            "index": "sore_throat",
            "title": "Sore throat"
          },
          {
            "index": "swollen_glands_lymph_nodes_neck",
            "title": "Swollen glands (lymph nodes) in neck"
          },
          {
            "index": "watery_itchy_eyes",
            "title": "Watery or itchy eyes"
          },
          {
            "index": "wheezing",
            "title": "Wheezing"
          },
          {
            "index": "white_patches_pus_tonsils",
            "title": "White patches or pus on tonsils"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "fever"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "intense",
            "title": "Intense"
          },
          {
            "index": "mild_moderate",
            "title": "Mild to moderate"
          },
          {
            "index": "moderate_severe",
            "title": "Moderate to severe"
          },
          {
            "index": "pressure_squeezing_sensation",
            "title": "Pressure or squeezing sensation"
          },
          {
            "index": "stabbing_burning",
            "title": "Stabbing or burning"
          },
          {
            "index": "throbbing",
            "title": "Throbbing"
          }
        ],
        "index": "pain_is"
      },
      {
        "items": [
          {
            "index": "around_one_eye_radiates",
            "title": "Around one eye or radiates from one eye"
          },
          {
            "index": "on_both_sides_head",
            "title": "On both sides of head"
          },
          {
            "index": "on_one_side_head",
            "title": "On one side of head"
          }
        ],
        "index": "pain_located"
      },
      {
        "items": [
          {
            "index": "gradual",
            "title": "Gradual"
          },
          {
            "index": "preceded_by_a_head_injury",
            "title": "Preceded by a head injury or fall"
          },
          {
            "index": "preceded_by_frequent_use",
            "title": "Preceded by frequent use of pain medication"
          },
          {
            "index": "preceded_by_visual",
            "title": "Preceded by visual or other sensory disturbances"
          },
          {
            "index": "sudden",
            "title": "Sudden"
          }
        ],
        "index": "onset_is"
      },
      {
        "items": [
          {
            "index": "a_few_hours_days",
            "title": "A few hours to days"
          },
          {
            "index": "a_few_minutes_hours",
            "title": "A few minutes to hours"
          }
        ],
        "index": "duration_of_headache_is"
      },
      {
        "items": [
          {
            "index": "gradually_becomes_more_frequent",
            "title": "Gradually becomes more frequent"
          },
          {
            "index": "is_daily",
            "title": "Is daily"
          },
          {
            "index": "is_often_the_morning",
            "title": "Is often in the morning"
          },
          {
            "index": "is_often_every_day",
            "title": "Is often the same time every day"
          }
        ],
        "index": "recurrence_of_headache"
      },
      {
        "items": [
          {
            "index": "change_sleep_patterns",
            "title": "Change in sleep patterns"
          },
          {
            "index": "clenching_grinding_teeth",
            "title": "Clenching or grinding teeth"
          },
          {
            "index": "everyday_activities",
            "title": "Everyday activities"
          },
          {
            "index": "hormonal_changes",
            "title": "Hormonal changes"
          },
          {
            "index": "poor_posture",
            "title": "Poor posture"
          },
          {
            "index": "stress",
            "title": "Stress"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "lying_down_the_dark",
            "title": "Lying down in the dark"
          },
          {
            "index": "overthecounter_pain_medication",
            "title": "Over-the-counter pain medication"
          },
          {
            "index": "rest",
            "title": "Rest"
          }
        ],
        "index": "relieved_by"
      },
      {
        "items": [
          {
            "index": "achy_joints_muscles",
            "title": "Achy joints or muscles"
          },
          {
            "index": "change_personality",
            "title": "Change in personality, behaviors or mental status"
          },
          {
            "index": "confusion",
            "title": "Confusion"
          },
          {
            "index": "difficulty_speaking",
            "title": "Difficulty speaking"
          },
          {
            "index": "dizziness",
            "title": "Dizziness"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "jaw_pain",
            "title": "Jaw pain"
          },
          {
            "index": "memory_loss",
            "title": "Memory loss or forgetfulness"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "persistent_weakness_numbness",
            "title": "Persistent weakness or numbness"
          },
          {
            "index": "restlessness_agitation",
            "title": "Restlessness or agitation"
          },
          {
            "index": "runny_stuffy_nose",
            "title": "Runny or stuffy nose"
          },
          {
            "index": "seizures",
            "title": "Seizures"
          },
          {
            "index": "sensitivity_light_noise",
            "title": "Sensitivity to light or noise"
          },
          {
            "index": "stiff_neck",
            "title": "Stiff neck"
          },
          {
            "index": "vision_problems",
            "title": "Vision problems"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "headaches"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "bone_pain",
            "title": "Bone pain"
          },
          {
            "index": "dull_achy_pain",
            "title": "Dull or achy pain"
          },
          {
            "index": "joint_pain",
            "title": "Joint pain"
          },
          {
            "index": "muscles_aches_cramps_pain",
            "title": "Muscles aches, cramps or pain"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "around_the_kneecap",
            "title": "Around the kneecap"
          },
          {
            "index": "in_a_specific_area",
            "title": "In a specific area"
          },
          {
            "index": "in_various_sites_as_it_moves_from_one_location_another",
            "title": "In various sites as it moves from one location to another"
          },
          {
            "index": "throughout_the_whole_body",
            "title": "Throughout the whole body"
          }
        ],
        "index": "located"
      },
      {
        "items": [
          {
            "index": "gradual",
            "title": "Gradual"
          },
          {
            "index": "intermittent_episodic",
            "title": "Intermittent or episodic"
          },
          {
            "index": "sudden",
            "title": "Sudden"
          }
        ],
        "index": "onset_is"
      },
      {
        "items": [
          {
            "index": "tick_bite_possible_exposure_ticks",
            "title": "Tick bite or possible exposure to ticks"
          }
        ],
        "index": "preceded_by"
      },
      {
        "items": [
          {
            "index": "at_night",
            "title": "At night"
          },
          {
            "index": "in_late_afternoon_early_evening",
            "title": "In late afternoon or early evening"
          }
        ],
        "index": "occurs"
      },
      {
        "items": [
          {
            "index": "everyday_activities",
            "title": "Everyday activities"
          },
          {
            "index": "injury",
            "title": "Injury"
          },
          {
            "index": "overuse",
            "title": "Overuse"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "movement",
            "title": "Movement"
          },
          {
            "index": "movement_weightbearing_activity",
            "title": "Movement or weight-bearing activity"
          },
          {
            "index": "prolonged_sitting_standing",
            "title": "Prolonged sitting or standing"
          },
          {
            "index": "rest_inactivity",
            "title": "Rest or inactivity"
          }
        ],
        "index": "worsened_by"
      },
      {
        "items": [
          {
            "index": "bruising_discoloring",
            "title": "Bruising or discoloring"
          },
          {
            "index": "concentration_memory_problems",
            "title": "Concentration or memory problems"
          },
          {
            "index": "decreased_range_motion",
            "title": "Decreased range of motion"
          },
          {
            "index": "easy_bruising_bleeding",
            "title": "Easy bruising or bleeding"
          },
          {
            "index": "feeling_instability",
            "title": "Feeling of instability"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "fever_with_no_apparent_cause",
            "title": "Fever with no apparent cause"
          },
          {
            "index": "grating_sensation",
            "title": "Grating sensation"
          },
          {
            "index": "headache",
            "title": "Headache"
          },
          {
            "index": "inability_bear_weight",
            "title": "Inability to bear weight"
          },
          {
            "index": "loss_appetite",
            "title": "Loss of appetite"
          },
          {
            "index": "pain_near_the_hip_—_the_groin_thigh_buttock_—_the_shoulder_knee_hand_or_foot",
            "title": "Pain near the hip — in the groin, thigh, or buttock — or in the shoulder, knee, hand or foot"
          },
          {
            "index": "persistent_decreased_energy_fatigue",
            "title": "Persistent decreased energy or fatigue"
          },
          {
            "index": "rash",
            "title": "Rash"
          },
          {
            "index": "skin_redness_warmth_near_affected_area",
            "title": "Skin redness or warmth near affected area"
          },
          {
            "index": "stiffness",
            "title": "Stiffness"
          },
          {
            "index": "swelling_tenderness_near_affected_area",
            "title": "Swelling or tenderness near affected area"
          },
          {
            "index": "swollen_lymph_nodes_neck_groin_underarms",
            "title": "Swollen lymph nodes in neck, groin or underarms"
          },
          {
            "index": "tiny_red_spots_skin",
            "title": "Tiny red spots on skin"
          },
          {
            "index": "unexplained_newonset_limping",
            "title": "Unexplained or new-onset limping"
          },
          {
            "index": "unintended_weight_loss",
            "title": "Unintended weight loss"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "joint_pain_or_muscle_pain"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "new_recent",
            "title": "New or recent"
          },
          {
            "index": "ongoing_recurrent",
            "title": "Ongoing or recurrent"
          },
          {
            "index": "recent_worsening",
            "title": "Recent and worsening"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "clear_watery",
            "title": "Clear or watery"
          },
          {
            "index": "mainly_from_one_side_'unilateral'_only",
            "title": "Mainly from one side ('unilateral') only"
          },
          {
            "index": "yellow_greenish",
            "title": "Yellow or greenish"
          }
        ],
        "index": "nasal_discharge_is"
      },
      {
        "items": [
          {
            "index": "bad_breath",
            "title": "Bad breath"
          },
          {
            "index": "bluecolored_skin_under_the_eyes",
            "title": "Blue-colored skin under the eyes"
          },
          {
            "index": "cough",
            "title": "Cough"
          },
          {
            "index": "decreased_energy_fatigue",
            "title": "Decreased energy or fatigue"
          },
          {
            "index": "delayed_growth",
            "title": "Delayed growth"
          },
          {
            "index": "ear_pain",
            "title": "Ear pain"
          },
          {
            "index": "enlargement_rounding_fingertips_toes",
            "title": "Enlargement or rounding of fingertips or toes"
          },
          {
            "index": "failure_thrive_infant_toddler",
            "title": "Failure to thrive (infant and toddler)"
          },
          {
            "index": "fatigue",
            "title": "Fatigue"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "foul_odor_bad_breath",
            "title": "Foul odor or bad breath"
          },
          {
            "index": "foulsmelling_stools",
            "title": "Foul-smelling stools"
          },
          {
            "index": "headache_facial_pain",
            "title": "Headache or facial pain"
          },
          {
            "index": "itchy_eyes_nose_mouth_throat",
            "title": "Itchy eyes, nose, mouth or throat"
          },
          {
            "index": "mild_body_aches",
            "title": "Mild body aches"
          },
          {
            "index": "nosebleeds",
            "title": "Nosebleeds"
          },
          {
            "index": "object_nostril",
            "title": "Object in nostril"
          },
          {
            "index": "recurrent_severe_respiratory_infections",
            "title": "Recurrent, severe respiratory infections"
          },
          {
            "index": "reduced_sense_smell_taste",
            "title": "Reduced sense of smell or taste"
          },
          {
            "index": "runny_nose",
            "title": "Runny nose"
          },
          {
            "index": "sneezing",
            "title": "Sneezing"
          },
          {
            "index": "snoring",
            "title": "Snoring"
          },
          {
            "index": "sore_throat",
            "title": "Sore throat"
          },
          {
            "index": "upper_jaw_tooth_pain",
            "title": "Upper jaw or tooth pain"
          },
          {
            "index": "watery_eyes",
            "title": "Watery eyes"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "nasal_congestion"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "eating_certain_foods",
            "title": "Eating certain foods"
          },
          {
            "index": "travel_motion",
            "title": "Travel or motion"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "intermittent_episodic",
            "title": "Intermittent or episodic"
          },
          {
            "index": "recent_hours_days",
            "title": "Recent (hours to days)"
          },
          {
            "index": "sudden_minutes_hours",
            "title": "Sudden (minutes to hours)"
          },
          {
            "index": "within_the_first_two_months_life",
            "title": "Within the first two months of life"
          }
        ],
        "index": "onset_is"
      },
      {
        "items": [
          {
            "index": "abdominal_bloating_swelling",
            "title": "Abdominal bloating or swelling"
          },
          {
            "index": "abdominal_pain_discomfort_cramps",
            "title": "Abdominal pain, discomfort or cramps"
          },
          {
            "index": "abdominal_pain_that's_severe_progressive",
            "title": "Abdominal pain that's severe and progressive"
          },
          {
            "index": "constipation",
            "title": "Constipation"
          },
          {
            "index": "cough",
            "title": "Cough"
          },
          {
            "index": "diarrhea",
            "title": "Diarrhea"
          },
          {
            "index": "dizziness",
            "title": "Dizziness"
          },
          {
            "index": "failure_thrive_gain_weight_infant",
            "title": "Failure to thrive or gain weight (infant)"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "fussiness_irritability",
            "title": "Fussiness or irritability"
          },
          {
            "index": "hives_rash",
            "title": "Hives or rash"
          },
          {
            "index": "swelling_tingling_lips_face_tongue",
            "title": "Swelling or tingling of lips, face and tongue"
          },
          {
            "index": "wet_burps_spit_up",
            "title": "Wet burps or spit up"
          },
          {
            "index": "wheezing",
            "title": "Wheezing"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "nausea_or_vomiting"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "blisters",
            "title": "Blisters"
          },
          {
            "index": "itchy",
            "title": "Itchy"
          },
          {
            "index": "lacy_slightly_raised",
            "title": "Lacy and slightly raised"
          },
          {
            "index": "oozing_sores_that_become_crusty",
            "title": "Oozing sores that become crusty"
          },
          {
            "index": "pusfilled_crusty_swellings",
            "title": "Pus-filled, crusty swellings"
          },
          {
            "index": "raised_red_welts",
            "title": "Raised, red welts"
          },
          {
            "index": "red_discolored_blotches",
            "title": "Red or discolored blotches"
          },
          {
            "index": "red_discolored_bumps",
            "title": "Red or discolored bumps"
          },
          {
            "index": "red_discolored_skin",
            "title": "Red or discolored skin"
          },
          {
            "index": "ring_shaped",
            "title": "Ring shaped"
          },
          {
            "index": "scaly_flaky",
            "title": "Scaly or flaky"
          },
          {
            "index": "thick_leathery",
            "title": "Thick and leathery"
          }
        ],
        "index": "rash_best_described_as"
      },
      {
        "items": [
          {
            "index": "a_small_site_then_spreads",
            "title": "A small site and then spreads"
          },
          {
            "index": "buttocks",
            "title": "Buttocks"
          },
          {
            "index": "diaper_area",
            "title": "Diaper area"
          },
          {
            "index": "face_neck",
            "title": "Face or neck"
          },
          {
            "index": "folds_skin",
            "title": "Folds of skin"
          },
          {
            "index": "genital_area",
            "title": "Genital area"
          },
          {
            "index": "hands_feet",
            "title": "Hands or feet"
          },
          {
            "index": "scalp",
            "title": "Scalp"
          },
          {
            "index": "trunk",
            "title": "Trunk"
          }
        ],
        "index": "affects"
      },
      {
        "items": [
          {
            "index": "eating_certain_foods",
            "title": "Eating certain foods"
          },
          {
            "index": "exposure_allergen_irritant",
            "title": "Exposure to allergen or irritant"
          },
          {
            "index": "exposure_irritating_plants",
            "title": "Exposure to irritating plants"
          },
          {
            "index": "prolonged_exposure_heat",
            "title": "Prolonged exposure to heat"
          },
          {
            "index": "tick_bite_possible_exposure_ticks",
            "title": "Tick bite or possible exposure to ticks"
          }
        ],
        "index": "preceded_by"
      },
      {
        "items": [
          {
            "index": "brittle_hair_hair_loss",
            "title": "Brittle hair and hair loss"
          },
          {
            "index": "dandruff",
            "title": "Dandruff"
          },
          {
            "index": "diarrhea",
            "title": "Diarrhea"
          },
          {
            "index": "difficulty_breathing",
            "title": "Difficulty breathing"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "headache",
            "title": "Headache"
          },
          {
            "index": "joint_pain",
            "title": "Joint pain"
          },
          {
            "index": "loss_appetite",
            "title": "Loss of appetite"
          },
          {
            "index": "mouth_tongue_sores",
            "title": "Mouth or tongue sores"
          },
          {
            "index": "muscle_aches_pains",
            "title": "Muscle aches or pains"
          },
          {
            "index": "muscle_weakness",
            "title": "Muscle weakness"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "sore_throat",
            "title": "Sore throat"
          },
          {
            "index": "swelling_lips_face_tongue",
            "title": "Swelling of lips, face or tongue"
          },
          {
            "index": "tender_painful_scalp",
            "title": "Tender or painful scalp"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "skin_rashes"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "bluecolored_skin_lips",
            "title": "Blue-colored skin or lips"
          },
          {
            "index": "chills_sweating",
            "title": "Chills or sweating"
          },
          {
            "index": "cough",
            "title": "Cough"
          },
          {
            "index": "difficult_painful_swallowing",
            "title": "Difficult or painful swallowing"
          },
          {
            "index": "difficult_raspy_breathing",
            "title": "Difficult or raspy breathing"
          },
          {
            "index": "enlarged_tender_glands_lymph_nodes_neck",
            "title": "Enlarged, tender glands (lymph nodes) in neck"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "headache_facial_pain",
            "title": "Headache or facial pain"
          },
          {
            "index": "heartburn",
            "title": "Heartburn"
          },
          {
            "index": "hoarse_muffled_voice",
            "title": "Hoarse or muffled voice"
          },
          {
            "index": "mild_body_aches",
            "title": "Mild body aches"
          },
          {
            "index": "red_swollen_tonsils",
            "title": "Red, swollen tonsils"
          },
          {
            "index": "runny_stuffy_nose",
            "title": "Runny or stuffy nose"
          },
          {
            "index": "skin_rash",
            "title": "Skin rash"
          },
          {
            "index": "sneezing",
            "title": "Sneezing"
          },
          {
            "index": "watery_eyes",
            "title": "Watery eyes"
          },
          {
            "index": "white_patches_pus_tonsils",
            "title": "White patches or pus on tonsils"
          },
          {
            "index": "yellow_greenish_phlegm_discharge_from_nose",
            "title": "Yellow or greenish phlegm or discharge from nose"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "sore_throat"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "blood_urine",
            "title": "Blood in urine"
          },
          {
            "index": "change_urine_color",
            "title": "Change in urine color"
          },
          {
            "index": "cloudy_urine",
            "title": "Cloudy urine"
          },
          {
            "index": "frequent_urination",
            "title": "Frequent urination"
          },
          {
            "index": "new_episodes_bedwetting",
            "title": "New episodes of bed-wetting"
          },
          {
            "index": "new_episodes_daytime_wetting_accidents",
            "title": "New episodes of daytime wetting or accidents"
          },
          {
            "index": "pain_burning_when_urinating",
            "title": "Pain or burning when urinating"
          },
          {
            "index": "strong_persistent_urge_urinate",
            "title": "Strong, persistent urge to urinate"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "severe_bloody_diarrhea",
            "title": "Severe or bloody diarrhea"
          },
          {
            "index": "sore_throat_upper_respiratory_infection",
            "title": "Sore throat or upper respiratory infection"
          }
        ],
        "index": "preceded_by"
      },
      {
        "items": [
          {
            "index": "abdominal_pain_discomfort",
            "title": "Abdominal pain or discomfort"
          },
          {
            "index": "back_side_groin_pain",
            "title": "Back, side or groin pain"
          },
          {
            "index": "fatigue_weakness",
            "title": "Fatigue or weakness"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "irritability_infant",
            "title": "Irritability (infant)"
          },
          {
            "index": "pale_skin",
            "title": "Pale skin"
          },
          {
            "index": "swelling_face_hands_feet_abdomen",
            "title": "Swelling in face, hands, feet or abdomen"
          },
          {
            "index": "vomiting_infant",
            "title": "Vomiting (infant)"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "urinary_problems"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "new_recent",
            "title": "New or recent"
          },
          {
            "index": "ongoing_recurrent",
            "title": "Ongoing or recurrent"
          }
        ],
        "index": "wheezing_is"
      },
      {
        "items": [
          {
            "index": "allergens_irritants",
            "title": "Allergens or irritants"
          },
          {
            "index": "exposure_cold_air",
            "title": "Exposure to cold air"
          },
          {
            "index": "mild_moderate_exertion",
            "title": "Mild to moderate exertion"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "is_dry",
            "title": "Is dry"
          },
          {
            "index": "gasping_harsh_barking",
            "title": "Gasping, harsh or barking"
          },
          {
            "index": "produces_phlegm_sputum",
            "title": "Produces phlegm or sputum"
          }
        ],
        "index": "related_cough"
      },
      {
        "items": [
          {
            "index": "bluish_skin_face",
            "title": "Bluish skin on face"
          },
          {
            "index": "chest_pain_tightness",
            "title": "Chest pain or tightness"
          },
          {
            "index": "delayed_growth",
            "title": "Delayed growth"
          },
          {
            "index": "drooling_difficulty_swallowing",
            "title": "Drooling or difficulty swallowing"
          },
          {
            "index": "enlargement_rounding_fingertips_toes",
            "title": "Enlargement or rounding of fingertips or toes"
          },
          {
            "index": "failure_thrive_infant_toddler",
            "title": "Failure to thrive (infant and toddler)"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "foulsmelling_stools",
            "title": "Foul-smelling stools"
          },
          {
            "index": "headache_facial_pain",
            "title": "Headache or facial pain"
          },
          {
            "index": "hoarse_voice",
            "title": "Hoarse voice"
          },
          {
            "index": "mild_body_aches",
            "title": "Mild body aches"
          },
          {
            "index": "rapid_heartbeat",
            "title": "Rapid heartbeat"
          },
          {
            "index": "rapid_difficult_breathing",
            "title": "Rapid or difficult breathing"
          },
          {
            "index": "recurrent_severe_respiratory_infections",
            "title": "Recurrent, severe respiratory infections"
          },
          {
            "index": "runny_stuffy_nose",
            "title": "Runny or stuffy nose"
          },
          {
            "index": "shortness_breath",
            "title": "Shortness of breath"
          },
          {
            "index": "sneezing",
            "title": "Sneezing"
          },
          {
            "index": "sore_throat",
            "title": "Sore throat"
          },
          {
            "index": "watery_itchy_eyes",
            "title": "Watery or itchy eyes"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "wheezing"
  }
];