import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import * as health_categorys from '../../jsons/health.json';

import * as measurements from '../../jsons/measurements.json';

@Component({
  selector: 'app-choose-user-permissions',
  templateUrl: './choose-user-permissions.component.html',
  styleUrls: ['./choose-user-permissions.component.css']
})
export class ChooseUserPermissionsComponent implements OnInit 
{
  @Input() link;

  @Output() onCheckCategory = new EventEmitter();

  private measurements;
  private health;

  private permissions = {};

  constructor( ) { }

  ngOnInit()
  {
    this.measurements = measurements.items;
    this.health = health_categorys.items;

    this.initPermissions();
  }

  initPermissions()
  {
    let permissions = {
      measurements:
      {
      },

      health:
      {
      },

      edit: false,
      write: false
    };

    measurements.items.forEach( item => {
      if( this.link )
      {
        if( this.link.permissions['measurements'][item.index] )
          permissions['measurements'][item.index] = true;
        else 
          permissions['measurements'][item.index] = false;
      }
      else permissions['measurements'][item.index] = true;
    });

    health_categorys.items.forEach( item => {
      if( this.link )
      {
        if( this.link.permissions['health'][item.index] )
          permissions['health'][item.index] = true;
        else
          permissions['health'][item.index] = false;
      }
      else permissions['health'][item.index] = true;
    });

    if( this.link )
    {
      if( this.link.permissions['edit'] )
        permissions['edit'] = true;
      else 
        permissions['edit'] = false;
    }
    else permissions['edit'] = true;

    if( this.link )
    {
      if( this.link.permissions['write'] )
        permissions['write'] = true;
      else 
        permissions['write'] = false;
    }
    else permissions['write'] = true;

    this.permissions = permissions;
    this.onCheckCategory.emit(this.permissions);
  }

  getModel( category : string, key : string )
  {
    if( category == "other" )
      return this.permissions[key];

    if( this.permissions[category][key] )
      return this.permissions[category][key];
  }

  changeModelValue( category : string, key : string, event )
  {
    if( category == "other" )
      this.permissions[key] = event;
    else
      this.permissions[category][key] = event;

    this.onCheckCategory.emit(this.permissions);
  }

  DeselectAll()
  {
    this.permissions['write'] = false;
    this.permissions['edit'] = false;

    Object.keys(this.permissions['measurements']).forEach( key => {
      this.permissions['measurements'][key] = false;
    });

    Object.keys(this.permissions['health']).forEach( key => {
      this.permissions['health'][key] = false;
    });

    this.onCheckCategory.emit(this.permissions);
  }

  SelectAll()
  {
    this.permissions['write'] = true;
    this.permissions['edit'] = true;

    Object.keys(this.permissions['measurements']).forEach( key => {
      this.permissions['measurements'][key] = true;
    });

    Object.keys(this.permissions['health']).forEach( key => {
      this.permissions['health'][key] = true;
    });

    this.onCheckCategory.emit(this.permissions);
  }
}