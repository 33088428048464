export const animals = 
[
    "ape",
    "baboon",
    "bear",
    "bee",
    "boar",
    "bufallo",
    "camel",
    "cat",
    "cheetah",
    "chicken",
    "cow",
    "crocodile",
    "deer",
    "dinosaur",
    "dog",
    "donkey",
    "duck",
    "duckling",
    "eagle",
    "elephant",
    "fox",
    "frog",
    "giraffe",
    "goat",
    "hen",
    "hippopotamus",
    "horse",
    "kangaroo",
    "koala",
    "lamb",
    "leopard",
    "lion",
    "monkey",
    "mouse",
    "mountain-goat",
    "octopus",
    "owl",
    "panda",
    "parrot",
    "pig",
    "rabbit",
    "rhino",
    "sea-lion",
    "shark",
    "snake",
    "tiger",
    "turtle",
    "whale",
    "wolf",
    "zebra"
];