import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';

import { NgForm } from '@angular/forms';

import { AuthService } from '../../shared/services/auth.service';

import { SpecialCodeService } from '../../shared/services/special_code.service';

import { AlertService } from '../../shared/services/alert.service';

import { User } from '../../shared/models/user.model';

import * as countries_list from '../../shared/jsons/country_list.json';

import * as _ from 'lodash';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy
{
  private loading = false;
  private role = '';
  private birthday = new Date();
  private countries = [];

  //Subscribers
  private subscribe_Route;

  constructor( private Route : ActivatedRoute, private Router : Router, private AuthService : AuthService, private AlertService : AlertService, private SpecialCodeService : SpecialCodeService )
  {
  }

  ngOnInit()
  {
    window.scroll(0, 0);
    
    this.countries = countries_list.list;
    
    this.subscribe_Route = this.Route.queryParams.subscribe((params: Params) => {
      this.role = params['role'];
    });
  }

  ngOnDestroy()
  {
    this.subscribe_Route.unsubscribe();
  }

  submitRegister( registerForm : NgForm )
  {
    if( registerForm.value.password != registerForm.value.repeat_password || registerForm.value.password.length == 0 || registerForm.value.repeat_password == 0 || !registerForm.valid )
      return console.log("Parolele nu coincid.");

    this.SpecialCodeService.checkCode(registerForm.value.special_code).then( result => {
      let user_formData = _.pick(registerForm.value, ['firstname', 'secondname', 'sex',, 'country', 'phoneNumber', 'email', 'password']);

      user_formData['birthday'] = this.birthday;

      if( user_formData['birthday'] != "undefined" )
        user_formData['birthday'] = new Date(user_formData['birthday']).getTime();

      user_formData['role'] = this.role;

      if( result == true )
      {
        this.loading = true;

        this.AuthService.register(user_formData).then(() => {
          this.AuthService.login(user_formData.email, user_formData.password)
          .then((user : User) => {
            if (user && user.role)
            {
              this.SpecialCodeService.deleteCode(registerForm.value.special_code);

              this.loading = false;
              this.Router.navigate([user.role]);
            }
          })
          .catch(err => {
            this.loading = false;
            console.log(err.message);
          });
        })
        .catch(err => {
          this.loading = false;
          this.AlertService.show(err.message, "Ok");
        });
      }
      else
      {
        delete user_formData.password;

        this.AuthService.createLeads(user_formData).then( () => {
          registerForm.resetForm();
          this.AlertService.showLongAlert("Codul este invalid, momentan nu acceptam utilizatori noi, dar o sa te anuntam cand o sa te poti inregistra.", "Multumesc");
        });
      }
    });
  } 
}


