import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

import { MeasurementService } from '../../../services/measurement.service';

import { HealthService } from '../../../services/health.service';

import { AuthService } from '../../../services/auth.service';

import { UserService } from '../../../services/user.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-logs-list',
  templateUrl: './logs-list.component.html',
  styleUrls: ['./logs-list.component.css']
})
export class LogsListComponent implements OnInit, OnDestroy 
{
  @Input() type : string;
  @Input() category : string;
  @Input() uID : string;
  @Output() selectedLog = new EventEmitter();

  private Logs;
  private user;
  private currentLogID;
  private ready = false;
  private subscribe_MeasurementService;
  private subscribe_HealthService;

  constructor( private MeasurementService : MeasurementService, private HealthService : HealthService, private AuthService : AuthService, private UserService : UserService )
  {
  }

  ngOnInit()
  {
    if( this.uID != this.AuthService.getLoggedInUser().uid )
    {
      this.UserService.getUserByUID(this.uID).then( user => {
        this.user = user;
      });
    }
    else this.user = this.AuthService.getLoggedInUser();

    if( this.type == "measurements" )
    {
      this.subscribe_MeasurementService = this.MeasurementService.getMeasurementsByUID(this.category, this.uID).subscribe( logs => {
        this.Logs = logs;
        this.ready = true;
      });
    }
    else
    {
      this.subscribe_HealthService = this.HealthService.getLogsByUID(this.category, this.uID).subscribe( logs => {
        this.Logs = logs;
        this.ready = true;
      });
    } 
  }

  ngOnDestroy()
  {
    if( this.subscribe_MeasurementService )
      this.subscribe_MeasurementService.unsubscribe();
    else this.subscribe_HealthService.unsubscribe();
  }

  selectLog( logID : string )
  {
    this.currentLogID = logID;
    this.selectedLog.emit(logID);
  }

  getUserAvatarPath( avatar : string )
  {
    return `assets/animals_icons/${avatar}.png`; 
  }
}