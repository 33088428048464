import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';

import { UserService } from '../../../services/user.service';

import { AuthService } from '../../../services/auth.service';

import { MeasurementService } from '../../../services/measurement.service';

import { HealthService } from '../../../services/health.service';

import { ModalEditingMeasurementComponent } from '../../modal-editing-measurement/modal-editing-measurement.component';

import { ModalEditingHealthComponent } from '../../modal-editing-health/modal-editing-health.component';

@Component({
  selector: 'app-view-log-with-comments',
  templateUrl: './view-log-with-comments.component.html',
  styleUrls: ['./view-log-with-comments.component.css']
})
export class ViewLogWithCommentsComponent implements OnInit, OnDestroy 
{
  @Input() type : string;
  @Input() logID : string;

  @ViewChild(ModalEditingMeasurementComponent) EditMeasurementModal : ModalEditingMeasurementComponent;
  @ViewChild(ModalEditingHealthComponent) EditHealthModal : ModalEditingHealthComponent;

  private log;
  private user;
  private subscribe_MeasurementService;
  private subscribe_HealthService;

  constructor( private MeasurementService : MeasurementService, private HealthService : HealthService, private AuthService : AuthService, private UserService : UserService )
  {
  }

  ngOnInit()
  {
    if( this.type == "measurements" )
    {
      this.subscribe_MeasurementService = this.MeasurementService.getMeasurementByID( this.logID ).subscribe( log => {
        if( log )
        {
          this.log = log;

          this.getUser(log['uid']);
        }
        else
        {
          this.log = null;
          this.subscribe_MeasurementService.unsubscribe();
        }
      });
    }
    else
    {
      this.subscribe_HealthService = this.HealthService.getLogByID( this.logID ).subscribe( log => {
        if( log )
        {
          this.log = log;

          this.getUser(log['uid']);
        }
        else
        {
          this.log = null;
          this.subscribe_HealthService.unsubscribe();
        }
      });
    }
  }

  ngOnDestroy()
  {
    if( this.subscribe_MeasurementService )
      this.subscribe_MeasurementService.unsubscribe();
    else this.subscribe_HealthService.unsubscribe();
  }

  getUser( uID )
  {
    if( uID != this.AuthService.getLoggedInUser().uid )
    {
      this.UserService.getUserByUID(uID).then( user => {
        this.user = user;
      });
    }
    else this.user = this.AuthService.getLoggedInUser();
  }

  getUserAvatarPath( avatar : string )
  {
    return `assets/animals_icons/${avatar}.png`; 
  }

  openEditModal( log_data )
  {
    if( this.type == "measurements" )
      this.EditMeasurementModal.openModal( log_data.type, log_data );
    else this.EditHealthModal.openModal( log_data.type, log_data );
  }

  getObjectKeys( object )
  {
    return Object.keys(object);
  }
}