import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument,  } from 'angularfire2/firestore';

@Injectable()
export class LinkService 
{
  constructor(private cloud: AngularFirestore)
  {}

  public createLinkBetweenUsers( uID : string, supervisor_uid : string, permissions, status : string )
  {
    /*
      Status:
        - accepted
        - rejected
        - pending
    */
    if( !permissions )
      permissions = {
        measurements: 
        {
          weight: true,
          tension: true,
          cholesterol: true,
          height: true,
          blood_glucose: true
        },

        health: 
        {
          future_appointments: true,
          disease: true,
          medical_procedures: true,
          medication: true,
          used_medication: true,
          immunizations: true,
          allergies: true,
          events: true,
          medical_devices: true,
          emotional_state: true,
          laboratory_results: true,
          family_history: true,
          insurance: true
        },

        write: true,
        
        read: true,

        edit: true
      };

    return new Promise((resolve, reject) => 
    {
      let link_data = 
      {
        id: this.generateRandomString(),
        uid: uID,
        supervisor_uid: supervisor_uid,
        permissions: permissions,
        status: status
      };

      const postRef : AngularFirestoreDocument = this.cloud.doc(`links/${link_data['id']}`);

      postRef.set(link_data).then(response => {
          resolve(link_data);
      })
      .catch(error => {
          console.log(error);
          reject (error);
      });
    });
  }

  public deleteLinkByID( link_id : string )
  {
    return new Promise(( resolve, reject) => 
    {
      this.cloud.doc(`links/${link_id}`).delete().then(function() {
        resolve();
      }).catch(function(error) {
        reject(error);
      });
    });
  }

  public updateLink( link )
  {
    return new Promise((resolve, reject) => {
      this.cloud.doc(`links/${link.id}`).update(link).then(function() {
        resolve();
      }).catch(function(error) {
        reject(error);
      });
    });
  }

  public existLinkBetweenUserAndSupervisor( uID : string, supervisor_id : string )
  {
    let ref = this.cloud.collection(`links`, ref => ref.where('supervisor_uid', '==', supervisor_id).where('uid', '==', uID)).valueChanges();

    return new Promise((resolve, reject) => {
      let subscribe = ref.subscribe(links => {
        if( links.length )
          resolve(true);
        else 
          resolve(false);
        
          subscribe.unsubscribe();
      });
    });
  }

  public getLinkBetweenUserAndSupervisor( uID : string, supervisor_id : string )
  {
    let ref = this.cloud.collection(`links`, ref => ref.where('supervisor_uid', '==', supervisor_id).where('uid', '==', uID)).valueChanges();

    return new Promise((resolve, reject) => {
      let subscribe = ref.subscribe(links => {
        resolve(links);
        subscribe.unsubscribe();
      });
    });
  }

  public getLinksByUID( uid : string )
  {
    return this.cloud.collection(`links`, ref => ref.where('uid', '==', uid)).valueChanges();
  }

  public getLinksBySupervisorID( uid : string )
  {
    return this.cloud.collection(`links`, ref => ref.where('supervisor_uid', '==', uid)).valueChanges();
  }

  private generateRandomString(): string 
  {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' + Date.now();

    for (let i = 0 ; i < 20; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}
