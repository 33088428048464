import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateAppService 
{
  constructor( private TranslateService : TranslateService ) {}

  setDefault( lang : string )
  {
    let localStorageLang = localStorage.getItem("appLanguage");

    if( localStorageLang )
      this.TranslateService.setDefaultLang(localStorageLang);
    else if( window.location.hostname == "omnevitae.com" )
      this.TranslateService.setDefaultLang("en");
    else 
      this.TranslateService.setDefaultLang(lang);
  }

  change( lang : string )
  {
    this.TranslateService.use(lang);

    localStorage.setItem("appLanguage", lang);
  }

  get()
  {
    let localStorageLang = localStorage.getItem("appLanguage");

    if( localStorageLang )
      return localStorageLang;
    return this.TranslateService.defaultLang;
  }

  getInstantStringByKey( key : string )
  {
    return this.TranslateService.instant(key);
  }
}
