import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-add-patient',
  templateUrl: './modal-add-patient.component.html',
  styleUrls: ['./modal-add-patient.component.css']
})
export class ModalAddPatientComponent implements OnInit
{
  private display = false;

  constructor( ) { }

  ngOnInit( )
  {
  }

  openModal()
  {
    this.display = true;

    document.body.classList.add("modal-open");
  }

  closeModal()
  {
    this.display = false;

    document.body.classList.remove("modal-open");
  }
}
