import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';

import { MeasurementService } from '../../../../services/measurement.service';

import { ModalHelpButtonComponent } from '../../../modal-help-button/modal-help-button.component';

import * as _ from 'lodash';

@Component({
  selector: 'app-height-form',
  templateUrl: './height-form.component.html',
  styleUrls: ['./height-form.component.css']
})
export class HeightFormComponent implements OnInit
{
  private maxDate;
  private date;

  @Input() uID : string;
  @Input() preloadedData;

  @Output() statusChanged = new EventEmitter();

  @ViewChild(ModalHelpButtonComponent) HelpModal: ModalHelpButtonComponent;

  constructor( private MeasurementService : MeasurementService ) { }

  ngOnInit( )
  {
    if( this.preloadedData )
    {
      this.date = new Date(this.preloadedData.form_data.date);
    }
    else
    {
      this.date = new Date();
    }
      
    this.maxDate = new Date();
  }

  getPreloadedData( key : string )
  {
    if( this.preloadedData && typeof(this.preloadedData.form_data[key]) != 'undefined' )
      return this.preloadedData.form_data[key];
    return null;
  }

  submitMeasurement( measurementForm : NgForm )
  {
    if( measurementForm.valid )
    {
      let height_data = measurementForm.value;
      height_data['date'] = this.date.getTime();

      if( !this.preloadedData )
      { 
        this.MeasurementService.saveMeasurement( "height", this.uID , height_data ).then( logID =>
        {
          measurementForm.resetForm();
          this.statusChanged.emit("submited");
        });
      }
      else
      {
        this.MeasurementService.updateMeasurement( this.preloadedData['id'], height_data ).then( () => {
          measurementForm.resetForm();
          this.statusChanged.emit("submited");
        });
      }
    }
  }

  openHelpModal ( helpText : string)
  {
    this.HelpModal.openHelpModal(helpText);
  }
}
