import { Component, OnInit, Input } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../services/auth.service';

import { AlertService } from '../../services/alert.service';

import * as health_json from '../../jsons/health.json';

@Component({
  selector: 'app-my-health',
  templateUrl: './my-health.component.html',
  styleUrls: ['./my-health.component.css']
})
export class MyHealthComponent implements OnInit {

  @Input() user;
  @Input() permissions;

  private health_categorys;
  private viewUser = false;

  constructor( private Router : Router, private route : ActivatedRoute, private AuthService : AuthService, private AlertService : AlertService )
  {
  }

  ngOnInit()
  {
    this.health_categorys = health_json.items;

    if( this.AuthService.getLoggedInUser().uid != this.user.uid )
      this.viewUser = true;
  }

  goTo( page : string )
  {
    if( this.AuthService.getLoggedInUser().uid != this.user.uid )
    {
      if( this.checkPermission(page) )
        this.Router.navigate([`health/${page}`], { relativeTo: this.route });
      else
        this.AlertService.show("Userul nu ti-a oferit acces la aceasta categorie.", "Ok");
    }
    else
      this.Router.navigate([`../health/${page}`], { relativeTo: this.route });
  }

  checkPermission( category : string )
  {
    if( this.permissions['health'][category] )
      return true;
    return false;
  }

  getHealthIcon( health_index : string )
  {
    if( this.checkPermission(health_index) )
      return `assets/homepage/${health_index}.png`;
    return "assets/homepage/locked.png";
  }
}
