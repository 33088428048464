import { Component, OnInit } from '@angular/core';

import { NgForm } from '@angular/forms';

import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit
{
  private loading = false;

  constructor( private AuthService : AuthService ) { }

  ngOnInit() 
  {}

  submitForgotPassword( forgotPasswordForm : NgForm )
  {
    if( forgotPasswordForm.valid )
    {
      this.loading = true;
      this.AuthService.sendPasswordResetEmail(forgotPasswordForm.value.email).then( () => {
        forgotPasswordForm.resetForm();
        this.loading = false;
      });
    }
  }
}
