import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument,  } from 'angularfire2/firestore';

@Injectable()
export class NotificationService 
{

  constructor(private cloud: AngularFirestore)
  {}

  public sendRequestNotification ( uID : string, from, data )
  {
      /*
        Category:
            - request
      */

    return new Promise((resolve, reject) => {

        let notification_data = {
            id: this.generateRandomString(),
            uid: uID,
            type: "request",
            notification_from: `${from.firstname} ${from.secondname}`,
            from: from.uid,
            data: data,
            status: "unread",
            date: Date.now()
        };
  
        const postRef : AngularFirestoreDocument = this.cloud.doc(`notifications/${notification_data['id']}`);
  
        postRef.set(notification_data).then(response => {
            resolve(notification_data['id']);
        })
        .catch(error => {
            console.log(error);
            reject(error);
        });
    });
  };

  public getNotificationsByUID( uID : string )
  {
    return this.cloud.collection(`notifications`, ref => ref.where('uid', '==', uID).orderBy('date', 'desc')).valueChanges();
  }

  public readNotificationByID( id : string )
  {
    return new Promise((resolve, reject) => {
        this.cloud.doc(`notifications/${id}`).update({ status: "read" }).then(function() {
            resolve();
        }).catch(function(error) {
            reject(error);
        });
    });
  }

  private generateRandomString(): string 
  {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' + Date.now();

    for (let i = 0 ; i < 20; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}
