import { Component, OnInit, OnDestroy, Input, ViewChild } from '@angular/core';

import { AuthService } from '../../../../services/auth.service';

import { HealthService } from '../../../../services/health.service';

import { ModalEditingHealthComponent } from '../../../modal-editing-health/modal-editing-health.component';

import { CategoryHeaderComponent } from '../../../category-header/category-header.component';

@Component({
  selector: 'app-my-health-page-medication',
  templateUrl: './medication.component.html',
  styleUrls: ['./medication.component.css']
})
export class MyHealthPageMedicationComponent implements OnInit, OnDestroy
{
  @Input() uID : string;

  @ViewChild( ModalEditingHealthComponent ) EditModal : ModalEditingHealthComponent;
  @ViewChild(CategoryHeaderComponent) CategoryHeader : CategoryHeaderComponent;

  private screen = 'measurement';
  private userID;
  private logs;

  private selectedLog;
  private refreshViewLog = false;

  private subscribe_Service;

  constructor( private AuthService : AuthService, private HealthService : HealthService )
  {
  }

  ngOnInit()
  {
    if( this.uID == this.AuthService.getLoggedInUser().uid || this.uID == null )
      this.userID = this.AuthService.getLoggedInUser().uid;      
    else 
      this.userID = this.uID;

    this.subscribe_Service = this.HealthService.getLogsByUID( "medication", this.userID ).subscribe( logs => {
      this.logs = logs;
    });
  }

  ngOnDestroy()
  {
    this.subscribe_Service.unsubscribe();
  }

  openEditLogModal( log )
  {
    this.EditModal.openModal(log.type, log);
  }

  changeScreen( screen : string )
  {
    this.screen = screen;
  }

  onFormSubmited()
  {
    this.CategoryHeader.changeScreen("history");
  }

  selectLog( log )
  {
    this.refreshViewLog = true;
    this.selectedLog = log;

    setTimeout( () =>
    {
      this.refreshViewLog = false;
    }, 100);
  }
}