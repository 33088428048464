import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';

import { ModalHelpButtonComponent } from '../../../modal-help-button/modal-help-button.component';


import { HealthService } from '../../../../services/health.service';

@Component({
  selector: 'app-my-health-form-used-medication',
  templateUrl: './used_medication.component.html',
  styleUrls: ['./used_medication.component.css']
})
export class MyHealthFormUsedMedicationComponent implements OnInit
{
  private maxDate;
  private date;

  @Input() uID : string;
  @Input() preloadedData;

  @Output() statusChanged = new EventEmitter();

  @ViewChild(ModalHelpButtonComponent) HelpModal: ModalHelpButtonComponent;


  constructor( private HealthService : HealthService ) { }

  ngOnInit( )
  {
    window.scroll(0, 0);
    
    if( this.preloadedData )
    {
      this.date = new Date(this.preloadedData.form_data.date);
    }
    else
    {
      this.date = new Date();
    }
      
    this.maxDate = new Date();
  }

  getPreloadedData( key : string )
  {
    if( this.preloadedData && typeof(this.preloadedData.form_data[key]) != 'undefined' )
      return this.preloadedData.form_data[key];
    return null;
  }

  submit( Form : NgForm )
  {
    if( Form.valid )
    {
      let form_data = Form.value;
      form_data['date'] = this.date.getTime();

      if( !this.preloadedData )
      { 
        this.HealthService.saveLog( "used_medication", this.uID , form_data ).then( logID =>
        {
          Form.resetForm();
          this.statusChanged.emit("submited");
        });
      }
      else
      {
        this.HealthService.updateLog( this.preloadedData['id'], form_data ).then( () => {
          Form.resetForm();
          this.statusChanged.emit("submited");
        });
      }
    }
  }

  openHelpModal ( helpText : string)
  {
    this.HelpModal.openHelpModal(helpText);
  }
}