import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { InvitationComponent } from './pages/invitation/invitation.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';

import { AuthGuardService } from './shared/services/auth-guard.service';

const appRoutes: Routes = [
  { path: '', redirectTo: 'index', pathMatch: 'full' },
  { path: 'index', component: LandingPageComponent},
  { path: 'login', component: LoginComponent, canActivate: [AuthGuardService]},
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuardService]},
  { path: 'invitation/:id', component: InvitationComponent, canActivate: [AuthGuardService]},
  { path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [AuthGuardService]},
  { path: 'terms-and-conditions', component: TermsAndConditionsComponent},
  { path: 'user', loadChildren: './pages/users/user/modules/user.module#UserModule', canActivate: [AuthGuardService]},
  { path: 'medic', loadChildren: './pages/users/medic/modules/medic.module#MedicModule', canActivate: [AuthGuardService]},
  { path: 'carer', loadChildren: './pages/users/carer/modules/carer.module#CarerModule', canActivate: [AuthGuardService]},
  { path: '**', redirectTo: 'login'}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [AuthGuardService]
})
export class AppRoutingModule { }

