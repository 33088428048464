import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument  } from 'angularfire2/firestore';

@Injectable()
export class MoodService 
{

  constructor(private cloud: AngularFirestore)
  {}

  public saveMood = ( uID : string, mood : string ) => {
    return new Promise((resolve, reject) => {

        let mood_data = {
            id: this.generateRandomString(),
            uid: uID,
            mood: mood,
            date: Date.now()
        };
  
        const postRef : AngularFirestoreDocument = this.cloud.doc(`mood_logs/${mood_data['id']}`);
  
        postRef.set(mood_data).then(response => {
            resolve(mood_data['id']);
        })
        .catch(error => {
            console.log(error);
            reject (error);
        });
    });
  };

  public getMoodsByUID( uID : string )
  {
    return new Promise((resolve, reject) => {
        let subscribe = this.cloud.collection(`mood_logs`, ref => ref.where('uid', '==', uID)).valueChanges().subscribe( moods => {
            resolve(moods);
            subscribe.unsubscribe();
        });
    });
  }

  private generateRandomString(): string 
  {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' + Date.now();

    for (let i = 0 ; i < 20; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}
