import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { AngularFireAuthModule } from 'angularfire2/auth';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { SharedModule } from './shared/modules/shared.module';
import { AppRoutingModule } from './app-routing.module';

import { MainComponent } from './main/app.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { InvitationComponent } from './pages/invitation/invitation.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';

import { AuthService } from './shared/services/auth.service';
import { UserService } from './shared/services/user.service';
import { MeasurementService } from './shared/services/measurement.service';
import { MoodService } from './shared/services/mood.service';
import { NotificationService } from './shared/services/notification.service';
import { LinkService } from './shared/services/link.service';
import { HealthService } from './shared/services/health.service';
import { StorageService } from './shared/services/storage.service';
import { InvitationService } from './shared/services/invitation.service';
import { CommentsService } from './shared/services/comments.service';
import { AlertService } from './shared/services/alert.service';
import { SpecialCodeService } from './shared/services/special_code.service';
import { TranslateAppService } from './shared/services/translate.service';

export function HttpLoaderFactory(http: HttpClient)
{
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: 
  [
    MainComponent,
    LandingPageComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    InvitationComponent,
    TermsAndConditionsComponent
  ],

  imports: 
  [
    BrowserModule, 
    HttpClientModule,
    TranslateModule.forRoot({
      loader: 
      {
        provide: TranslateLoader,
        useFactory: (HttpLoaderFactory),
        deps: [HttpClient]
      }
    }),
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    BrowserAnimationsModule,
    AppRoutingModule
  ],

  providers: 
  [
    AuthService,
    UserService,
    MeasurementService,
    MoodService,
    NotificationService,
    LinkService,
    HealthService,
    StorageService,
    InvitationService,
    CommentsService,
    AlertService,
    SpecialCodeService,
    TranslateAppService
  ],
  
  bootstrap: [MainComponent]
})
export class AppModule { }