import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { NgForm } from '@angular/forms';

import { AuthService } from '../../services/auth.service';

import { LinkService } from '../../services/link.service';

import * as avatars from '../../jsons/avatars.json';

import * as health_categorys from '../../jsons/health.json';

import * as measurements from '../../jsons/measurements.json';

@Component({
  selector: 'app-user-without-email-form',
  templateUrl: './user-without-email-form.component.html',
  styleUrls: ['./user-without-email-form.component.css']
})
export class UserWithoutEmailFormComponent implements OnInit 
{
  @Output() statusChanged = new EventEmitter();

  private selectedAvatar;
  private showAvatars = false;
  private birthday = new Date();

  private list_avatars = [
  ];

  private permissions = {
    measurements: 
    {
    },

    health: 
    {
    },

    write: true,

    edit: true
  }

  constructor( private AuthService : AuthService, private LinkService : LinkService )
  {

  }

  ngOnInit()
  {
    this.list_avatars = avatars.animals;
  }

  submit( patientForm : NgForm )
  {
    if( patientForm.valid )
    {
      let userData = patientForm.value;

      userData['birthday'] = this.birthday;

      if( this.selectedAvatar )
        userData['avatar'] = this.selectedAvatar;
      else userData['avatar'] = "cat";

      if( userData['birthday'] != "undefined" )
        userData['birthday'] = new Date(userData['birthday']).getTime();

      this.AuthService.createUserWithoutEmailAndPassword( userData ).then( user => {
        let supervisor = this.AuthService.getLoggedInUser();
        this.initPermisions();
        
        this.LinkService.createLinkBetweenUsers(user['uid'], supervisor.uid, this.permissions, "accepted").then( () => {
          patientForm.resetForm();
          this.selectedAvatar = null;
          this.statusChanged.emit("submited");
        });
      });
    }
  }

  initPermisions()
  {
    health_categorys.items.forEach( item => {
      this.permissions['health'][item.index] = true;
    });

    measurements.items.forEach( item => {
      this.permissions['measurements'][item.index] = true;
    });
  }

  onClickShowAvatars()
  {
    if( this.showAvatars )
      this.showAvatars = false;
    else this.showAvatars = true;
  }

  onSelectAvatar( animal_name : string )
  {
    this.selectedAvatar = animal_name;
  }

  getAvatarPath( animal_name : string )
  {
    return `assets/animals_icons/${animal_name}.png`;
  }
}