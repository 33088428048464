import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-modal-help-button',
    templateUrl: './modal-help-button.component.html',
    styleUrls: ['./modal-help-button.component.css']
  })


export class ModalHelpButtonComponent implements OnInit
{
  private display = false;
  private translateHelpIndex;

  constructor()
  { 

  }

  ngOnInit()
  {
  }

  openHelpModal( translateHelpIndex : string)
  {
    this.translateHelpIndex = translateHelpIndex;

    this.display = true;

    document.body.classList.add("modal-open");
  }

  closeModal( index : number )
  {
      this.display = false;

      document.body.classList.remove("modal-open");
  }
}