import { Injectable } from '@angular/core';

import * as firebase from 'firebase/app';

@Injectable()
export class StorageService 
{
    constructor( )
    {}

    uploadFile( uID : string, file : File ) 
    {
        const fileName = Date.now() + file.name.substr(0, 32);
        const fileRef = firebase.storage().ref().child(`${uID}/${fileName}`);
        const uploadTask = fileRef.put(file);

        return new Promise((resolve, reject) => 
        {
            uploadTask.on('state_changed', snapshot => 
            {

            }, error => {
                reject(error);
            }, () => {
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    resolve({fileName, downloadURL});
                  });
            }); 
        });
    }
}
