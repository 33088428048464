import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument,  } from 'angularfire2/firestore';

@Injectable()
export class MeasurementService 
{
  constructor(private cloud: AngularFirestore)
  {}

  public getAllMeasurementsByUID( uid : string )
  {
    return this.cloud.collection(`measurements`, ref => ref.where('uid', '==', uid)).valueChanges();
  }

  public getMeasurementByID( id : string )
  {
    return this.cloud.doc(`measurements/${id}`).valueChanges();
  }

  public getMeasurementsByUID( measurement_type : string, uid : string )
  {
    return this.cloud.collection(`measurements`, ref => ref.where('uid', '==', uid).where('type', '==', measurement_type)).valueChanges();
  }

  public getAllMeasurementsByType( measurement_type : string )
  {
    return this.cloud.collection(`measurements`, ref => ref.where('type', '==', measurement_type)).valueChanges();
  }

  public saveMeasurement = ( measurement_type : string, userID : string, measurement_data ) => {
    return new Promise((resolve, reject) => {
        let log_data = {};

        log_data['id'] = this.generateRandomString();
        log_data['uid'] = userID;
        log_data['category'] = "measurements";
        log_data['type'] = measurement_type;

        log_data['createdAt'] = Date.now();
        log_data['form_data'] = measurement_data;
  
        const postRef : AngularFirestoreDocument = this.cloud.doc(`measurements/${log_data['id']}`);
  
        postRef.set(log_data).then(response => {
            resolve(log_data['id']);
        })
        .catch(error => {
            console.log(error);
            reject (error);
        });
    });
  };

  public updateMeasurement( ID : string, measurement_data )
  {
    return new Promise((resolve, reject) => {
      
      let log_data = { form_data: measurement_data };

      const postRef : AngularFirestoreDocument = this.cloud.doc(`measurements/${ID}`);

      postRef.update(log_data).then(response => {
          resolve();
      })
      .catch(error => {
          console.log(error);
          reject (error);
      });
    });
  }

  public deleteMeasurementByID ( measurementID : string )
  {
    return new Promise(( resolve, reject) => 
    {
      this.cloud.doc(`measurements/${measurementID}`).delete().then(function() {
        resolve();
      }).catch(function(error) {
        reject(error);
      });
    });
  }

  private generateRandomString(): string 
  {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' + Date.now();

    for (let i = 0 ; i < 20; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
