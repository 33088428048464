export var symptoms = 
[
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "burning",
            "title": "Burning"
          },
          {
            "index": "crampy",
            "title": "Crampy"
          },
          {
            "index": "dull",
            "title": "Dull"
          },
          {
            "index": "gnawing",
            "title": "Gnawing"
          },
          {
            "index": "intense",
            "title": "Intense"
          },
          {
            "index": "intermittent_episodic",
            "title": "Intermittent or episodic"
          },
          {
            "index": "ongoing_chronic",
            "title": "Ongoing (chronic)"
          },
          {
            "index": "sharp",
            "title": "Sharp"
          },
          {
            "index": "steady",
            "title": "Steady"
          },
          {
            "index": "sudden_acute",
            "title": "Sudden (acute)"
          },
          {
            "index": "worsening_progressing",
            "title": "Worsening or progressing"
          }
        ],
        "index": "pain_is"
      },
      {
        "items": [
          {
            "index": "abdomen_but_radiates_other_parts",
            "title": "Abdomen but radiates to other parts of the body"
          },
          {
            "index": "lower_abdomen",
            "title": "Lower abdomen"
          },
          {
            "index": "middle_abdomen",
            "title": "Middle abdomen"
          },
          {
            "index": "one_both_sides",
            "title": "One or both sides"
          },
          {
            "index": "upper_abdomen",
            "title": "Upper abdomen"
          }
        ],
        "index": "pain_located_in"
      },
      {
        "items": [
          {
            "index": "coughing_other_jarring_movements",
            "title": "Coughing or other jarring movements"
          },
          {
            "index": "drinking_alcohol",
            "title": "Drinking alcohol"
          },
          {
            "index": "eating_certain_foods",
            "title": "Eating certain foods"
          },
          {
            "index": "menstrual_cycle",
            "title": "Menstrual cycle"
          },
          {
            "index": "stress",
            "title": "Stress"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "antacids",
            "title": "Antacids"
          },
          {
            "index": "avoiding_certain_foods",
            "title": "Avoiding certain foods"
          },
          {
            "index": "changing_position",
            "title": "Changing position"
          },
          {
            "index": "drinking_more_water",
            "title": "Drinking more water"
          },
          {
            "index": "eating_certain_foods",
            "title": "Eating certain foods"
          },
          {
            "index": "eating_more_fiber",
            "title": "Eating more fiber"
          }
        ],
        "index": "relieved_by"
      },
      {
        "items": [
          {
            "index": "abdominal_swelling",
            "title": "Abdominal swelling"
          },
          {
            "index": "black_bloody_stools",
            "title": "Black or bloody stools"
          },
          {
            "index": "constipation",
            "title": "Constipation"
          },
          {
            "index": "diarrhea",
            "title": "Diarrhea"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "inability_move_bowels",
            "title": "Inability to move bowels in spite of urge"
          },
          {
            "index": "loose_watery_stools",
            "title": "Loose, watery stools"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "passing_gas",
            "title": "Passing gas"
          },
          {
            "index": "pulsing_sensation_near_navel",
            "title": "Pulsing sensation near the navel"
          },
          {
            "index": "rash",
            "title": "Rash"
          },
          {
            "index": "stomach_growling_rumbling",
            "title": "Stomach growling or rumbling"
          },
          {
            "index": "unintended_weight_loss",
            "title": "Unintended weight loss"
          },
          {
            "index": "urgent_need_bowel_movement",
            "title": "Urgent need to have a bowel movement"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "abdominal_pain"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "in_the_stool",
            "title": "In or on the stool"
          },
          {
            "index": "in_toilet_bowl_toilet_tissue",
            "title": "In toilet bowl or on toilet tissue"
          }
        ],
        "index": "blood_appears"
      },
      {
        "items": [
          {
            "index": "drinking_alcohol_caffeine",
            "title": "Drinking alcohol or caffeine"
          },
          {
            "index": "eating_certain_foods",
            "title": "Eating certain foods"
          },
          {
            "index": "straining_during_bowel_movements",
            "title": "Straining during bowel movements"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "drinking_more_water",
            "title": "Drinking more water"
          },
          {
            "index": "eating_certain_foods",
            "title": "Eating certain foods"
          },
          {
            "index": "eating_more_fiber",
            "title": "Eating more fiber"
          }
        ],
        "index": "relieved_by"
      },
      {
        "items": [
          {
            "index": "abdominal_pain_cramping",
            "title": "Abdominal pain or cramping"
          },
          {
            "index": "anal_itching",
            "title": "Anal itching"
          },
          {
            "index": "change_bowel_habits_that_lasts",
            "title": "Change in bowel habits that lasts longer than four weeks"
          },
          {
            "index": "change_your_bowel_habits",
            "title": "Change in your bowel habits"
          },
          {
            "index": "constipation",
            "title": "Constipation"
          },
          {
            "index": "diarrhea",
            "title": "Diarrhea"
          },
          {
            "index": "fatigue_weakness",
            "title": "Fatigue or weakness"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "frequent_urge_have_bowel_movement",
            "title": "Frequent urge to have bowel movement"
          },
          {
            "index": "narrow_stools",
            "title": "Narrow stools"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "painful_bowel_movements",
            "title": "Painful bowel movements"
          },
          {
            "index": "persistent_abdominal_discomfort",
            "title": "Persistent abdominal discomfort, such as cramps, gas or pain"
          },
          {
            "index": "rectal_pain",
            "title": "Rectal pain"
          },
          {
            "index": "unintended_weight_loss",
            "title": "Unintended weight loss"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "blood_in_stool"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "achy_gnawing",
            "title": "Achy or gnawing"
          },
          {
            "index": "burning",
            "title": "Burning"
          },
          {
            "index": "severe",
            "title": "Severe"
          },
          {
            "index": "sharp",
            "title": "Sharp"
          },
          {
            "index": "squeezing_pressure",
            "title": "Squeezing or pressure"
          },
          {
            "index": "sudden",
            "title": "Sudden"
          },
          {
            "index": "tight",
            "title": "Tight"
          }
        ],
        "index": "pain_best_described_as"
      },
      {
        "items": [
          {
            "index": "ongoing_recurrent",
            "title": "Ongoing or recurrent"
          },
          {
            "index": "upper_respiratory_illness",
            "title": "Preceded by upper respiratory illness"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "allergens_irritants",
            "title": "Allergens or irritants"
          },
          {
            "index": "eating_drinking",
            "title": "Eating or drinking"
          },
          {
            "index": "exertion",
            "title": "Exertion"
          },
          {
            "index": "exposure_cold_air",
            "title": "Exposure to cold air"
          },
          {
            "index": "lying_down_long_period",
            "title": "Lying down for a long period"
          },
          {
            "index": "mild_moderate_exertion",
            "title": "Mild to moderate exertion"
          },
          {
            "index": "pressing_chest_wall",
            "title": "Pressing on chest wall"
          },
          {
            "index": "smoking",
            "title": "Smoking"
          },
          {
            "index": "stress",
            "title": "Stress"
          },
          {
            "index": "taking_deep_breath",
            "title": "Taking a deep breath"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "antacids",
            "title": "Antacids"
          },
          {
            "index": "bending_forward",
            "title": "Bending forward"
          },
          {
            "index": "rest",
            "title": "Rest"
          }
        ],
        "index": "relieved_by"
      },
      {
        "items": [
          {
            "index": "anxiety",
            "title": "Anxiety"
          },
          {
            "index": "belching",
            "title": "Belching"
          },
          {
            "index": "cough_blood_phlegm",
            "title": "Cough with blood or phlegm"
          },
          {
            "index": "difficult_painful_swallowing",
            "title": "Difficult or painful swallowing"
          },
          {
            "index": "dry_cough",
            "title": "Dry cough"
          },
          {
            "index": "fainting_dizziness",
            "title": "Fainting or dizziness"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "headache",
            "title": "Headache"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "pain_neck_jaw_arms_shoulders",
            "title": "Pain in neck, jaw, arms, shoulders or back"
          },
          {
            "index": "rapid_irregular_heartbeat",
            "title": "Rapid or irregular heartbeat"
          },
          {
            "index": "shortness_breath",
            "title": "Shortness of breath"
          },
          {
            "index": "sweating",
            "title": "Sweating"
          },
          {
            "index": "unexplained_fatigue",
            "title": "Unexplained fatigue"
          },
          {
            "index": "wheezing",
            "title": "Wheezing"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "chest_pain"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "in_the_stool",
            "title": "In or on the stool"
          }
        ],
        "index": "blood_appears"
      },
      {
        "items": [
          {
            "index": "ongoing_recurrent",
            "title": "Ongoing or recurrent"
          },
          {
            "index": "recent",
            "title": "Recent"
          },
          {
            "index": "worsening_progressing",
            "title": "Worsening or progressing"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "abdominal_pain",
            "title": "Abdominal pain"
          },
          {
            "index": "anal_rectal_pain",
            "title": "Anal or rectal pain"
          },
          {
            "index": "bloody_stools",
            "title": "Bloody stools"
          },
          {
            "index": "change_bowel_habits_lasts",
            "title": "Change in bowel habits that lasts longer than four weeks"
          },
          {
            "index": "constipation",
            "title": "Constipation"
          },
          {
            "index": "cramping",
            "title": "Cramping"
          },
          {
            "index": "diarrhea",
            "title": "Diarrhea"
          },
          {
            "index": "fatigue_weakness",
            "title": "Fatigue or weakness"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "gas",
            "title": "Gas"
          },
          {
            "index": "increased_sensitivity_cold",
            "title": "Increased sensitivity to cold"
          },
          {
            "index": "mucus_stools",
            "title": "Mucus in stools"
          },
          {
            "index": "muscle_joint_aches",
            "title": "Muscle or joint aches"
          },
          {
            "index": "muscle_weakness",
            "title": "Muscle weakness"
          },
          {
            "index": "narrow_stools",
            "title": "Narrow stools"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "painful_bowel_movements",
            "title": "Painful bowel movements"
          },
          {
            "index": "pale_dry_skin",
            "title": "Pale, dry skin"
          },
          {
            "index": "persistent_abdominal_discomfort",
            "title": "Persistent abdominal discomfort, such as cramps, gas or pain"
          },
          {
            "index": "unexplained_fatigue",
            "title": "Unexplained fatigue"
          },
          {
            "index": "unintended_weight_gain",
            "title": "Unintended weight gain"
          },
          {
            "index": "unintended_weight_loss",
            "title": "Unintended weight loss"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "constipation"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "dry",
            "title": "Dry"
          },
          {
            "index": "producing_phlegm_sputum",
            "title": "Producing phlegm or sputum"
          }
        ],
        "index": "cough_is"
      },
      {
        "items": [
          {
            "index": "new_recent",
            "title": "New or recent"
          },
          {
            "index": "ongoing_recurrent",
            "title": "Ongoing or recurrent"
          },
          {
            "index": "worsening_progressing",
            "title": "Worsening or progressing"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "allergens_irritants",
            "title": "Allergens or irritants"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "chest_pain_tightness",
            "title": "Chest pain or tightness"
          },
          {
            "index": "chills_sweating",
            "title": "Chills or sweating"
          },
          {
            "index": "difficult_painful_swallowing",
            "title": "Difficult or painful swallowing"
          },
          {
            "index": "fatigue",
            "title": "Fatigue"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "headache_facial_pain",
            "title": "Headache or facial pain"
          },
          {
            "index": "hoarse_voice",
            "title": "Hoarse voice"
          },
          {
            "index": "loss_appetite",
            "title": "Loss of appetite"
          },
          {
            "index": "muscle_aches",
            "title": "Muscle aches"
          },
          {
            "index": "runny_stuffy_nose",
            "title": "Runny or stuffy nose"
          },
          {
            "index": "shortness_breath",
            "title": "Shortness of breath"
          },
          {
            "index": "sneezing",
            "title": "Sneezing"
          },
          {
            "index": "sore_throat",
            "title": "Sore throat"
          },
          {
            "index": "watery_itchy_eyes",
            "title": "Watery or itchy eyes"
          },
          {
            "index": "wheezing",
            "title": "Wheezing"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "cough"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "ongoing_recurrent_weeks_years",
            "title": "Ongoing or recurrent (weeks to years)"
          },
          {
            "index": "recent_days_weeks",
            "title": "Recent (days to weeks)"
          },
          {
            "index": "sudden_hours_days",
            "title": "Sudden (hours to days)"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "eating_suspect_food",
            "title": "Eating suspect food"
          },
          {
            "index": "recent_antibiotic_use",
            "title": "Recent antibiotic use"
          }
        ],
        "index": "preceded_by"
      },
      {
        "items": [
          {
            "index": "eating_certain_foods",
            "title": "Eating certain foods"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "avoiding_certain_foods",
            "title": "Avoiding certain foods"
          }
        ],
        "index": "relieved_by"
      },
      {
        "items": [
          {
            "index": "abdominal_pain_cramping",
            "title": "Abdominal pain or cramping"
          },
          {
            "index": "bloating_abdominal_swelling",
            "title": "Bloating or abdominal swelling"
          },
          {
            "index": "bloody_stools",
            "title": "Bloody stools"
          },
          {
            "index": "constipation",
            "title": "Constipation"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "mucus_stools",
            "title": "Mucus in stools"
          },
          {
            "index": "muscle_joint_aches",
            "title": "Muscle or joint aches"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "passing_gas",
            "title": "Passing gas"
          },
          {
            "index": "unintended_weight_loss",
            "title": "Unintended weight loss"
          },
          {
            "index": "urgency_have_bowel_movement",
            "title": "Urgency to have a bowel movement"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "diarrhea"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "hurts",
            "title": "Hurts"
          },
          {
            "index": "takes_effort",
            "title": "Takes effort"
          }
        ],
        "index": "swallowing"
      },
      {
        "items": [
          {
            "index": "eating_certain_foods",
            "title": "Eating certain foods"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "bad_breath",
            "title": "Bad breath"
          },
          {
            "index": "cough",
            "title": "Cough"
          },
          {
            "index": "difficulty_breathing",
            "title": "Difficulty breathing"
          },
          {
            "index": "dry_mouth",
            "title": "Dry mouth"
          },
          {
            "index": "earache",
            "title": "Earache"
          },
          {
            "index": "feeling_something",
            "title": "Feeling of something stuck in your throat"
          },
          {
            "index": "heartburn",
            "title": "Heartburn"
          },
          {
            "index": "hoarse_voice_difficulty_speaking",
            "title": "Hoarse voice or difficulty speaking"
          },
          {
            "index": "jaw_pain_stiffness",
            "title": "Jaw pain or stiffness"
          },
          {
            "index": "loose_teeth_poorly_fitting_dentures",
            "title": "Loose teeth or poorly fitting dentures"
          },
          {
            "index": "lump_front_neck",
            "title": "Lump in front of neck"
          },
          {
            "index": "mouth_sores_lumps_pain",
            "title": "Mouth sores, lumps or pain"
          },
          {
            "index": "pain_chest_back",
            "title": "Pain in chest or back"
          },
          {
            "index": "regurgitation_food_sour_liquid",
            "title": "Regurgitation of food or sour liquid"
          },
          {
            "index": "sore_throat",
            "title": "Sore throat"
          },
          {
            "index": "thick_saliva",
            "title": "Thick saliva"
          },
          {
            "index": "unintended_weight_loss",
            "title": "Unintended weight loss"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "difficulty_swallowing"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "a_spinning_sensation",
            "title": "A spinning sensation"
          },
          {
            "index": "lightheaded_faint",
            "title": "Lightheaded or faint"
          },
          {
            "index": "unsteady",
            "title": "Unsteady"
          }
        ],
        "index": "you_feel"
      },
      {
        "items": [
          {
            "index": "new_sudden",
            "title": "New or sudden"
          },
          {
            "index": "recurrent_ongoing",
            "title": "Recurrent or ongoing"
          }
        ],
        "index": "symptoms_are"
      },
      {
        "items": [
          {
            "index": "change_head_body_position",
            "title": "Change in head or body position"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "anxiety",
            "title": "Anxiety"
          },
          {
            "index": "blurred_double_vision",
            "title": "Blurred or double vision"
          },
          {
            "index": "buzzing_ringing_ear",
            "title": "Buzzing or ringing in ear"
          },
          {
            "index": "buzzing_ringing_tinnitus",
            "title": "Buzzing or ringing (tinnitus) in only one ear"
          },
          {
            "index": "chest_pain_tightness",
            "title": "Chest pain or tightness"
          },
          {
            "index": "confusion",
            "title": "Confusion"
          },
          {
            "index": "ear_pain_mpressure",
            "title": "Ear pain or pressure"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "hearing_loss",
            "title": "Hearing loss"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "numbness_weakness_one_side_body",
            "title": "Numbness or weakness on one side of body"
          },
          {
            "index": "pain_neck_jaw_arms_shoulders",
            "title": "Pain in neck, jaw, arms, shoulders or back"
          },
          {
            "index": "rapid_irregular_heartbeat",
            "title": "Rapid or irregular heartbeat"
          },
          {
            "index": "severe_headache",
            "title": "Severe headache"
          },
          {
            "index": "shortness_breath",
            "title": "Shortness of breath"
          },
          {
            "index": "slurred_speech",
            "title": "Slurred speech"
          },
          {
            "index": "sweating",
            "title": "Sweating"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "dizziness"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "achy",
            "title": "Achy"
          },
          {
            "index": "dry_itchy",
            "title": "Dry or itchy"
          },
          {
            "index": "gritty_sensation",
            "title": "Gritty sensation"
          },
          {
            "index": "redness_without_actual_discomfort",
            "title": "Redness without actual discomfort"
          },
          {
            "index": "sensitivity_light",
            "title": "Sensitivity to light"
          },
          {
            "index": "severe_pain",
            "title": "Severe pain"
          },
          {
            "index": "stinging_burning_sensation",
            "title": "Stinging or burning sensation"
          }
        ],
        "index": "eye_discomfort_best_described_as"
      },
      {
        "items": [
          {
            "index": "bleeding_the_surface",
            "title": "Bleeding on the surface of the white of the eye"
          },
          {
            "index": "crusted_eyelashes_after_sleeping",
            "title": "Crusted eyelashes after sleeping"
          },
          {
            "index": "excessive_tearing",
            "title": "Excessive tearing"
          },
          {
            "index": "red_painful_lump_the_eyelid",
            "title": "Red, painful lump on the eyelid"
          },
          {
            "index": "redness",
            "title": "Redness"
          },
          {
            "index": "stringy_mucus_around",
            "title": "Stringy mucus in or around the eye"
          },
          {
            "index": "swelling_around_the_eye",
            "title": "Swelling around the eye"
          }
        ],
        "index": "appearance_of_eye_includes"
      },
      {
        "items": [
          {
            "index": "blurred_vision",
            "title": "Blurred vision"
          },
          {
            "index": "dark_floating_spots_vision",
            "title": "Dark, floating spots in vision"
          },
          {
            "index": "vision_loss",
            "title": "Vision loss"
          }
        ],
        "index": "vision_problem_includes"
      },
      {
        "items": [
          {
            "index": "allergens_other_irritants",
            "title": "Allergens or other irritants"
          },
          {
            "index": "injury_trauma",
            "title": "Injury or trauma"
          }
        ],
        "index": "triggered_by"
      },
      {
        "items": [
          {
            "index": "dry_warm_air",
            "title": "Dry, warm air"
          },
          {
            "index": "eye_movement",
            "title": "Eye movement"
          }
        ],
        "index": "worsened_by"
      },
      {
        "items": [
          {
            "index": "runny_stuffy_nose",
            "title": "Runny or stuffy nose"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "eye_discomfort_and_redness"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "blurry_distant_objects",
            "title": "Blurry distant objects"
          },
          {
            "index": "blurry_nearby_objects",
            "title": "Blurry nearby objects"
          },
          {
            "index": "blurry_blind_spot",
            "title": "Blurry or blind spot in center of vision"
          },
          {
            "index": "blurry_vision_at_all_distances",
            "title": "Blurry vision at all distances"
          },
          {
            "index": "bright_zigzag_lines",
            "title": "Bright zigzag lines"
          },
          {
            "index": "clouded_hazy_dim_vision",
            "title": "Clouded, hazy or dim vision"
          },
          {
            "index": "double_vision",
            "title": "Double vision"
          },
          {
            "index": "fading_colors",
            "title": "Fading of colors"
          },
          {
            "index": "flashes_light",
            "title": "Flashes of light"
          },
          {
            "index": "glare_with_bright_lights",
            "title": "Glare with bright lights"
          },
          {
            "index": "halos_around_lights",
            "title": "Halos around lights"
          },
          {
            "index": "inability_distinguish",
            "title": "Inability to distinguish certain shades of color"
          },
          {
            "index": "loss_side_vision",
            "title": "Loss of side vision"
          },
          {
            "index": "objects_appear_crooked",
            "title": "Objects appear crooked or distorted"
          },
          {
            "index": "poor_night_vision",
            "title": "Poor night vision"
          },
          {
            "index": "progressive_expansion",
            "title": "Progressive expansion of shadow or curtain over visual field"
          },
          {
            "index": "seeing_nonexistent_things",
            "title": "Seeing nonexistent things, or hallucinating"
          },
          {
            "index": "sensitivity_light",
            "title": "Sensitivity to light"
          },
          {
            "index": "shimmering_spots_stars",
            "title": "Shimmering spots or stars"
          },
          {
            "index": "spots_strings_floating",
            "title": "Spots or strings floating in field of vision"
          },
          {
            "index": "tunnel_vision",
            "title": "Tunnel vision"
          },
          {
            "index": "vision_loss_partial_total",
            "title": "Vision loss, partial or total"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "both_eyes",
            "title": "Both eyes"
          },
          {
            "index": "one_eye",
            "title": "One eye"
          }
        ],
        "index": "problem_affects"
      },
      {
        "items": [
          {
            "index": "holding_objects_away_from_face",
            "title": "Holding objects away from face"
          },
          {
            "index": "holding_objects_close_face",
            "title": "Holding objects close to face"
          },
          {
            "index": "squinting",
            "title": "Squinting"
          },
          {
            "index": "use_bright_lighting",
            "title": "Use of bright lighting"
          }
        ],
        "index": "vision_improves_somewhat_with"
      },
      {
        "items": [
          {
            "index": "gradual_progressive",
            "title": "Gradual or progressive"
          },
          {
            "index": "recent_hours_days",
            "title": "Recent (hours to days)"
          },
          {
            "index": "sudden_seconds_minutes",
            "title": "Sudden (seconds to minutes)"
          }
        ],
        "index": "onset_is"
      },
      {
        "items": [
          {
            "index": "a_few_minutes",
            "title": "A few minutes"
          },
          {
            "index": "usually_no_longer_30_minutes",
            "title": "Usually no longer than 30 minutes"
          }
        ],
        "index": "duration_is"
      },
      {
        "items": [
          {
            "index": "confusion_trouble_talking",
            "title": "Confusion or trouble talking"
          },
          {
            "index": "dizziness_difficulty_walking",
            "title": "Dizziness or difficulty walking"
          },
          {
            "index": "eye_pain_discomfort",
            "title": "Eye pain or discomfort"
          },
          {
            "index": "eye_redness",
            "title": "Eye redness"
          },
          {
            "index": "eyestrain",
            "title": "Eyestrain"
          },
          {
            "index": "headache",
            "title": "Headache"
          },
          {
            "index": "numbness_weakness_one_side",
            "title": "Numbness or weakness on one side of the body"
          },
          {
            "index": "other_sensory_disturbances",
            "title": "Other sensory disturbances"
          },
          {
            "index": "swelling_around_the_eye",
            "title": "Swelling around the eye"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "eye_problems"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "ankle",
            "title": "Ankle"
          },
          {
            "index": "area_along_edge_toenail",
            "title": "Area along edge of toenail"
          },
          {
            "index": "back_ankle",
            "title": "Back of ankle"
          },
          {
            "index": "bottom_foot",
            "title": "Bottom of foot"
          },
          {
            "index": "heel",
            "title": "Heel"
          },
          {
            "index": "middle_part_foot",
            "title": "Middle part of foot"
          },
          {
            "index": "toe_front_part_foot",
            "title": "Toe or front part of foot"
          },
          {
            "index": "whole_foot",
            "title": "Whole foot"
          }
        ],
        "index": "located_in"
      },
      {
        "items": [
          {
            "index": "activity_overuse",
            "title": "Activity or overuse"
          },
          {
            "index": "illfitting_shoes",
            "title": "Ill-fitting shoes"
          },
          {
            "index": "injury",
            "title": "Injury"
          },
          {
            "index": "long_periods_rest",
            "title": "Long periods of rest"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "blackandblue_mark",
            "title": "Black-and-blue mark"
          },
          {
            "index": "burning",
            "title": "Burning"
          },
          {
            "index": "difficulty_pushing_off_with_toes",
            "title": "Difficulty pushing off with toes"
          },
          {
            "index": "feeling_instability",
            "title": "Feeling of instability"
          },
          {
            "index": "flattened_arch",
            "title": "Flattened arch"
          },
          {
            "index": "inability_bear_weight",
            "title": "Inability to bear weight"
          },
          {
            "index": "joint_deformity",
            "title": "Joint deformity"
          },
          {
            "index": "numbness_tingling",
            "title": "Numbness or tingling"
          },
          {
            "index": "redness",
            "title": "Redness"
          },
          {
            "index": "stiffness",
            "title": "Stiffness"
          },
          {
            "index": "swelling",
            "title": "Swelling"
          },
          {
            "index": "thickened_rough_skin",
            "title": "Thickened or rough skin"
          },
          {
            "index": "weakness",
            "title": "Weakness"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "foot_pain_or_ankle_pain"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "dull_achy",
            "title": "Dull or achy"
          },
          {
            "index": "gradually_worsening",
            "title": "Gradually worsening"
          }
        ],
        "index": "pain_best_described_as"
      },
      {
        "items": [
          {
            "index": "along_whole_limb",
            "title": "Along whole limb"
          },
          {
            "index": "around_ankle_foot",
            "title": "Around ankle or foot"
          },
          {
            "index": "around_knee",
            "title": "Around knee"
          },
          {
            "index": "in_both_limbs",
            "title": "In both limbs"
          },
          {
            "index": "in_one_limb",
            "title": "In one limb"
          }
        ],
        "index": "swelling_occurs"
      },
      {
        "items": [
          {
            "index": "activity_overuse",
            "title": "Activity or overuse"
          },
          {
            "index": "everyday_activities",
            "title": "Everyday activities"
          },
          {
            "index": "injury",
            "title": "Injury"
          },
          {
            "index": "movement",
            "title": "Movement"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "inactivity_long_periods_rest",
            "title": "Inactivity or long periods of rest"
          },
          {
            "index": "sitting_standing_still",
            "title": "Sitting or standing still for long periods"
          }
        ],
        "index": "preceded_by"
      },
      {
        "items": [
          {
            "index": "walking",
            "title": "Walking"
          }
        ],
        "index": "relieved_by"
      },
      {
        "items": [
          {
            "index": "enlarged_purplish_vein",
            "title": "Enlarged or purplish vein in affected leg"
          },
          {
            "index": "entire_leg_calf_being_pale",
            "title": "Entire leg or calf being pale and cool"
          },
          {
            "index": "fatigue",
            "title": "Fatigue"
          },
          {
            "index": "hardening_skin_affected_area",
            "title": "Hardening of skin in affected area"
          },
          {
            "index": "heaviness_affected_limb",
            "title": "Heaviness in affected limb"
          },
          {
            "index": "loss_appetite",
            "title": "Loss of appetite"
          },
          {
            "index": "nausea",
            "title": "Nausea"
          },
          {
            "index": "pain_tenderness_aching_affected",
            "title": "Pain, tenderness or aching in affected area"
          },
          {
            "index": "persistent_cough",
            "title": "Persistent cough"
          },
          {
            "index": "rapid_irregular_heartbeat",
            "title": "Rapid or irregular heartbeat"
          },
          {
            "index": "redness_warmth_affected_area",
            "title": "Redness and warmth in affected area"
          },
          {
            "index": "shortness_breath_that_may_prevent",
            "title": "Shortness of breath that may prevent sleep unless propped up"
          },
          {
            "index": "stiffness",
            "title": "Stiffness"
          },
          {
            "index": "stiffness_limited_movement",
            "title": "Stiffness or limited movement"
          },
          {
            "index": "swelling",
            "title": "Swelling"
          },
          {
            "index": "swelling_abdomen_other_parts",
            "title": "Swelling in abdomen or other parts of body"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "foot_swelling_or_leg_swelling"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "extreme",
            "title": "Extreme"
          },
          {
            "index": "mild_moderate",
            "title": "Mild to moderate"
          },
          {
            "index": "moderate_severe",
            "title": "Moderate to severe"
          },
          {
            "index": "pressure_squeezing_sensation",
            "title": "Pressure or squeezing sensation"
          },
          {
            "index": "stabbing_burning",
            "title": "Stabbing or burning"
          },
          {
            "index": "throbbing",
            "title": "Throbbing"
          }
        ],
        "index": "pain_is"
      },
      {
        "items": [
          {
            "index": "around_one_eye_radiates",
            "title": "Around one eye or radiates from one eye"
          },
          {
            "index": "around_your_temples",
            "title": "Around your temples"
          },
          {
            "index": "both_sides_your_head",
            "title": "On both sides of your head"
          },
          {
            "index": "one_side_your_head",
            "title": "On one side of your head"
          }
        ],
        "index": "pain_located"
      },
      {
        "items": [
          {
            "index": "gradual",
            "title": "Gradual"
          },
          {
            "index": "preceded_by_a_head_injury",
            "title": "Preceded by a head injury or fall"
          },
          {
            "index": "preceded_by_frequent_use",
            "title": "Preceded by frequent use of pain medication"
          },
          {
            "index": "preceded_by_visual",
            "title": "Preceded by visual or other sensory disturbances"
          },
          {
            "index": "sudden",
            "title": "Sudden"
          }
        ],
        "index": "onset_is"
      },
      {
        "items": [
          {
            "index": "a_few_hours_days",
            "title": "A few hours to days"
          },
          {
            "index": "a_few_minutes_hours",
            "title": "A few minutes to hours"
          }
        ],
        "index": "duration_of_headache_is"
      },
      {
        "items": [
          {
            "index": "gradually_becomes_more_frequent",
            "title": "Gradually becomes more frequent"
          },
          {
            "index": "is_daily",
            "title": "Is daily"
          },
          {
            "index": "is_often_every_day",
            "title": "Is often the same time every day"
          }
        ],
        "index": "recurrence_of_headache"
      },
      {
        "items": [
          {
            "index": "change_sleep_patterns",
            "title": "Change in sleep patterns"
          },
          {
            "index": "chewing",
            "title": "Chewing"
          },
          {
            "index": "clenching_grinding_teeth",
            "title": "Clenching or grinding teeth"
          },
          {
            "index": "everyday_activities",
            "title": "Everyday activities"
          },
          {
            "index": "hormonal_changes",
            "title": "Hormonal changes"
          },
          {
            "index": "orgasm",
            "title": "Orgasm"
          },
          {
            "index": "poor_posture",
            "title": "Poor posture"
          },
          {
            "index": "stress",
            "title": "Stress"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "lying_down_the_dark",
            "title": "Lying down in the dark"
          },
          {
            "index": "overthecounter_pain_medication",
            "title": "Over-the-counter pain medication"
          },
          {
            "index": "rest",
            "title": "Rest"
          }
        ],
        "index": "relieved_by"
      },
      {
        "items": [
          {
            "index": "change_personality",
            "title": "Change in personality, behaviors or mental status"
          },
          {
            "index": "confusion",
            "title": "Confusion"
          },
          {
            "index": "difficulty_speaking",
            "title": "Difficulty speaking"
          },
          {
            "index": "dizziness",
            "title": "Dizziness"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "jaw_pain",
            "title": "Jaw pain"
          },
          {
            "index": "memory_loss",
            "title": "Memory loss or forgetfulness"
          },
          {
            "index": "muscle_joint_aches",
            "title": "Muscle or joint aches"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "persistent_weakness_numbness",
            "title": "Persistent weakness or numbness"
          },
          {
            "index": "restlessness_agitation",
            "title": "Restlessness or agitation"
          },
          {
            "index": "runny_stuffy_nose",
            "title": "Runny or stuffy nose"
          },
          {
            "index": "seizures",
            "title": "Seizures"
          },
          {
            "index": "sensitivity_light_noise",
            "title": "Sensitivity to light or noise"
          },
          {
            "index": "stiff_neck",
            "title": "Stiff neck"
          },
          {
            "index": "tender_scalp",
            "title": "Tender scalp"
          },
          {
            "index": "vision_problems",
            "title": "Vision problems"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "headaches"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "you're_anxious_stressed",
            "title": "You're anxious or stressed"
          },
          {
            "index": "you're_exerting_yourself",
            "title": "You're exerting yourself"
          },
          {
            "index": "you're_resting_going_bed",
            "title": "You're resting or going to bed"
          }
        ],
        "index": "palpitations_often_occur_when"
      },
      {
        "items": [
          {
            "index": "faster_normal",
            "title": "Faster than normal"
          },
          {
            "index": "irregular_not_steady",
            "title": "Irregular or not steady"
          },
          {
            "index": "slower_normal",
            "title": "Slower than normal"
          }
        ],
        "index": "heart_rate_is"
      },
      {
        "items": [
          {
            "index": "caffeine_alcohol",
            "title": "Caffeine or alcohol"
          },
          {
            "index": "cigarettes_recreational_drugs",
            "title": "Cigarettes or recreational drugs"
          },
          {
            "index": "medications_herbal_supplements",
            "title": "Medications or herbal supplements"
          }
        ],
        "index": "preceded_by_use_of"
      },
      {
        "items": [
          {
            "index": "chest_pain_tightness",
            "title": "Chest pain or tightness"
          },
          {
            "index": "dizziness_lightheadedness",
            "title": "Dizziness or lightheadedness"
          },
          {
            "index": "fainting",
            "title": "Fainting"
          },
          {
            "index": "headache",
            "title": "Headache"
          },
          {
            "index": "nausea",
            "title": "Nausea"
          },
          {
            "index": "nervousness",
            "title": "Nervousness"
          },
          {
            "index": "shortness_breath",
            "title": "Shortness of breath"
          },
          {
            "index": "sudden_weight_loss",
            "title": "Sudden weight loss"
          },
          {
            "index": "sweating",
            "title": "Sweating"
          },
          {
            "index": "tremors",
            "title": "Tremors"
          },
          {
            "index": "trouble_sleeping",
            "title": "Trouble sleeping"
          },
          {
            "index": "unexplained_fatigue_weakness",
            "title": "Unexplained fatigue or weakness"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "heart_palpitations"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "dull_achy",
            "title": "Dull or achy"
          },
          {
            "index": "located_the_groin",
            "title": "Located in the groin"
          },
          {
            "index": "located_the_outside",
            "title": "Located on the outside of the hip (greater trochanter)"
          },
          {
            "index": "sudden_intense",
            "title": "Sudden and intense"
          }
        ],
        "index": "pain_is"
      },
      {
        "items": [
          {
            "index": "activity_overuse",
            "title": "Activity or overuse"
          },
          {
            "index": "applying_pressure",
            "title": "Applying pressure or trying to bear weight"
          },
          {
            "index": "injury",
            "title": "Injury"
          },
          {
            "index": "long_periods_rest",
            "title": "Long periods of rest"
          },
          {
            "index": "movement",
            "title": "Movement"
          },
          {
            "index": "overuse",
            "title": "Overuse"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "decreased_range_motion",
            "title": "Decreased range of motion"
          },
          {
            "index": "decreased_range",
            "title": "Decreased range of motion such as difficulty rolling the hip or thigh outward"
          },
          {
            "index": "locking_catching",
            "title": "Locking or catching"
          },
          {
            "index": "stiffness",
            "title": "Stiffness"
          },
          {
            "index": "swelling",
            "title": "Swelling"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "hip_pain"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "dull_achy",
            "title": "Dull or achy"
          },
          {
            "index": "gradually_worsening",
            "title": "Gradually worsening"
          },
          {
            "index": "persistent",
            "title": "Persistent"
          },
          {
            "index": "sharp_severe",
            "title": "Sharp or severe"
          },
          {
            "index": "sudden",
            "title": "Sudden"
          }
        ],
        "index": "pain_best_described_as"
      },
      {
        "items": [
          {
            "index": "along_one_both_sides_knee",
            "title": "Along one or both sides of the knee"
          },
          {
            "index": "around_the_kneecap",
            "title": "Around the kneecap"
          },
          {
            "index": "behind_the_knee",
            "title": "Behind the knee"
          },
          {
            "index": "in_the_knee_joint",
            "title": "In the knee joint"
          }
        ],
        "index": "located"
      },
      {
        "items": [
          {
            "index": "everyday_activities",
            "title": "Everyday activities"
          },
          {
            "index": "injury",
            "title": "Injury"
          },
          {
            "index": "overuse",
            "title": "Overuse"
          }
        ],
        "index": "triggered_by"
      },
      {
        "items": [
          {
            "index": "movement",
            "title": "Movement"
          },
          {
            "index": "prolonged_sitting_standing",
            "title": "Prolonged sitting or standing"
          },
          {
            "index": "rest_inactivity",
            "title": "Rest or inactivity"
          }
        ],
        "index": "worsened_by"
      },
      {
        "items": [
          {
            "index": "bruising_discoloring",
            "title": "Bruising or discoloring"
          },
          {
            "index": "decreased_range_motion",
            "title": "Decreased range of motion"
          },
          {
            "index": "feeling_instability",
            "title": "Feeling of instability"
          },
          {
            "index": "grating_sensation",
            "title": "Grating sensation"
          },
          {
            "index": "inability_bear_weight",
            "title": "Inability to bear weight"
          },
          {
            "index": "joint_weakness",
            "title": "Joint weakness"
          },
          {
            "index": "locking_catching",
            "title": "Locking or catching"
          },
          {
            "index": "popping_snapping",
            "title": "Popping or snapping"
          },
          {
            "index": "skin_redness",
            "title": "Skin redness"
          },
          {
            "index": "stiffness",
            "title": "Stiffness"
          },
          {
            "index": "swelling",
            "title": "Swelling"
          },
          {
            "index": "warmth_touch",
            "title": "Warmth to touch"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "knee_pain"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "began_suddenly",
            "title": "Began suddenly"
          },
          {
            "index": "gradually_worsens",
            "title": "Gradually worsens"
          },
          {
            "index": "started_gradually",
            "title": "Started gradually"
          }
        ],
        "index": "pain"
      },
      {
        "items": [
          {
            "index": "everyday_activities",
            "title": "Everyday activities"
          },
          {
            "index": "injury",
            "title": "Injury"
          },
          {
            "index": "overuse",
            "title": "Overuse"
          }
        ],
        "index": "triggered_by"
      },
      {
        "items": [
          {
            "index": "movement",
            "title": "Movement"
          },
          {
            "index": "prolonged_rest_inactivity",
            "title": "Prolonged rest or inactivity"
          },
          {
            "index": "prolonged_sitting_standing",
            "title": "Prolonged sitting or standing"
          }
        ],
        "index": "worsened_by"
      },
      {
        "items": [
          {
            "index": "decreased_range_motion_stiffness",
            "title": "Decreased range of motion or stiffness in neck"
          },
          {
            "index": "knot_tight_spot",
            "title": "Knot or tight spot in back muscle"
          },
          {
            "index": "loss_balance_coordination",
            "title": "Loss of balance or coordination"
          },
          {
            "index": "loss_bowel_bladder_control",
            "title": "Loss of bowel or bladder control"
          },
          {
            "index": "muscle_weakness",
            "title": "Muscle weakness"
          },
          {
            "index": "numbness_tingling_bilateral",
            "title": "Numbness or tingling in limbs, most often on both sides of the body (bilateral)"
          },
          {
            "index": "numbness_tingling_unilateral",
            "title": "Numbness or tingling in limbs, most often on one side of the body (unilateral)"
          },
          {
            "index": "shoulder_arm_chest_pain",
            "title": "Shoulder, arm or chest pain"
          },
          {
            "index": "stiffness_lower_back",
            "title": "Stiffness in lower back"
          },
          {
            "index": "swelling",
            "title": "Swelling"
          },
          {
            "index": "weakness_numbness",
            "title": "Weakness, numbness or tingling in leg or foot"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "low_back_pain"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "new_recent",
            "title": "New or recent"
          },
          {
            "index": "ongoing_persistent",
            "title": "Ongoing or persistent"
          }
        ],
        "index": "nasal_congestion_is"
      },
      {
        "items": [
          {
            "index": "clear_watery",
            "title": "Clear or watery"
          },
          {
            "index": "yellow_greenish",
            "title": "Yellow or greenish"
          }
        ],
        "index": "nasal_discharge_is"
      },
      {
        "items": [
          {
            "index": "bad_breath",
            "title": "Bad breath"
          },
          {
            "index": "cough",
            "title": "Cough"
          },
          {
            "index": "ear_pain",
            "title": "Ear pain"
          },
          {
            "index": "fatigue",
            "title": "Fatigue"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "headache_facial_pain",
            "title": "Headache or facial pain"
          },
          {
            "index": "itchy_eyes_nose_mouth_throat",
            "title": "Itchy eyes, nose, mouth or throat"
          },
          {
            "index": "mild_body_aches",
            "title": "Mild body aches"
          },
          {
            "index": "nosebleeds",
            "title": "Nosebleeds"
          },
          {
            "index": "reduced_sense_smell_taste",
            "title": "Reduced sense of smell or taste"
          },
          {
            "index": "runny_nose",
            "title": "Runny nose"
          },
          {
            "index": "sneezing",
            "title": "Sneezing"
          },
          {
            "index": "snoring",
            "title": "Snoring"
          },
          {
            "index": "sore_throat",
            "title": "Sore throat"
          },
          {
            "index": "upper_jaw_tooth_pain",
            "title": "Upper jaw or tooth pain"
          },
          {
            "index": "watery_eyes",
            "title": "Watery eyes"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "nasal_congestion"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "drinking_alcohol",
            "title": "Drinking alcohol"
          },
          {
            "index": "eating_certain_foods",
            "title": "Eating certain foods"
          },
          {
            "index": "stress",
            "title": "Stress"
          },
          {
            "index": "travel_motion",
            "title": "Travel or motion"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "intermittent_episodic",
            "title": "Intermittent or episodic"
          },
          {
            "index": "recent_few_hours",
            "title": "Recent (few hours)"
          },
          {
            "index": "recent_hours_days",
            "title": "Recent (hours to days)"
          },
          {
            "index": "sudden_minutes_hours",
            "title": "Sudden (minutes to hours)"
          }
        ],
        "index": "onset_is"
      },
      {
        "items": [
          {
            "index": "abdominal_bloating_swelling",
            "title": "Abdominal bloating or swelling"
          },
          {
            "index": "abdominal_pain_discomfort_cramps",
            "title": "Abdominal pain, discomfort or cramps"
          },
          {
            "index": "anxiety",
            "title": "Anxiety"
          },
          {
            "index": "black_bloody_stools",
            "title": "Black or bloody stools"
          },
          {
            "index": "chest_pain_tightness",
            "title": "Chest pain or tightness"
          },
          {
            "index": "constipation",
            "title": "Constipation"
          },
          {
            "index": "diarrhea",
            "title": "Diarrhea"
          },
          {
            "index": "difficulty_breathing",
            "title": "Difficulty breathing"
          },
          {
            "index": "dizziness_fainting",
            "title": "Dizziness or fainting"
          },
          {
            "index": "feeling_fullness",
            "title": "Feeling of fullness after eating small amounts of food"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "headache",
            "title": "Headache"
          },
          {
            "index": "heartburn",
            "title": "Heartburn"
          },
          {
            "index": "irregular_rapid_heartbeat",
            "title": "Irregular or rapid heartbeat"
          },
          {
            "index": "sweating",
            "title": "Sweating"
          },
          {
            "index": "unintended_weight_loss",
            "title": "Unintended weight loss"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "nausea_or_vomiting"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "gradually",
            "title": "Gradually"
          },
          {
            "index": "suddenly",
            "title": "Suddenly"
          }
        ],
        "index": "pain_started"
      },
      {
        "items": [
          {
            "index": "injury",
            "title": "Injury"
          },
          {
            "index": "physical_exertion",
            "title": "Physical exertion"
          },
          {
            "index": "poor_posture",
            "title": "Poor posture"
          },
          {
            "index": "stress",
            "title": "Stress"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "decreased_range_stiffness_neck",
            "title": "Decreased range of motion or stiffness in neck"
          },
          {
            "index": "headache",
            "title": "Headache"
          },
          {
            "index": "loss_balance_coordination",
            "title": "Loss of balance or coordination"
          },
          {
            "index": "loss_bowel_bladder_control",
            "title": "Loss of bowel or bladder control"
          },
          {
            "index": "muscle_weakness",
            "title": "Muscle weakness"
          },
          {
            "index": "numbness_tingling_limbs",
            "title": "Numbness or tingling in limbs"
          },
          {
            "index": "shoulder_arm_chest_pain",
            "title": "Shoulder, arm or chest pain"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "neck_pain"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "affects_your_fingers_hand",
            "title": "Affects your fingers or hand"
          },
          {
            "index": "is_intermittent",
            "title": "Is intermittent"
          },
          {
            "index": "started_gradually",
            "title": "Started gradually"
          },
          {
            "index": "wakes_you_up_at_night",
            "title": "Wakes you up at night"
          }
        ],
        "index": "numbness_or_tingling"
      },
      {
        "items": [
          {
            "index": "being_one_position",
            "title": "Being in one position for a long time"
          },
          {
            "index": "repetitive_use",
            "title": "Repetitive use"
          }
        ],
        "index": "triggered_by"
      },
      {
        "items": [
          {
            "index": "rest",
            "title": "Rest"
          }
        ],
        "index": "relieved_by"
      },
      {
        "items": [
          {
            "index": "sleep",
            "title": "Sleep"
          }
        ],
        "index": "worsened_by"
      },
      {
        "items": [
          {
            "index": "arm_hand_pain",
            "title": "Arm or hand pain"
          },
          {
            "index": "arm_hand_weakness",
            "title": "Arm or hand weakness"
          },
          {
            "index": "burning_pain",
            "title": "Burning pain"
          },
          {
            "index": "extreme_sensitivity_touch",
            "title": "Extreme sensitivity to touch"
          },
          {
            "index": "frequent_feeling",
            "title": "Frequent feeling that a foot or hand has 'fallen asleep'"
          },
          {
            "index": "gradual_onset_numbness_prickling",
            "title": "Gradual onset of numbness, prickling or tingling in your feet or hands, which can spread upward into your legs and arms"
          },
          {
            "index": "lack_coordination_falling",
            "title": "Lack of coordination and falling"
          },
          {
            "index": "muscle_weakness_affected_area",
            "title": "Muscle weakness in the affected area"
          },
          {
            "index": "muscle_weakness_paralysis",
            "title": "Muscle weakness or paralysis"
          },
          {
            "index": "numbness_decreased_sensation",
            "title": "Numbness or decreased sensation in the area supplied by the nerve"
          },
          {
            "index": "sharp_aching_burning_pain",
            "title": "Sharp, aching or burning pain, which may radiate outward"
          },
          {
            "index": "sharp_jabbing_throbbing_freezing",
            "title": "Sharp, jabbing, throbbing, freezing or burning pain"
          },
          {
            "index": "tingling_pins",
            "title": "Tingling, pins and needles sensations (paresthesia)"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "numbness_or_tingling_in_hands"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "beginning_suddenly",
            "title": "Beginning suddenly"
          },
          {
            "index": "cyclical_recurrent",
            "title": "Cyclical or recurrent"
          },
          {
            "index": "different_menstrual_cramps",
            "title": "Different from usual menstrual cramps"
          },
          {
            "index": "dull_achy",
            "title": "Dull or achy"
          },
          {
            "index": "itching_burning_around_genitals",
            "title": "Itching or burning around genitals, buttocks or inner thighs"
          },
          {
            "index": "ongoing",
            "title": "Ongoing"
          },
          {
            "index": "sharp_crampy",
            "title": "Sharp or crampy"
          }
        ],
        "index": "pain_best_described_as"
      },
      {
        "items": [
          {
            "index": "lifting_straining",
            "title": "Lifting or straining"
          },
          {
            "index": "menstrual_cycle",
            "title": "Menstrual cycle"
          },
          {
            "index": "movement_physical_activity",
            "title": "Movement or physical activity"
          },
          {
            "index": "stress",
            "title": "Stress"
          }
        ],
        "index": "worsened_by"
      },
      {
        "items": [
          {
            "index": "abnormal_vaginal_bleeding",
            "title": "Abnormal vaginal bleeding"
          },
          {
            "index": "black_bloody_stools",
            "title": "Black or bloody stools"
          },
          {
            "index": "bloating_constipation_diarrhea",
            "title": "Bloating, constipation or diarrhea"
          },
          {
            "index": "bumps_blisters_open_sores",
            "title": "Bumps, blisters or open sores"
          },
          {
            "index": "change_vaginal_discharge",
            "title": "Change in vaginal discharge"
          },
          {
            "index": "excessive_menstrual_bleeding",
            "title": "Excessive menstrual bleeding"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "frequent_urination_urge_urinate",
            "title": "Frequent urination or urge to urinate"
          },
          {
            "index": "increased_waist_size",
            "title": "Increased waist size"
          },
          {
            "index": "irregular_periods",
            "title": "Irregular periods"
          },
          {
            "index": "lightheadedness_fainting",
            "title": "Lightheadedness or fainting"
          },
          {
            "index": "low_back_pain",
            "title": "Low back pain"
          },
          {
            "index": "mood_swings",
            "title": "Mood swings"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "painful_bowel_movements",
            "title": "Painful bowel movements"
          },
          {
            "index": "painful_difficult_urination",
            "title": "Painful or difficult urination"
          },
          {
            "index": "painful_sexual_intercourse",
            "title": "Painful sexual intercourse"
          },
          {
            "index": "skin_rash",
            "title": "Skin rash"
          },
          {
            "index": "unintended_weight_loss",
            "title": "Unintended weight loss"
          },
          {
            "index": "weight_gain",
            "title": "Weight gain"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "pelvic_pain:_female"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "discomfort_pressure",
            "title": "Discomfort, pressure or pain in lower abdomen or groin"
          },
          {
            "index": "itching_burning",
            "title": "Itching or burning around genitals, buttocks or inner thighs"
          },
          {
            "index": "pain_discomfort",
            "title": "Pain or discomfort in the penis"
          },
          {
            "index": "painful_intercourse",
            "title": "Painful intercourse or ejaculation"
          },
          {
            "index": "painful_urination",
            "title": "Painful urination"
          },
          {
            "index": "testicle_pain",
            "title": "Testicle pain"
          }
        ],
        "index": "pain_best_described_as"
      },
      {
        "items": [
          {
            "index": "blood_semen",
            "title": "Blood in semen"
          },
          {
            "index": "blood_urine",
            "title": "Blood in urine"
          },
          {
            "index": "bumps_blisters",
            "title": "Bumps, blisters or open sores"
          },
          {
            "index": "clear_discharge_from_penis",
            "title": "Clear discharge from penis"
          },
          {
            "index": "difficulty_urinating",
            "title": "Difficulty urinating or emptying bladder completely"
          },
          {
            "index": "discharge_from_the_penis",
            "title": "Discharge from the penis"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "frequent_urination",
            "title": "Frequent urination or urge to urinate"
          },
          {
            "index": "lump_swelling_scrotum",
            "title": "Lump or swelling in scrotum"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "pusfilled_discharge_from_penis",
            "title": "Pus-filled discharge from penis"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "pelvic_pain:_male"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "new_recent",
            "title": "New or recent"
          },
          {
            "index": "ongoing_recurrent",
            "title": "Ongoing or recurrent"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "trauma_injury",
            "title": "Trauma or injury"
          },
          {
            "index": "upper_respiratory_illness",
            "title": "Upper respiratory illness"
          }
        ],
        "index": "preceded_by"
      },
      {
        "items": [
          {
            "index": "allergens_irritants",
            "title": "Allergens or irritants"
          },
          {
            "index": "anxiety_stress",
            "title": "Anxiety or stress"
          },
          {
            "index": "coughing_sneezing",
            "title": "Coughing, sneezing or deep breathing"
          },
          {
            "index": "exposure_cold_air",
            "title": "Exposure to cold air"
          },
          {
            "index": "mild_moderate_exertion",
            "title": "Mild to moderate exertion"
          },
          {
            "index": "smoking",
            "title": "Smoking"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "chest_pain_tightness",
            "title": "Chest pain or tightness"
          },
          {
            "index": "cough_that_produces_blood",
            "title": "Cough that produces blood"
          },
          {
            "index": "cough_that_produces_sputum",
            "title": "Cough that produces sputum"
          },
          {
            "index": "dizziness_fainting",
            "title": "Dizziness or fainting"
          },
          {
            "index": "dry_cough",
            "title": "Dry cough"
          },
          {
            "index": "fatigue_weakness",
            "title": "Fatigue or weakness"
          },
          {
            "index": "fever_chills",
            "title": "Fever or chills"
          },
          {
            "index": "headache",
            "title": "Headache"
          },
          {
            "index": "irregular_rapid_heartbeat",
            "title": "Irregular or rapid heartbeat"
          },
          {
            "index": "nausea",
            "title": "Nausea"
          },
          {
            "index": "pale_bluish_skin",
            "title": "Pale or bluish skin"
          },
          {
            "index": "rapid_weight_gain",
            "title": "Rapid weight gain"
          },
          {
            "index": "sweating",
            "title": "Sweating"
          },
          {
            "index": "swelling_legs_ankles_feet",
            "title": "Swelling of legs, ankles and feet"
          },
          {
            "index": "trembling",
            "title": "Trembling"
          },
          {
            "index": "wheezing",
            "title": "Wheezing"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "shortness_of_breath"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "dull_achy",
            "title": "Dull or achy"
          },
          {
            "index": "sharp_severe",
            "title": "Sharp or severe"
          }
        ],
        "index": "pain_is"
      },
      {
        "items": [
          {
            "index": "gradual",
            "title": "Gradual"
          },
          {
            "index": "sudden",
            "title": "Sudden"
          }
        ],
        "index": "onset_is"
      },
      {
        "items": [
          {
            "index": "everyday_activities",
            "title": "Everyday activities"
          },
          {
            "index": "injury",
            "title": "Injury"
          },
          {
            "index": "overuse_exertion",
            "title": "Overuse or exertion"
          },
          {
            "index": "rest_inactivity",
            "title": "Rest or inactivity"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "inability_move_shoulder",
            "title": "Inability to move shoulder"
          },
          {
            "index": "muscle_spasm",
            "title": "Muscle spasm"
          },
          {
            "index": "numbness_tingling",
            "title": "Numbness or tingling"
          },
          {
            "index": "shoulder_arm_weakness",
            "title": "Shoulder or arm weakness"
          },
          {
            "index": "shoulder_stiffness",
            "title": "Shoulder stiffness"
          },
          {
            "index": "swelling",
            "title": "Swelling"
          },
          {
            "index": "visible_deformity",
            "title": "Visible deformity"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "shoulder_pain"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "bluecolored_skin_lips",
            "title": "Blue-colored skin or lips"
          },
          {
            "index": "chills_sweating",
            "title": "Chills or sweating"
          },
          {
            "index": "cough",
            "title": "Cough"
          },
          {
            "index": "difficult_painful_swallowing",
            "title": "Difficult or painful swallowing"
          },
          {
            "index": "difficult_raspy_breathing",
            "title": "Difficult or raspy breathing"
          },
          {
            "index": "enlarged_tender_lymph_nodes_neck",
            "title": "Enlarged, tender lymph nodes in neck"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "headache_facial_pain",
            "title": "Headache or facial pain"
          },
          {
            "index": "heartburn",
            "title": "Heartburn"
          },
          {
            "index": "hoarse_muffled_voice",
            "title": "Hoarse or muffled voice"
          },
          {
            "index": "muscle_aches",
            "title": "Muscle aches"
          },
          {
            "index": "red_swollen_tonsils",
            "title": "Red, swollen tonsils"
          },
          {
            "index": "runny_stuffy_nose",
            "title": "Runny or stuffy nose"
          },
          {
            "index": "skin_rash",
            "title": "Skin rash"
          },
          {
            "index": "sneezing",
            "title": "Sneezing"
          },
          {
            "index": "watery_eyes",
            "title": "Watery eyes"
          },
          {
            "index": "white_patches_pus_tonsils",
            "title": "White patches or pus on tonsils"
          },
          {
            "index": "yellow_greenish_phlegm_or_discharge_from_your_nose",
            "title": "Yellow or greenish phlegm or discharge from your nose"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "sore_throat"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "awaking_two_more_times",
            "title": "Awaking two or more times in the night to urinate"
          },
          {
            "index": "blood_urine",
            "title": "Blood in urine"
          },
          {
            "index": "change_urine_color",
            "title": "Change in urine color"
          },
          {
            "index": "cloudy_urine",
            "title": "Cloudy urine"
          },
          {
            "index": "difficulty_emptying_bladder",
            "title": "Difficulty emptying bladder or weak urine stream"
          },
          {
            "index": "difficulty_starting_urination",
            "title": "Difficulty starting urination"
          },
          {
            "index": "frequent_urination",
            "title": "Frequent urination"
          },
          {
            "index": "involuntary_loss_urine",
            "title": "Involuntary loss of urine"
          },
          {
            "index": "pain_burning_when_urinating",
            "title": "Pain or burning when urinating"
          },
          {
            "index": "strong_persistent_urge_urinate",
            "title": "Strong, persistent urge to urinate"
          }
        ],
        "index": "problem_is"
      },
      {
        "items": [
          {
            "index": "itching_burning_around",
            "title": "Itching or burning around genitals, buttocks or inner thighs"
          },
          {
            "index": "pain_back_side",
            "title": "Pain in back or side"
          },
          {
            "index": "pain_lower_abdomen_groin",
            "title": "Pain in lower abdomen or groin"
          },
          {
            "index": "painful_ejaculation",
            "title": "Painful ejaculation"
          },
          {
            "index": "painful_sexual_intercourse",
            "title": "Painful sexual intercourse"
          }
        ],
        "index": "related_pain_involves"
      },
      {
        "items": [
          {
            "index": "menstrual_cycle",
            "title": "Menstrual cycle"
          },
          {
            "index": "stress",
            "title": "Stress"
          }
        ],
        "index": "worsened_by"
      },
      {
        "items": [
          {
            "index": "blurred_vision",
            "title": "Blurred vision"
          },
          {
            "index": "bumps_blisters",
            "title": "Bumps, blisters or open sores around genitals"
          },
          {
            "index": "change_vaginal_discharge",
            "title": "Change in vaginal discharge"
          },
          {
            "index": "clear_discharge_from_penis",
            "title": "Clear discharge from penis"
          },
          {
            "index": "extreme_thirst_hunger",
            "title": "Extreme thirst or hunger"
          },
          {
            "index": "fatigue",
            "title": "Fatigue"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "nausea_vomiting",
            "title": "Nausea or vomiting"
          },
          {
            "index": "pusfilled_discharge_from_penis",
            "title": "Pus-filled discharge from penis"
          },
          {
            "index": "unintended_weight_loss",
            "title": "Unintended weight loss"
          },
          {
            "index": "vaginal_odor",
            "title": "Vaginal odor"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "urinary_problems"
  },
  {
    "related_factors": [
      {
        "items": [
          {
            "index": "new_recent",
            "title": "New or recent"
          },
          {
            "index": "ongoing_recurrent",
            "title": "Ongoing or recurrent"
          }
        ],
        "index": "wheezing_is"
      },
      {
        "items": [
          {
            "index": "upper_respiratory_illness",
            "title": "Upper respiratory illness"
          }
        ],
        "index": "preceded_by"
      },
      {
        "items": [
          {
            "index": "allergens_irritants",
            "title": "Allergens or irritants"
          },
          {
            "index": "exposure_cold_air",
            "title": "Exposure to cold air"
          },
          {
            "index": "mild_moderate_exertion",
            "title": "Mild to moderate exertion"
          },
          {
            "index": "smoking",
            "title": "Smoking"
          }
        ],
        "index": "triggered_or_worsened_by"
      },
      {
        "items": [
          {
            "index": "bluecolored_skin_lips",
            "title": "Blue-colored skin or lips"
          },
          {
            "index": "chest_pain_tightness",
            "title": "Chest pain or tightness"
          },
          {
            "index": "cough_that_produces_sputum",
            "title": "Cough that produces sputum"
          },
          {
            "index": "difficult_painful_swallowing",
            "title": "Difficult or painful swallowing"
          },
          {
            "index": "dry_cough",
            "title": "Dry cough"
          },
          {
            "index": "fatigue_weakness",
            "title": "Fatigue or weakness"
          },
          {
            "index": "fever",
            "title": "Fever"
          },
          {
            "index": "hoarse_muffled_voice",
            "title": "Hoarse or muffled voice"
          },
          {
            "index": "shortness_breath",
            "title": "Shortness of breath"
          },
          {
            "index": "sore_throat",
            "title": "Sore throat"
          }
        ],
        "index": "accompanied_by"
      }
    ],
    "index": "wheezing"
  }
];