import { Component, OnInit, Input } from '@angular/core';

import * as _ from 'lodash';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css']
})
export class ChartComponent implements OnInit 
{
  @Input() chartData;
  @Input() chartHeader;

  private Logs;
  private ready = false;

  private fromDate : Date;
  private toDate : Date;

  constructor( ) { }

  public lineChartData:Array<any> = [
    /*
    {data: [ ], label: 'Sistolic'},
    {data: [ ], label: 'Diastolic'}
    */
  ];

  public lineChartLabels:Array<any> = [ ]; // linia cu data

  public lineChartOptions:any = {
    responsive: true,
    scales: 
    {
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }],
      xAxes: [{
        type: 'time',
        ticks: {
          stepSize: 0.5
        }
      }]
    },
    tooltips: 
    {
      mode: 'index',
      axis: 'y'
    }
  };
  public lineChartColors:Array<any> = [
    {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: 'rgba(113, 146, 255, 1)',
      pointBackgroundColor: 'rgba(113, 146, 255, 1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(113, 146, 255, 0.8)'
    },
    {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: 'rgba(248, 135, 130, 1)',
      pointBackgroundColor: 'rgba(248, 135, 130, 1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(248, 135, 130, 1)'
    },
    {
      backgroundColor: 'rgba(0, 0, 0, 0)',
      borderColor: 'rgba(96, 210, 211, 1)',
      pointBackgroundColor: 'rgba(96, 210, 211, 1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(96, 210, 211, 1)'
    }
  ];
  public lineChartLegend:boolean = true;
  public lineChartType:string = 'line';

  ngOnInit()
  {
    this.initGraph();
    //this.testGraph();
  }

  initGraph()
  { 
    this.lineChartData = [];
    this.lineChartLabels = [];

    this.Logs = _.orderBy( this.chartData, ['form_data.date'], ['asc'] );

    for(let i = 0; i < this.chartHeader.length; i++)
    {
      this.lineChartData.push( { data: [ ], label: this.chartHeader[i].toUpperCase() } );
    }

    let from;
    if( this.fromDate )
      from = this.fromDate.getTime();

    let to;
    if( this.toDate )
      to = this.toDate.getTime();

    
    if( this.Logs.length == 1 )
    {
      for(let i = 0; i < this.chartHeader.length; i++)
      {
        this.lineChartData[i].data.push( 0 );
      }

      this.lineChartLabels.push( this.DateOneWeekAgo(this.Logs[0].form_data['date']).setHours(0, 0, 0, 0) );
    }

    this.Logs.forEach( log => {
      let ok = false;

      let date = log.form_data['date'];

      for(let i = 0; i < this.chartHeader.length; i++)
      {
        let header = this.chartHeader[i];

        if( log.form_data[header] )
        {
          if( from || to )
          {
            if( from && !to )
            {
              if( date >= from )
              {
                this.lineChartData[i].data.push( log.form_data[header] );
                ok = true;
              }
            }
            else if( !from && to )
            {
              if( date <= to )
              {
                this.lineChartData[i].data.push( log.form_data[header] );
                ok = true;
              }
            }
            else if( date >= from && date <= to )
            {
              this.lineChartData[i].data.push( log.form_data[header] );
              ok = true;
            }
          }
          else
          {
            this.lineChartData[i].data.push( log.form_data[header] );
            ok = true;
          }
        }
      }

      if( ok )
      {
        let date = new Date(log.form_data['date']).setHours(0, 0, 0, 0);
        this.lineChartLabels.push( date );
      }
    });

    this.ready = true;
  }

  updateGraph( value )
  {
    this.ready = false;

    setTimeout( () =>
    {
      this.initGraph();
    }, 1000);
  }

  DateOneMonthAgo()
  {
    var d = new Date();
    d.setDate(d.getDate() - 30);
    return d;
  }

  DateOneWeekAgo( from )
  {
    var d = new Date(from);
    d.setDate(d.getDate() - 7);
    return d;
  }

  testGraph()
  {
    var now = new Date();
    var daysOfYear = [];
    for (var d = new Date(2018, 10, 1); d <= now; d.setDate(d.getDate() + 1)) 
    {
      let sistolic, diastolic;

      if( Math.floor(Math.random() * 30) >= 20 )
      {
        sistolic = Math.floor(Math.random() * 90 + 100);
        diastolic = Math.floor(Math.random() * 30 + 70);

        this.lineChartData[0].data.push( sistolic );
        this.lineChartData[1].data.push( diastolic );
        this.lineChartLabels.push( new Date(d) );
      }
      
      if( Math.floor(Math.random() * 30) >= 25 )
      {
        sistolic = Math.floor(Math.random() * 90 + 100);
        diastolic = Math.floor(Math.random() * 30 + 70);

        this.lineChartData[0].data.push( sistolic );
        this.lineChartData[1].data.push( diastolic );
        
        this.lineChartLabels.push( new Date(d) );
      }
    }
  }
}