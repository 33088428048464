import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';

import { AuthService } from '../../services/auth.service';

import { LinkService } from '../../services/link.service';

import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit, OnDestroy
{
  private notifications;
  private requestSelected = false;
  private requestSelectedData;
  private ready = false;
  private permissions;
  
  //Subscribers
  private subscribe_Service;

  constructor( private NotificationService : NotificationService, private AuthService : AuthService, private LinkService : LinkService )
  {
  }

  ngOnInit()
  {
    this.subscribe_Service = this.NotificationService.getNotificationsByUID( this.AuthService.getLoggedInUser().uid ).subscribe( notifications => {
      this.notifications = notifications;

      this.ready = true;
    });
  }

  ngOnDestroy()
  {
    this.subscribe_Service.unsubscribe();
  }

  acceptRequest( notification )
  { 
    let link = notification.data;
    link['status'] = "accepted";
    link['permissions'] = this.permissions;

    this.LinkService.updateLink(link).then( () => {      
      this.NotificationService.readNotificationByID(notification.id).then( () => {
      });
    });
  }

  rejectRequest( notification )
  {
    this.LinkService.deleteLinkByID(notification.data.id).then( () => {
      this.NotificationService.readNotificationByID(notification.id).then( () => {
      });
    });
  }

  changePermissions( permissions )
  {
    this.permissions = permissions;
  }

  onClickRequest( notification )
  {
    this.requestSelectedData = notification;
    this.requestSelected = true;
  }

  back()
  {
    this.requestSelected = false;
  }
}
