import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';

import { MeasurementService } from '../../../../services/measurement.service';

import { ModalHelpButtonComponent } from '../../../modal-help-button/modal-help-button.component';

import * as _ from 'lodash';

@Component({
  selector: 'app-tension-form',
  templateUrl: './tension-form.component.html',
  styleUrls: ['./tension-form.component.css']
})
export class TensionFormComponent implements OnInit
{
  private maxDate;
  private date;
  private time_slot;

  @Input() uID : string;
  @Input() preloadedData;

  @Output() statusChanged = new EventEmitter();

  @ViewChild(ModalHelpButtonComponent) HelpModal: ModalHelpButtonComponent;

  constructor( private MeasurementService : MeasurementService ) { }

  ngOnInit( )
  {
    if( this.preloadedData )
    {
      this.time_slot = this.getPreloadedData("time_slot");
      this.date = new Date(this.preloadedData.form_data.date);
    }
    else
    {
      this.date = new Date();
    }
      
    this.maxDate = new Date();
  }

  getPreloadedData( key : string )
  {
    if( this.preloadedData && typeof(this.preloadedData.form_data[key]) != 'undefined' )
      return this.preloadedData.form_data[key];
    return null;
  }

  submitTensionLog( tensionForm : NgForm )
  {
    if( tensionForm.valid )
    {
      let tension_data = tensionForm.value;
      tension_data['date'] = this.date.getTime();

      if( this.time_slot )
        tension_data['time_slot'] = this.time_slot;

      if( !this.preloadedData )
      { 
        this.MeasurementService.saveMeasurement( "tension", this.uID , tension_data ).then( logID =>
        {
          tensionForm.resetForm();
          this.statusChanged.emit("submited");
        });
      }
      else
      {
        this.MeasurementService.updateMeasurement( this.preloadedData['id'], tension_data).then( () => {
          tensionForm.resetForm();
          this.statusChanged.emit("submited");
        });
      }
    }
  }

  openHelpModal ( helpText : string)
  {
    this.HelpModal.openHelpModal(helpText);
  }

  selectTimeSlot( time : string )
  {
    this.time_slot = time;
  }
}
