import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { NgForm } from '@angular/forms';

import { AuthService } from '../../services/auth.service';

import { UserService } from '../../services/user.service';

import { CommentsService } from '../../services/comments.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit, OnDestroy 
{
  @Input() logID : string;
  @Input() logCategory : string;
  
  private ready = false;
  private currentUser;
  
  private comments = [];
  private users = [];

  private subscribe_UserService;
  private subscribe_CommentsService;

  constructor( private CommentsService : CommentsService, private AuthService : AuthService, private UserService : UserService )
  {
  }

  ngOnInit()
  { 
    if( this.logID && this.logCategory )
    {
      this.currentUser = this.AuthService.getLoggedInUser();
      
      this.subscribe_UserService = this.UserService.getAllUsers().subscribe( users => {
        this.users = users;
      });

      this.subscribe_CommentsService = this.CommentsService.getCommentsByLogID(this.logID, this.logCategory).subscribe( comments => {
        this.prepareComments(comments);
      });
    }
  }

  ngOnDestroy()
  {
    if( this.logID && this.logCategory )
    {
      this.subscribe_UserService.unsubscribe();
      this.subscribe_CommentsService.unsubscribe();
    }
  }

  prepareComments( comments )
  {
    for(var i = 0; i < comments.length; i++)
    {
      let findUserIndex = _.findIndex(this.users, {'uid': comments[i].uID});
      comments[i].user = this.users[findUserIndex];
    }

    this.comments = comments;
    this.ready = true;
  }

  sendComment( form : NgForm )
  {
    if( form.value.text )
      this.CommentsService.add(this.logID, this.logCategory, this.AuthService.getLoggedInUser().uid, form.value.text ).then( () => {
        form.resetForm();
      });
  }

  getUserAvatarPath( avatar : string )
  {
    return `assets/animals_icons/${avatar}.png`; 
  }
}
