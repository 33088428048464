import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth.service';

import { MoodService } from '../../services/mood.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-mood',
  templateUrl: './mood-form.component.html',
  styleUrls: ['./mood-form.component.css']
})
export class MoodFormComponent implements OnInit 
{
  private done = false;
  private loading = false;
  private display = false;

  private mood_intervals = [
    {
      start: {
        hour: 0,
        minutes: 0,
        seconds: 0
      },
      end: {
        hour: 11,
        minutes: 0,
        seconds: 0
      }
    },

    {
      start: {
        hour: 11,
        minutes: 0,
        seconds: 1
      },
      end: {
        hour: 17,
        minutes: 0,
        seconds: 0
      }
    },

    {
      start: {
        hour: 17,
        minutes: 0,
        seconds: 1
      },
      end: {
        hour: 23,
        minutes: 59,
        seconds: 59
      }
    }
  ];

  constructor( private AuthService : AuthService, private MoodService : MoodService ) {}

  ngOnInit()
  {
    if( this.AuthService.getLoggedInUser().tutorialFinished ) this.checkMoodByInterval();
  }

  sendMood( mood : string )
  {
    this.loading = true;

    this.MoodService.saveMood( this.AuthService.getLoggedInUser().uid, mood ).then( () => {
      this.loading = false;
      this.done = true;

      this.display = false;
    });
  }

  checkMoodByInterval( )
  {
    let current_date = Date.now();
    let interval, start_hour, end_hour;

    for(var i = 0; i < this.mood_intervals.length; i++)
    {
      interval = this.mood_intervals[i];
      start_hour = new Date().setHours(interval.start.hour, interval.start.minutes, interval.start.seconds);
      end_hour = new Date().setHours(interval.end.hour, interval.end.minutes, interval.end.seconds);

      if( current_date >= start_hour && current_date <= end_hour )
        break;
    }

    this.MoodService.getMoodsByUID( this.AuthService.getLoggedInUser().uid ).then( moods => {
      let countMoodsByInterval = _.filter(moods, mood => mood.date >= start_hour && mood.date <= end_hour );

      if( countMoodsByInterval.length == 0 )
        this.display = true;
    });
  }
}