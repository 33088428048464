import { Injectable } from '@angular/core';

import { AngularFirestore  } from 'angularfire2/firestore';

@Injectable()
export class InvitationService 
{
  constructor(private cloud: AngularFirestore)
  {}

  public saveInvitation( by_uID : string, email : string )
  {
    return new Promise((resolve, reject) => 
    {
      let invitation_data = 
      {
        id: this.generateRandomString(),
        by_uID: by_uID,
        email: email,
        status: "available"
      };

      const postRef = this.cloud.doc(`invitations/${invitation_data['id']}`);

      postRef.set(invitation_data).then(response => {
          resolve(invitation_data);
      })
      .catch(error => {
          console.log(error);
          reject(error);
      });
    });
  }

  public changeInvitationStatus( by_uID : string )
  {
    return new Promise((resolve, reject) => 
    {
      const postRef = this.cloud.doc(`invitations/${by_uID}`);

      postRef.update({ status: "unavailable" }).then( () => {
        resolve();
      }).catch( error => {
        console.log(error);
        reject(error);
      });
    });
  }

  public getInvitationByID( invitationID : string )
  {
    const invitationRef = this.cloud.doc(`invitations/${invitationID}`).valueChanges();

    return new Promise((resolve, reject) => {
      let subscribe = invitationRef.subscribe(invitation => {
        resolve(invitation);
        subscribe.unsubscribe();
      });
    });
  }

  private generateRandomString(): string 
  {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' + Date.now();

    for (let i = 0 ; i < 20; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}
