export var items = [
    {
        index: "future_appointments",
        title: "Programari viitoare"
    },
    {
        index: "disease",
        title: "Boli"
    },
    {
        index: "medical_procedures",
        title: "Proceduri medicale"
    },
    {
        index: "medication",
        title: "Medicatie"
    },
    {
        index: "used_medication",
        title: "Medicatie folosita"
    },
    {
        index: "immunizations",
        title: "Imunizari"
    },
    {
        index: "allergies",
        title: "Alergii"
    },
    {
        index: "events",
        title: "Evenimente"
    },
    {
        index: "medical_devices",
        title: "Dispozitive medicale"
    },
    {
        index: "emotional_state",
        title: "Stare emotionala"
    },
    {
        index: "laboratory_results",
        title: "Rezultate de laborator si fisiere"
    },
    {
        index: "family_history",
        title: "Istoric in familie"
    },
    {
        index: "insurance",
        title: "Asigurari"
    },
    {
        index: "ecography",
        title: "Ecografii"
    }
];