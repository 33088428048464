import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';

import { HealthService } from '../../../../services/health.service';

import { ModalHelpButtonComponent } from '../../../modal-help-button/modal-help-button.component';


import { StorageService } from '../../../../services/storage.service';

@Component({
  selector: 'app-my-health-form-laboratory-results',
  templateUrl: './laboratory_results.component.html',
  styleUrls: ['./laboratory_results.component.css']
})
export class MyHealthFormLaboratoryResultsComponent implements OnInit
{
  private maxDate;
  private date;
  private loading = false;

  private filesArray = [];

  private messageCodeReturned;

  @Input() uID : string;
  @Input() preloadedData;

  @Output() statusChanged = new EventEmitter();

  @ViewChild(ModalHelpButtonComponent) HelpModal: ModalHelpButtonComponent;


  constructor( private HealthService : HealthService, private StorageService : StorageService ) { }

  ngOnInit( )
  {
    window.scroll(0, 0);
    
    if( this.preloadedData )
    {
      this.date = new Date(this.preloadedData.form_data.date);
      this.filesArray = this.getPreloadedData("files");
    }
    else
    {
      this.date = new Date();
    }
      
    this.maxDate = new Date();
  }

  getPreloadedData( key : string )
  {
    if( this.preloadedData && typeof(this.preloadedData.form_data[key]) != 'undefined' )
      return this.preloadedData.form_data[key];
    return null;
  }

  async submit( Form : NgForm )
  {
    if( Form.valid )
    {
      this.loading = true;
      
      let form_data = Form.value;
      form_data['date'] = this.date.getTime();

      if( !this.preloadedData )
      { 
        if( this.filesArray.length )
        {
          let files_results = [];

          for(var i = 0; i < this.filesArray.length; i++)
          {
            files_results.push( await this.uploadFile(this.filesArray[i]) );
          }
          
          form_data['files'] = files_results;

          if( files_results.length != this.filesArray.length )
          {
            this.loading = false;
            return this.messageCodeReturned = "error_upload_file";
          }

          this.filesArray.splice(0, this.filesArray.length);
        }

        this.HealthService.saveLog( "laboratory_results", this.uID , form_data ).then( logID =>
        {
          Form.resetForm();
          this.loading = false;
          this.messageCodeReturned = "succes";
          this.statusChanged.emit("submited");
        });
      }
      else
      {
        let files_results = [];

        for(var i = 0; i < this.filesArray.length; i++)
        {
          if( this.filesArray[i]['downloadURL'] )
            files_results.push( this.filesArray[i] );
          else
            files_results.push( await this.uploadFile(this.filesArray[i]) );
        }
        
        form_data['files'] = files_results;

        if( files_results.length != this.filesArray.length )
        {
          this.loading = false;
          return this.messageCodeReturned = "error_upload_file";
        }

        this.HealthService.updateLog( this.preloadedData['id'], form_data ).then( () => {
          Form.resetForm();
          this.loading = false;
          this.messageCodeReturned = "succes";
          this.statusChanged.emit("submited");
        });
      }
    }
  }

  async uploadFile( file )
  {
    return await this.StorageService.uploadFile( this.uID, file ).then( result => {
      return result;
    });
  }

  onFileSelection(event) 
  {
    const fileList: FileList = event.target.files;
    
    if (fileList.length > 0) 
    {
      const file : File = fileList[0];

      this.filesArray.push(file);
    }
  }

  removeFileFromArray( index : number )
  {
    this.filesArray.splice(index, 1);
  }

  openHelpModal ( helpText : string)
  {
    this.HelpModal.openHelpModal(helpText);
  }
}