import { Component, OnInit, Input } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../services/auth.service';

import { AlertService } from '../../services/alert.service';

import * as measurements_json from '../../jsons/measurements.json';

@Component({
  selector: 'app-my-measurements',
  templateUrl: './my-measurements.component.html',
  styleUrls: ['./my-measurements.component.css']
})
export class MyMeasurementsComponent implements OnInit {

  @Input() user;
  @Input() permissions;

  private measurements;
  private viewUser = false;
  
  constructor( private Router : Router, private route : ActivatedRoute, private AuthService : AuthService, private AlertService : AlertService )
  {
  }

  ngOnInit()
  {    
    this.measurements = measurements_json.items;

    if( this.AuthService.getLoggedInUser().uid != this.user.uid )
      this.viewUser = true;
  }

  goTo( page : string )
  {
    if( this.AuthService.getLoggedInUser().uid != this.user.uid )
    {
      if( this.checkPermission(page) )
        this.Router.navigate([`measurements/${page}`], { relativeTo: this.route });
      else 
        this.AlertService.show("Userul nu ti-a oferit acces la aceasta categorie.", "Ok");
    }
    else this.Router.navigate([`../measurements/${page}`], { relativeTo: this.route });
  }

  checkPermission( category : string )
  {
    if( this.permissions['measurements'][category] )
      return true;
    return false;
  }

  getMeasurementIcon( measurement_index : string )
  {
    if( this.checkPermission(measurement_index) )
      return `assets/homepage/${measurement_index}.png`;
    return "assets/homepage/locked.png";
  }
}
