import { Component, OnInit } from '@angular/core';

import { AuthService } from '../../services/auth.service';

import { CommentsService } from '../../services/comments.service';

import { HealthService } from '../../services/health.service';

@Component({
  selector: 'app-modal-editing-health',
  templateUrl: './modal-editing-health.component.html',
  styleUrls: ['./modal-editing-health.component.css']
})
export class ModalEditingHealthComponent implements OnInit
{
  private display = false;
  private LogData;
  private LogType;

  constructor( private CommentsService : CommentsService, private AuthService : AuthService, private HealthService : HealthService )
  { 

  }

  ngOnInit( )
  {
  }

  openModal( log_type : string, log_data )
  {
    this.LogType = log_type;
    
    this.LogData = log_data;

    this.display = true;

    document.body.classList.add("modal-open");
  }

  closeModal( index : number )
  {
    if( index )
      this.CommentsService.add(this.LogData.id, "health", this.AuthService.getLoggedInUser().uid, "The health log was edited by me !").then( () => {
        this.display = false;
      });
    else
      this.display = false;

    document.body.classList.remove("modal-open");
  }

  async deleteLog( LogData )
  {
    let comments_array = [];
    let subscribe = this.CommentsService.getCommentsByLogID( LogData.id, "health").subscribe( comments => {
      comments_array = comments;
      subscribe.unsubscribe();
    });

    comments_array.forEach( async comment => {
      await this.CommentsService.deleteCommentByID(comment.id).then( result => {
        //
      });
    });

    this.HealthService.deleteLogByID( LogData.id ).then( result => {
      this.closeModal(0);
    });
  }
}
