import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument  } from 'angularfire2/firestore';

@Injectable()
export class HealthService 
{
  constructor(private cloud: AngularFirestore)
  {}

  public getAllLogsByUID( uid : string )
  {
    return this.cloud.collection(`health`, ref => ref.where('uid', '==', uid)).valueChanges();
  }

  public getLogsByUID( log_type : string, uid : string )
  {
    return this.cloud.collection(`health`, ref => ref.where('uid', '==', uid).where('type', '==', log_type)).valueChanges();
  }

  public getLogByID( id : string )
  {
    return this.cloud.doc(`health/${id}`).valueChanges();
  }

  public getAllLogsByType( log_type : string )
  {
    return this.cloud.collection(`health`, ref => ref.where('type', '==', log_type)).valueChanges();
  }

  public saveLog = ( health_type : string, userID : string, health_data ) => {
    return new Promise((resolve, reject) => {

        let log_data = {};

        log_data['id'] = this.generateRandomString();
        log_data['uid'] = userID;
        log_data['category'] = "health";
        log_data['type'] = health_type;

        log_data['createdAt'] = Date.now();
        log_data['form_data'] = health_data;
  
        const postRef : AngularFirestoreDocument = this.cloud.doc(`health/${log_data['id']}`);
  
        postRef.set(log_data).then(response => {
            resolve(log_data['id']);
        })
        .catch(error => {
            console.log(error);
            reject (error);
        });
    });
  };

  public updateLog( ID : string, health_data )
  {
    return new Promise((resolve, reject) => {

      let log_data = { form_data: health_data };

      const postRef : AngularFirestoreDocument = this.cloud.doc(`health/${ID}`);

      postRef.update(log_data).then(response => {
          resolve();
      })
      .catch(error => {
          console.log(error);
          reject (error);
      });
    });
  }

  public deleteLogByID ( logID : string )
  {
    return new Promise(( resolve, reject) => 
    {
      this.cloud.doc(`health/${logID}`).delete().then(function() {
        resolve();
      }).catch(function(error) {
        reject(error);
      });
    });
  }

  private generateRandomString(): string 
  {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' + Date.now();

    for (let i = 0 ; i < 20; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
