import { Component, OnInit } from '@angular/core';

import { AlertService } from '../../services/alert.service';

import { TranslateAppService } from '../../services/translate.service';

@Component({
  selector: 'app-add-patient-form',
  templateUrl: './add-patient-form.component.html',
  styleUrls: ['./add-patient-form.component.css']
})
export class AddPatientComponent implements OnInit 
{
  private loading = false;
  private userAdded = false;
  private userType = null;

  constructor( private AlertService : AlertService, private TranslateAppService : TranslateAppService ) { }

  ngOnInit()
  {
  }

  selectUserType( userType : string )
  {
    if( userType == 'withCode' )
      this.userType = 'withCode';
    else
      this.userType = 'withoutCode';
  }

  statusChanged( event )
  {
    if( event == "anotherUser" )
      this.userType = null;
    else
    {
      this.userType = null;
      
      this.AlertService.show(this.TranslateAppService.getInstantStringByKey('add-patient-form.userAdded'), "Ok");
    }
  }
}
