import { Component, OnInit, Input } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../services/auth.service';

import { LinkService } from '../../services/link.service';

import * as health_categorys from '../../jsons/health.json';

import * as measurements from '../../jsons/measurements.json';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit 
{
  private accesToMyPatients = false;

  private waitComponents = {
    patients: true,
    supervisors: true
  };

  private permissions = {
    measurements:
    {
    },

    health:
    {
    },

    edit: false,
    write: false
  };

  @Input() user;

  constructor( private AuthService : AuthService, private LinkService : LinkService, private Router : Router, private route : ActivatedRoute )
  {
  }

  ngOnInit()
  {
    window.scroll(0, 0);
    
    this.initPermissions();
    
    if( this.user.uid == this.AuthService.getLoggedInUser().uid )
      this.accesToMyPatients = true;
    else
    {
      this.LinkService.getLinkBetweenUserAndSupervisor( this.user.uid, this.AuthService.getLoggedInUser().uid).then( links => {
        if( links ) this.permissions = links[0].permissions;
      });
    }
  }

  initPermissions()
  {
    measurements.items.forEach( item => {
        this.permissions['measurements'][item.index] = true;
    });

    health_categorys.items.forEach( item => {
        this.permissions['health'][item.index] = true;
    });
  }

  goToTimeline()
  {
    if( this.AuthService.getLoggedInUser().uid != this.user.uid )
      this.Router.navigate([`timeline`], { relativeTo: this.route });
    else this.Router.navigate([`../timeline`], { relativeTo: this.route });
  }

  readyPatients()
  {
    this.waitComponents.patients = false;
  }

  readySupervisors()
  {
    this.waitComponents.supervisors = false;
  }
}
