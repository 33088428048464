import { Component, OnInit, Input } from '@angular/core';

import { UserService } from '../../services/user.service';

import { LinkService } from '../../services/link.service';

import { AuthService } from '../../services/auth.service';

import { InvitationService } from '../../services/invitation.service';

import { NgForm } from '@angular/forms';

import * as _ from 'lodash';

@Component({
  selector: 'app-modal-userbycode',
  templateUrl: './modal-userbycode.component.html',
  styleUrls: ['./modal-userbycode.component.css']
})
export class ModalUserByCodeComponent implements OnInit
{
  private step = 0;

  private patientID : string;

  private error;

  private display = false;

  private theUserFound;

  private userAdded = false;
  private userType = null;

  private permissions;

  constructor( private UserService : UserService, private AuthService : AuthService, private LinkService : LinkService, private InvitationService : InvitationService ) { }

  ngOnInit( )
  {
  }

  openModal( patientID : string)
  {
    this.step = 0;
    this.patientID = patientID;
    this.display = true;
    this.error = "";

    document.body.classList.add("modal-open");
  }

  closeModal()
  {
    this.display = false;

    document.body.classList.remove("modal-open");
  }

  submitCode( codeForm : NgForm )
  {
    if( codeForm.value.code == this.AuthService.getLoggedInUser().code )
    {
      this.error = "Acest cod iti apartine tie. Ne am gandit si la asta :)";
      this.step = 3;
    }
    else
      this.UserService.getUserByCODE(codeForm.value.code).then( user => {
        if( user == null )
        {
          this.error = "Ne pare rau, acest utilizator nu exista.";
          this.step = 3;
        }
        else
        {
          this.theUserFound = user;
          this.step = 2;
        }
      });
  }

  addSupervisor( role : string )
  {
    this.LinkService.existLinkBetweenUserAndSupervisor( this.patientID, this.theUserFound.uid ).then( bool => {
      if( bool )
      {
        this.error = `Ne pare rau , ${this.theUserFound.firstname} ${this.theUserFound.secondname} iti este deja medic/ingrijitor.`;
        this.step = 3;
      }
      else
      {
        this.LinkService.createLinkBetweenUsers(this.patientID, this.theUserFound.uid, this.permissions, "accepted" ).then( () => {
          this.closeModal();
        });
      }
    });
  }

  back( )
  {
    if( this.step == 3 || this.step == 4 )
      this.step = 0;
    else this.step--;
  }

  submitInvitation( form : NgForm )
  {
    this.InvitationService.saveInvitation( this.patientID, form.value.email ).then( () => {
      this.closeModal();
    });
  }

  selectUserType( userType : string )
  {
    if( userType == 'withCode' )
      this.userType = 'withCode';
    else
      this.userType = 'withoutCode';
  }

  statusChanged( event )
  {
    if( event == "anotherUser" )
      this.userType = null;
    else
    {
      this.userType = null;
      this.userAdded = true;
    }
  }

  updatePermissions( permissions )
  {
    this.permissions = permissions;
  }
}
