import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument  } from 'angularfire2/firestore';

@Injectable()
export class CommentsService 
{
  constructor(private cloud: AngularFirestore)
  {}

  add( logID : string, logCategory : string, by_uID : string, text : string )
  {
    /*
      LogCategory:
        - measurement
        - health
    */
    let comment_data = {
      id: this.generateRandomString(),
      createdAt: Date.now(),
      logID: logID,
      logCategory: logCategory,
      uID: by_uID,
      text: text
    };

    const postRef : AngularFirestoreDocument = this.cloud.doc(`comments/${comment_data['id']}`);

    return new Promise((resolve, reject) => 
    {
      postRef.set(comment_data).then(response => {
        resolve(comment_data['id']);
      })
      .catch(error => {
          console.log(error);
          reject (error);
      });
    });
  }

  getCommentsByLogID( logID : string, logCategory : string )
  {
    return this.cloud.collection(`comments`, ref => ref.where('logID', '==', logID).where('logCategory', '==', logCategory).orderBy('createdAt', 'asc')).valueChanges();
  }

  deleteCommentByID( commentID : string )
  {
    return new Promise(( resolve, reject) => 
    {
      this.cloud.doc(`comments/${commentID}`).delete().then(function() {
        resolve();
      }).catch(function(error) {
        reject(error);
      });
    });
  }

  private generateRandomString() : string 
  {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' + Date.now();

    for (let i = 0 ; i < 20; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}
