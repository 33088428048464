import { Injectable } from '@angular/core';

import { AngularFirestore  } from 'angularfire2/firestore';

@Injectable()
export class SpecialCodeService 
{
  constructor( private cloud: AngularFirestore )
  {}

  public checkCode( code : string )
  {
    return new Promise((resolve, reject) => {
      if( code )
      {
        const Ref = this.cloud.doc(`special_codes/${code}`).valueChanges();

        let subscribe = Ref.subscribe(data => {
          if( data )
              resolve(true);
          else resolve(false);
          subscribe.unsubscribe();
        });
      }
      else resolve(false);
    });
  }

  public deleteCode( code : string )
  {
    return new Promise(( resolve, reject) => 
    {
      this.cloud.doc(`special_codes/${code}`).delete().then(function() {
        resolve();
      }).catch(function(error) {
        reject(error);
      });
    });
  }

  private generateRandomString(): string 
  {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' + Date.now();

    for (let i = 0 ; i < 20; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}
