import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-category-header',
  templateUrl: './category-header.component.html',
  styleUrls: ['./category-header.component.css']
})
export class CategoryHeaderComponent implements OnInit 
{
  private screen = 'measurement';
  private headerFormat;

  @Input() format : string; // normal (with 3 categories), short (with 2 categories)

  @Output() selectPage = new EventEmitter();

  constructor( ) { }

  ngOnInit()
  {
    this.headerFormat = this.format;
  }

  changeScreen( page : string )
  {
    this.screen = page;
    this.selectPage.emit(page);
  }
}