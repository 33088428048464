import { Injectable } from '@angular/core';

import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore, AngularFirestoreDocument,  } from 'angularfire2/firestore';

import { Observable } from 'rxjs';

import { User } from '../../shared/models/user.model';

import * as _ from 'lodash';

@Injectable()
export class AuthService 
{
  private userDoc: AngularFirestoreDocument<User>;
  private user: Observable<User>;

  constructor(private fireAuth: AngularFireAuth, private db: AngularFirestore)
  {
  }

  public register(user: any)
  {
    return new Promise((resolve, reject) => {
      this.fireAuth.auth.createUserWithEmailAndPassword(user.email, user.password)
        .then(
          result => {
            user.uid = result.user.uid;

            let password = user.password;

            this.onCreateUser(user).then( () => {
              user['password'] = password;

              resolve(user);
            });
          }
        )
        .catch( error => {
            reject(error);
          }
        );
    });
  };

  public login(email : string, password : string)
  {
    return new Promise((resolve, reject) => {
        this.fireAuth.auth.signInWithEmailAndPassword(email, password).then(
          (user : any ) => {

            let firebaseUser = user.user;

            this.userDoc = this.db.doc<User>('users/' + user.user.uid);

            this.user = this.userDoc.valueChanges();

            let subscribe_user = this.user.subscribe(
              user => {
                localStorage.setItem('user', JSON.stringify(user));

                console.log('User logged in (UID: ' + firebaseUser.uid + ')');
                resolve(user);
                subscribe_user.unsubscribe();
            });
          }
        )
        .catch(
          error => {
            reject(error);
          }
        );
    });
  };

  public logout() 
  {  
    return new Promise((resolve, reject) => {
      this.fireAuth.auth.signOut().then( () => {
        localStorage.removeItem('user');
        resolve();
      }).catch(error =>
      {
        reject(error);
      });
    });
  };

  public sendPasswordResetEmail(email: string)
  {
    return new Promise((resolve, reject) => {
      this.fireAuth.auth.sendPasswordResetEmail(email)
        .then(function() {
          resolve();
        })
        .catch(function(error) {
          reject(error);
        });
    });
  };

  public isLoggedInFireAuth()
  {
    if( this.fireAuth.auth )
    {
      if( this.fireAuth.auth.currentUser != null && this.fireAuth.auth.currentUser.uid )
        return true;
    }
    return false;
  };

  public getLoggedInUser = () => {
    return JSON.parse(localStorage.getItem('user'));
  };

  public loggedIn = () => {
    return this.getLoggedInUser();
  };

  private async onCreateUser(user: any) 
  {
    if( user['password'] )
      delete user.password;

    user['code'] = await this.generateUniqueUserCode();

    user['tutorialFinished'] = false;

    return new Promise((resolve, reject) => {
      const userRef: AngularFirestoreDocument<User> = this.db.doc(`users/${user.uid}`);

      userRef.set(user).then( () => {
        resolve(user);
      }).catch(function(error) {
        reject(error);
      });
    });
  };

  public createUserWithoutEmailAndPassword( user_data )
  {
    return new Promise((resolve, reject) => {
      user_data['uid'] = this.generateRandomString();

      console.log(user_data);
      
      this.onCreateUser( user_data ).then( user => {
        resolve(user);
      });
    });
  }

  public createLeads( data )
  {
    return new Promise((resolve, reject) => {
      data['id'] = this.generateRandomString();

      const Ref: AngularFirestoreDocument<User> = this.db.doc(`leads/${data.id}`);

      Ref.set(data).then( () => {
        resolve(data);
      }).catch(function(error) {
        reject(error);
      });
    });
  }
  
  public async generateUniqueUserCode( )
  {
    let code = this.generateUserCode();

    while( await this.checkUserCode(code) )
      code = this.generateUserCode();
     
    return code;
  }

  private async checkUserCode( code : string )
  {
    const codeRef = this.db.doc(`users_codes/${code}`);

    return new Promise((resolve, reject) => {
      codeRef.get().toPromise().then( result => {
        if( !result.exists )
          codeRef.set({ });
        resolve(result.exists);
      });
    });
  }

  private generateRandomString(): string 
  {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' + Date.now();

    for (let i = 0 ; i < 27; i++) 
    {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }

  private generateUserCode(): string 
  {
    let text = '';
    let possible = 'ABCDEFGHIJKMNPQRSTUVWXYZ23456789';

    for (let i = 0 ; i < 5; i++) 
    {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return text;
  }
}
