import { Component, OnInit, Input } from '@angular/core';

import { AuthService } from '../../services/auth.service';

import { MeasurementService } from '../../services/measurement.service';

import { HealthService } from '../../services/health.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.css']
})
export class TimelineComponent implements OnInit
{
  @Input() userID : string;

  private selectedLog;

  private ready = false;
  private currentFeedsNumber = 10;
  private feedsGroupedByDate;
  private logs;
  private feeds;

  private subscribe_MeasurementService;
  private subscribe_HealthService;

  constructor( private MeasurementService : MeasurementService, private HealthService : HealthService, private AuthService : AuthService )
  {
  }

  ngOnInit()
  {    
    if( !this.userID )
      this.userID = this.AuthService.getLoggedInUser().uid;
      
      this.subscribe_MeasurementService = this.MeasurementService.getAllMeasurementsByUID( this.userID ).subscribe( measurements => {
        this.logs = measurements;

        this.subscribe_HealthService = this.HealthService.getAllLogsByUID( this.userID ).subscribe( logs => {
          
          logs.forEach( log => { this.logs.push(log); } );

          this.initFeeds();

          this.subscribe_HealthService.unsubscribe();
        });

        this.subscribe_MeasurementService.unsubscribe();
    });
  }

  initFeeds()
  {
    this.feeds = _.orderBy(this.logs, ['form_data.date'], ['desc']).slice(0, this.currentFeedsNumber);

    this.groupByDate(this.feeds);
  }

  groupByDate( feeds )
  {
    let object = [];
    let object_index = -1;
    let date;
    let feed_date;

    for(var i = 0; i < feeds.length; i++)
    {
      if( feeds[i].form_data.date )
      {
        feed_date = new Date(feeds[i].form_data.date).setHours(0, 0, 0, 0);

        if( feed_date != date )
        {
          date = feed_date;
          object.push( { index_date: date, feeds: [] } );
          object_index++;
        }

        object[object_index]['feeds'].push( feeds[i] );
      }
    }

    this.feedsGroupedByDate = object;
    this.ready = true;
  }

  increaseFeedsNumber()
  {
    if( this.feeds.length < this.logs.length )
    {
      this.currentFeedsNumber += 10;
      this.initFeeds();
    }
  }

  onSelectLog( log )
  {
    this.selectedLog = log;
  }

  backToTimeline()
  {
    this.selectedLog = null;
  }
}
