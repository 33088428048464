import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';

import { ModalEditingMeasurementComponent } from '../../../modal-editing-measurement/modal-editing-measurement.component';

import { CategoryHeaderComponent } from '../../../category-header/category-header.component';

import { AuthService } from '../../../../services/auth.service';

import { MeasurementService } from '../../../../services/measurement.service';

import * as _ from 'lodash';

@Component({
  selector: 'app-measurements-symptoms',
  templateUrl: './symptoms.component.html',
  styleUrls: ['./symptoms.component.css']
})
export class SymptomComponent implements OnInit, OnDestroy
{
  @ViewChild(ModalEditingMeasurementComponent) Modal: ModalEditingMeasurementComponent;
  @ViewChild(CategoryHeaderComponent) CategoryHeader : CategoryHeaderComponent;

  private screen = 'measurement';

  private selectedLog;
  
  private LogsArray = [];
  private loading = true;
  private userID;
  private refreshViewLog = false;

  @Input() uID : string;

  // Subscribers
  private subscribe_Service;

  constructor( private AuthService : AuthService, private MeasurementService : MeasurementService ) { }

  ngOnInit()
  {
    window.scroll(0, 0);
    
    if( this.uID == this.AuthService.getLoggedInUser().uid || this.uID == null )
      this.userID = this.AuthService.getLoggedInUser().uid;      
    else 
      this.userID = this.uID;

    this.subscribe_Service = this.MeasurementService.getMeasurementsByUID( "symptoms", this.userID ).subscribe( logs =>
    {
      this.LogsArray = logs;
      this.loading = false;
    });
  }

  ngOnDestroy()
  {
    this.subscribe_Service.unsubscribe();
  }

  changeScreen( screen : string )
  {
    this.screen = screen;
  }

  onFormSubmited()
  {
    this.CategoryHeader.changeScreen("history");
  }

  selectLog( log )
  {
    this.refreshViewLog = true;
    this.selectedLog = log;

    setTimeout( () =>
    {
      this.refreshViewLog = false;
    }, 100);
  }
}