import { Component, OnInit, OnDestroy, Input, ViewChild, Output, EventEmitter } from '@angular/core';

import { UserService } from '../../services/user.service';

import { LinkService } from '../../services/link.service';

import { AlertService } from '../../services/alert.service';

import { Router, ActivatedRoute } from '@angular/router';

import { ModalAddPatientComponent } from '../modal-add-patient/modal-add-patient.component';

import * as _ from 'lodash';

@Component({
  selector: 'app-my-patients',
  templateUrl: './my-patients.component.html',
  styleUrls: ['./my-patients.component.css']
})
export class MyPatientsComponent implements OnInit, OnDestroy {

  @Input() userID;

  @Output() statusChanged = new EventEmitter();

  @ViewChild(ModalAddPatientComponent) ModalAddPatient : ModalAddPatientComponent;

  private loading = true;

  private users = [];
  private links = [];

  // Subscribers
  private subscribe_UserService;
  private subscribe_Users;

  constructor( private UserService : UserService, private Router : Router, private route : ActivatedRoute, private LinkService : LinkService, private AlertService : AlertService )
  {
  }

  ngOnInit()
  {
    this.subscribe_Users = this.UserService.getAllUsers().subscribe( users => {
      this.users = users;

      this.subscribe_UserService = this.LinkService.getLinksBySupervisorID( this.userID ).subscribe( links => {
        this.preparePatients( links );
      });
    });
  }

  ngOnDestroy()
  {
    if( this.subscribe_Users )
      this.subscribe_Users.unsubscribe();

    if( this.subscribe_UserService )
      this.subscribe_UserService.unsubscribe();
  }

  preparePatients( links )
  {    
    for(var i = 0; i < links.length; i++)
    {
      let findUserIndex = _.findIndex(this.users, {'uid': links[i].uid});
      links[i]['user'] = this.users[findUserIndex];
    }

    this.links = links;
    this.loading = false;

    this.statusChanged.emit("ready");
  }

  add()
  {
    this.ModalAddPatient.openModal();
  }

  goTo( link )
  {
    if( link.status == 'accepted' )
    {
      let uid = link.user.uid;
      this.Router.navigate([`../view-user/${uid}`], { relativeTo: this.route });
    }
    else
      this.AlertService.show("Persoana inca nu a raspuns requestului.", "Ok");
  }

  getUserAvatar( user )
  {
    if( user.avatar )
      return `assets/animals_icons/${user.avatar}.png`;
    else return "assets/health.png";
  }
}
