import { Component, OnInit, Output, EventEmitter } from '@angular/core';

import { UserService } from '../../services/user.service';

import { AuthService } from '../../services/auth.service';

import { LinkService } from '../../services/link.service';

import { NotificationService } from '../../services/notification.service';

import * as health_categorys from '../../jsons/health.json';

import * as measurements from '../../jsons/measurements.json';

import { NgForm } from '@angular/forms';

import * as _ from 'lodash';

@Component({
  selector: 'app-userbycode-form',
  templateUrl: './userbycode-form.component.html',
  styleUrls: ['./userbycode-form.component.css']
})
export class UserByCodeFormComponent implements OnInit {

  @Output() statusChanged = new EventEmitter();

  private step = 0;

  private error;

  private theUserFound;

  private permissions = {
    measurements: 
    {
    },

    health: 
    {
    },

    write: true,

    edit: true
  }

  constructor( private UserService : UserService, private AuthService : AuthService, private NotificationService : NotificationService, private LinkService : LinkService )
  {
  }

  ngOnInit()
  {
  }

  submitCode( codeForm : NgForm )
  {
    if( codeForm.value.code == this.AuthService.getLoggedInUser().code )
    {
      this.error = "Acest cod iti apartine tie. Ne am gandit si la asta :)";
      this.step = 2;
    }
    else
      this.UserService.getUserByCODE(codeForm.value.code).then( user => {
        if( user == null )
        {
          this.error = "Ne pare rau, acest utilizator nu exista.";
          this.step = 2;
        }
        else
        {
          this.theUserFound = user;
          this.step = 1;
        }
      });
  }

  addSupervisor( )
  {
    this.LinkService.existLinkBetweenUserAndSupervisor( this.theUserFound.uid, this.AuthService.getLoggedInUser().uid ).then( bool => {
      if( bool )
      {
        this.error = `Ne pare rau , ${this.theUserFound.firstname} ${this.theUserFound.secondname} este deja in ingrijirea ta.`;
        this.step = 2;
      }
      else
      {
        this.initPermisions();
        
        this.LinkService.createLinkBetweenUsers(this.theUserFound.uid, this.AuthService.getLoggedInUser().uid, this.permissions, "pending" ).then( link => {
          this.NotificationService.sendRequestNotification( this.theUserFound.uid, this.AuthService.getLoggedInUser(), link).then( () => {
            this.statusChanged.emit("submited");
          });
        });
      }
    });
  }

  back( )
  {
    if( this.step == 2 )
      this.step = 0;
    else this.step--;
  }

  backToSelectUserType()
  {
    this.step = 0;
    this.statusChanged.emit("anotherUser");
  }

  initPermisions()
  {
    health_categorys.items.forEach( item => {
      this.permissions['health'][item.index] = true;
    });

    measurements.items.forEach( item => {
      this.permissions['measurements'][item.index] = true;
    });
  }
}
