export var items = [
    {
        index: "weight",
        title: "Greutate"
    },
    {
        index: "tension",
        title: "Tensiune"
    },
    {
        index: "cholesterol",
        title: "Colesterol"
    },
    {
        index: "blood_glucose",
        title: "Glucoza in sange"
    },
    {
        index: "height",
        title: "Inaltime"
    },
    {
        index: "symptoms",
        title: "Simptome"
    }
];