import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { AuthService } from '../../services/auth.service';

import { UserService } from '../../services/user.service';

import { NotificationService } from '../../services/notification.service';

import { TranslateAppService } from '../../services/translate.service';

import { Location } from '@angular/common';

import * as _ from 'lodash';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private user;
  private viewUser;
  private appLanguage;
  private screen = "menu";
  private showAlert = false;
  private showHeader = false;
  private showBackButton = false;
  private showNotifications = false;
  private subscribe_Service;
  private subscribe_Router;

  private notificationIconPath = "assets/small_icons/bell.png";

  constructor( private AuthService : AuthService, private TranslateAppService : TranslateAppService, private UserService : UserService, private Url: Location, private Router : Router, private Route : ActivatedRoute, private NotificationService : NotificationService )
  {
    this.subscribe_Router = this.Router.events.subscribe((route) => {
      if( route instanceof NavigationEnd )
      {
        if( route.url.indexOf("/tutorial") != -1 )
          this.showHeader = false;
        else
          this.showHeader = true;

        if( route.url.indexOf("/view-user/") != -1 )
        {          
          let uID = this.Route.children[0].children[0].snapshot.params.id;

          this.UserService.getUserByUID( uID ).then( user => {
            this.viewUser = user;
          });

          if( this.Route.children[0].children[0].children[0].component['name'] == "DashboardUserComponent" )
            this.showBackButton = false;
          else 
            this.showBackButton = true;
        }
        else
        {
          this.viewUser = null;

          if( route.url.indexOf("/home") != -1 || route.urlAfterRedirects.indexOf("/home") != -1 || route.url.indexOf("/tutorial") != -1 )
            this.showBackButton = false;
          else
            this.showBackButton = true;
        }
      }
    });
  }

  ngOnInit()
  {
    this.user = this.AuthService.getLoggedInUser();

    this.subscribe_Service = this.NotificationService.getNotificationsByUID( this.user.uid ).subscribe( notifications => {
      let unreadNotifications = _.find(notifications, { status: "unread" } );

      if( unreadNotifications ) this.showAlert = true;
      else this.showAlert = false;
    });

    this.appLanguage = this.TranslateAppService.get();
  }

  ngOnDestroy()
  {
    this.subscribe_Service.unsubscribe();
    this.subscribe_Router.unsubscribe();
  }

  logout()
  {
    this.AuthService.logout().then( () => {
      this.Router.navigate(['/index']);
    });
  }

  home()
  {
    this.Router.navigate(['home'], { relativeTo: this.Route });
  }

  changeMenuContent( screen : string )
  {
    this.screen = screen;
  }

  onClickNotifications()
  {
    this.showNotifications = true;
    this.notificationIconPath = "assets/small_icons/bell-clicked.png";
  }

  offClickNotifications()
  {
    this.showNotifications = false;
    this.notificationIconPath = "assets/small_icons/bell.png";
  }

  onClickMenu()
  {
    this.screen = "menu";
  }

  back()
  {
    this.Url.back();
  }

  getUserAvatarPath( avatar : string )
  {
    return `assets/animals_icons/${avatar}.png`;
  }

  changeAppLanguage( lang : string )
  {
    this.TranslateAppService.change(lang);

    this.appLanguage = lang;
  }
}
