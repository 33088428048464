import { Component } from '@angular/core';

import { TranslateAppService } from '../shared/services/translate.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class MainComponent
{
  constructor(private TranslateAppService : TranslateAppService) 
  {
    TranslateAppService.setDefault('ro');
  }
}