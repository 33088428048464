import { Component, OnInit, Input, Output, ViewChild, EventEmitter } from '@angular/core';

import { NgForm } from '@angular/forms';

import { MeasurementService } from '../../../../services/measurement.service';

import { ModalHelpButtonComponent } from '../../../modal-help-button/modal-help-button.component';

import * as childSymptoms from '../../../../jsons/child_symptoms.json';

import * as adultSymptoms from '../../../../jsons/adult_symptoms.json';

@Component({
  selector: 'app-symptoms-form',
  templateUrl: './symptoms-form.component.html',
  styleUrls: ['./symptoms-form.component.css']
})
export class SymptomsFormComponent implements OnInit
{
  private type = null;
  private selected_symptom = null;
  
  private child_symptoms = [];
  private adult_symptoms = [];

  @Input() uID : string;

  @Output() statusChanged = new EventEmitter();

  @ViewChild(ModalHelpButtonComponent) HelpModal: ModalHelpButtonComponent;

  constructor( private MeasurementService : MeasurementService ) { }

  ngOnInit( )
  {
    this.type = null;

    this.child_symptoms = childSymptoms.symptoms;
    this.adult_symptoms = adultSymptoms.symptoms;
  }

  selectType( type : string )
  {
    this.type = type;
    this.selected_symptom = null;
  }

  onSelectSymptom( symptom )
  {
    console.log(symptom);
    //this.selected_symptom = symptom_index;
  }

  submit( form : NgForm )
  {
      let data = form.value;
      data['symptoms_type'] = this.type;
      data['date'] = Date.now();

      this.MeasurementService.saveMeasurement( "symptoms", this.uID , data ).then( logID =>
      {
        form.resetForm();
        this.statusChanged.emit("submited");
      });
  }

  openHelpModal ( helpText : string)
  {
    this.HelpModal.openHelpModal(helpText);
  }
}
