import { Component, OnInit, OnDestroy, ViewChild, Input } from '@angular/core';

import { ModalEditingMeasurementComponent } from '../../../modal-editing-measurement/modal-editing-measurement.component';

import { CategoryHeaderComponent } from '../../../category-header/category-header.component';

import { AuthService } from '../../../../services/auth.service';

import { MeasurementService } from '../../../../services/measurement.service';

@Component({
  selector: 'app-measurements-blood-glucose',
  templateUrl: './blood_glucose.component.html',
  styleUrls: ['./blood_glucose.component.css']
})
export class BloodGlucoseComponent implements OnInit, OnDestroy
{
  @ViewChild(ModalEditingMeasurementComponent) Modal: ModalEditingMeasurementComponent;
  @ViewChild(CategoryHeaderComponent) CategoryHeader : CategoryHeaderComponent;

  private screen = 'measurement';

  private selectedLog;
  
  private LogsArray = [];
  private loading = true;
  private userID;
  private chartHeader = [ "blood_glucose" ];
  private refreshGraph = false;
  private refreshViewLog = false;

  @Input() uID : string;

  // Subscribers
  private subscribe_Service;

  constructor( private AuthService : AuthService, private MeasurementService : MeasurementService ) { }

  ngOnInit()
  {
    window.scroll(0, 0);
    
    if( this.uID == this.AuthService.getLoggedInUser().uid || this.uID == null )
      this.userID = this.AuthService.getLoggedInUser().uid;      
    else 
      this.userID = this.uID;

    this.subscribe_Service = this.MeasurementService.getMeasurementsByUID( "blood_glucose", this.userID ).subscribe( logs =>
    {
      this.LogsArray = logs;
      this.refreshGraph = true;
      this.loading = false;

      setTimeout( () =>
      {
        this.refreshGraph = false;
      }, 1000);
    });
  }

  ngOnDestroy()
  {
    this.subscribe_Service.unsubscribe();
  }

  openModal( log_data )
  {
    this.Modal.openModal( "blood_glucose", log_data );
  }

  changeScreen( screen : string )
  {
    this.screen = screen;
  }

  onFormSubmited()
  {
    this.CategoryHeader.changeScreen("graph");
  }

  selectLog( log )
  {
    this.refreshViewLog = true;
    this.selectedLog = log;

    setTimeout( () =>
    {
      this.refreshViewLog = false;
    }, 100);
  }
}