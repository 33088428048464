import { Component, OnInit, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';

import { AuthService } from '../../services/auth.service';

import { UserService } from '../../services/user.service';

import { LinkService } from '../../services/link.service';

import { ModalUserByCodeComponent } from '../modal-userbycode/modal-userbycode.component';

import { ModalEditUserPermissionsComponent } from '../modal-edit-user-permissions/modal-edit-user-permissions.component';

import * as avatars from '../../jsons/avatars.json';

import * as _ from 'lodash';

@Component({
  selector: 'app-my-supervisors',
  templateUrl: './my-supervisors.component.html',
  styleUrls: ['./my-supervisors.component.css']
})
export class MySupervisorsComponent implements OnInit, OnDestroy {

  @Input() user;

  @Output() statusChanged = new EventEmitter();

  @ViewChild(ModalUserByCodeComponent) Modal : ModalUserByCodeComponent;
  @ViewChild(ModalEditUserPermissionsComponent) ModalEditUserPermissions : ModalEditUserPermissionsComponent;

  private loading = true;
  private accesToAddSupervisor = false;
  private my_supervisors = [];
  private users = [];

  // Subscribers
  private subscribe_UserService;
  private subscribe_Users;

  constructor( private AuthService : AuthService, private UserService : UserService, private LinkService : LinkService )
  {
  }

  ngOnInit()
  {
    if( this.user.uid == this.AuthService.getLoggedInUser().uid )
      this.accesToAddSupervisor = true;

    this.subscribe_Users = this.UserService.getAllUsers().subscribe( users => {
      this.users = users;

      this.subscribe_UserService = this.LinkService.getLinksByUID( this.user.uid ).subscribe( links => {
        this.preparePatients( links );
      });
    });
  }

  ngOnDestroy()
  {
    if( this.subscribe_UserService )
      this.subscribe_UserService.unsubscribe();

    if( this.subscribe_Users )
      this.subscribe_Users.unsubscribe();
  }

  preparePatients( links )
  {
    for(var i = 0; i < links.length; i++)
    {
      let findUserIndex = _.findIndex(this.users, {'uid': links[i].supervisor_uid});
      links[i]['user'] = this.users[findUserIndex];
    }

    this.my_supervisors = links;
    this.loading = false;
    
    this.statusChanged.emit("ready");
  }

  add()
  {
    this.Modal.openModal( this.user.uid );
  }

  getUserAvatar( user )
  {
    if( user.avatar )
      return `assets/animals_icons/${user.avatar}.png`;
    else return "assets/health.png";
  }

  viewPermissions( link )
  {
    if( this.accesToAddSupervisor )
      this.ModalEditUserPermissions.openModal(link.user, link);
  }
}
