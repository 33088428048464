import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-invitation-code',
  templateUrl: './invitation-code.component.html',
  styleUrls: ['./invitation-code.component.css']
})
export class InvitationCodeComponent implements OnInit
{
  @Input() code : string;

  private invitation_code;

  constructor( )
  {
  }

  ngOnInit()
  {
    this.invitation_code = this.code;
  }
}