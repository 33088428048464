import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute, Params } from '@angular/router';

import { NgForm } from '@angular/forms';

import { InvitationService } from '../../shared/services/invitation.service';

import { AuthService } from '../../shared/services/auth.service';

import { LinkService } from '../../shared/services/link.service';

import { User } from '../../shared/models/user.model';

import * as countries_list from '../../shared/jsons/country_list.json';

import * as _ from 'lodash';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.css']
})
export class InvitationComponent implements OnInit
{
  private roleSelected;
  private invitation;
  private email;
  private birthday = new Date();
  private countries = [];

  private ready = false;
  private loading = false;

  private errorCode;

  constructor(  private route : ActivatedRoute, private InvitationService : InvitationService, private AuthService : AuthService, private LinkService : LinkService, private Router : Router )
  {
  }

  ngOnInit()
  {
    if( this.route.snapshot.params && this.route.snapshot.params.id )
    {
      this.InvitationService.getInvitationByID(this.route.snapshot.params.id).then( invitation => {
        if( invitation['status'] == "unavailable" )
        {
          this.errorCode = "expire_invitation";
        }
        else
        {
          this.countries = countries_list.list;

          this.invitation = invitation;
          this.email = invitation['email'];
          this.ready = true;
        }
      }).catch( error => {
        this.errorCode = "invalid_invitation";
      });
    }
  }

  submitRegister( registerForm : NgForm )
  {
    if( registerForm.value.password != registerForm.value.repeat_password || registerForm.value.password.length == 0 || registerForm.value.repeat_password == 0 || !registerForm.valid )
      return console.log("Parolele nu coincid.");
    
    this.loading = true;
    let user_formData = _.pick(registerForm.value, ['firstname', 'secondname', 'sex', 'password']);

    user_formData['birthday'] = this.birthday;
    user_formData['email'] = this.email;

    if( user_formData['birthday'] != "undefined" )
      user_formData['birthday'] = new Date(user_formData['birthday']).getTime();
      
    user_formData['role'] = this.roleSelected;

    this.AuthService.register(user_formData).then(() => {
      this.AuthService.login(user_formData.email, user_formData.password)
      .then((user : User) => {
        if (user && user.role)
        {
          this.LinkService.createLinkBetweenUsers(this.invitation.by_uID, user['uid'], null, "accepted").then( () => {
            this.InvitationService.changeInvitationStatus(this.invitation.id).then( () => {
              this.loading = false;
              this.Router.navigate([user.role]);
            });
          });
        }
      })
      .catch(err => {
        this.loading = false;
        console.log(err.message);
      });
    })
    .catch(err => {
      this.loading = false;
      console.log(err.message);
    });
  } 

  back()
  {
    this.roleSelected = null;
  }
}


