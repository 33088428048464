import { Component, OnInit } from '@angular/core';

import { TranslateAppService } from '../../shared/services/translate.service';

declare let $ : any;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit
{
  private user_type_for_details = "user";
  private appLanguage;
  
  constructor( private TranslateAppService : TranslateAppService )
  {
  }

  ngOnInit()
  {
    this.appLanguage = this.TranslateAppService.get();
  }

  changeAppLanguage( lang : string )
  {
    this.TranslateAppService.change(lang);

    this.appLanguage = lang;
  }
}

/*
var lFollowX = 0,
  lFollowY = 0,
  x = 0,
  y = 0,
  friction = 1 / 120;

function moveBackground() 
{
    x += (lFollowX - x) * friction;
    y += (lFollowY - y) * friction;
    
    var translate = 'translate(' + x + 'px, ' + y + 'px)';

    $('.people-component').css({
        '-webit-transform': translate,
        '-moz-transform': translate,
        'transform': translate
    });

    window.requestAnimationFrame(moveBackground);
}

$(window).on('mousemove click', function(e) 
{
    var lMouseX = Math.max(-100, Math.min(100, $(window).width() / 2 - e.clientX));
    var lMouseY = Math.max(-100, Math.min(100, $(window).height() / 2 - e.clientY));
    lFollowX = (20 * lMouseX) / 100; // 100 : 12 = lMouxeX : lFollow
    lFollowY = (10 * lMouseY) / 100;
});

moveBackground();
*/