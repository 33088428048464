import { Component, OnInit } from '@angular/core';

import { LinkService } from '../../services/link.service';

@Component({
  selector: 'app-modal-edit-user-permissions',
  templateUrl: './modal-edit-user-permissions.component.html',
  styleUrls: ['./modal-edit-user-permissions.component.css']
})
export class ModalEditUserPermissionsComponent implements OnInit
{
  private display = false;
  private user;
  private link;

  private permissions = {};

  private ready = false;

  constructor( private LinkService : LinkService ) { }

  ngOnInit( )
  {
  }

  openModal( user, link )
  {
    this.ready = false;

    this.link = link;
    this.user = user;

    this.ready = true;
    this.display = true;

    document.body.classList.add("modal-open");
  }

  closeModal()
  {
    this.display = false;

    document.body.classList.remove("modal-open");
  }

  savePermissions()
  {
    this.link.permissions = this.permissions;

    let ok = false;

    Object.keys(this.permissions['measurements']).forEach( key => {
      if( this.permissions['measurements'][key] == true )
        ok = true;
    });

    Object.keys(this.permissions['health']).forEach( key => {
      if( this.permissions['health'][key] == true )
        ok = true;
    });
    
    if( ok )
      this.LinkService.updateLink( this.link ).then( () => {
        this.closeModal();
      });
    else
      this.LinkService.deleteLinkByID( this.link.id ).then( () => {
        this.closeModal();
      });
  }

  updatePermissions( permissions )
  {
    this.permissions = permissions;
  }

  getUserAvatarPath( avatar : string )
  {
    return `assets/animals_icons/${avatar}.png`;
  }
}
