import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreDocument,  } from 'angularfire2/firestore';

@Injectable()
export class UserService 
{
  constructor(private cloud: AngularFirestore)
  {}

  public getAllUsers()
  {
    return this.cloud.collection(`users`).valueChanges();
  }

  public getMedics()
  {
    return this.cloud.collection(`users`, ref => ref.where('role', '==', 'medic')).valueChanges();
  }

  public getCareres()
  {
    return this.cloud.collection(`users`, ref => ref.where('role', '==', 'carer')).valueChanges();
  }

  public getUserByUID( uid : string )
  {
    const userByID = this.cloud.doc(`users/${uid}`).valueChanges();

    return new Promise((resolve, reject) => {
      let subscribe = userByID.subscribe(user => {
        resolve(user);
        subscribe.unsubscribe();
      });
    });
  }

  public getUserByCODE( code : string )
  {
    code = code.toUpperCase();
    const userByCode = this.cloud.collection(`users`, ref => ref.where('code', '==', code)).valueChanges();

    return new Promise((resolve, reject) => {
      let subscribe = userByCode.subscribe( users => {
        resolve(users[0]);
        subscribe.unsubscribe();
      });
    });
  }

  public updateUser( user )
  {
    return new Promise(( resolve, reject) => 
    {
      this.cloud.doc(`users/${user.uid}`).update( user ).then( () => {
        localStorage.setItem('user', JSON.stringify(user));
        resolve();
      }).catch( error => {
        reject(error.message);
      });
    });
  }

  private generateRandomString(): string 
  {
    let text = '';
    let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz' + Date.now();

    for (let i = 0 ; i < 20; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
}
